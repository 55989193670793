import { getCurrentAuthCredentials } from 'api/auth/auth.api';
import {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
  useMemo,
} from 'react';
import { User } from 'types/api';

interface UserContextState {
  user: User | null;
  setUser: (user: User | null) => void;
  getJWT: () => Promise<string | null>;
}

const UserContext = createContext<UserContextState | undefined>(undefined);

export const useUserContext = (): UserContextState => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserContextProvider');
  }
  return context;
};

interface UserContextProviderProps {
  children: ReactNode;
}

export function UserContextProvider({
  children,
}: UserContextProviderProps): JSX.Element {
  const [user, setUser] = useState<User | null>(null);
  const [jwt, setJWT] = useState<string | null>(null);
  const [jwtExpiry, setJwtExpiry] = useState<number>(0);

  const getJWT = useCallback(async (): Promise<string | null> => {
    const currentTime = Date.now() / 1000; // Convert to seconds
    if (jwt === null || currentTime + 60 > jwtExpiry) {
      try {
        const { jwt: newJWT, expiry } = await getCurrentAuthCredentials();
        setJWT(newJWT);
        setJwtExpiry(expiry);
        return newJWT;
      } catch (error) {
        console.error('Error updating JWT:', error);
        return null;
      }
    }
    return jwt;
  }, [jwt, jwtExpiry]);

  const contextValue = useMemo(
    () => ({ user, setUser, getJWT }),
    [user, setUser, getJWT],
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
}
