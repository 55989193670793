import { Step, StepLabel, Stepper } from '@mui/material';

interface ProgressStepperProps {
  steps: string[];
  activeStep: number;
}

export default function ProgressStepper({
  steps,
  activeStep,
}: ProgressStepperProps) {
  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
    >
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
