import { DocumentNode } from 'graphql';
import { GraphQLClient, ClientError } from 'graphql-request';
import {
  BaseQueryFn,
  createApi,
  BaseQueryApi,
} from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { getCurrentAuthCredentials } from 'api/auth/auth.api';

interface GraphQLRequestParams {
  document: DocumentNode | string;
  variables?: Record<string, unknown>;
}

type GraphQLBaseQueryFn = BaseQueryFn<
  GraphQLRequestParams,
  unknown,
  { message: string; errors?: any[] }
>;

const appsyncEndpoint = `https://appsync-twins-${process.env.REACT_APP_STAGE || 'prod'}-${process.env.REACT_APP_AWS_REGION || 'eu-west-2'}.superfeel.com/graphql`
const client: GraphQLClient = new GraphQLClient(
  appsyncEndpoint,
);

let cachedJWT: string | null = null;
let lastJWTUpdate: number = 0;
const JWT_CACHE_DURATION = 10 * 60 * 1000; // 10 minutes in milliseconds

const getJWTWithCache = async (): Promise<string | null> => {
  const currentTime = Date.now();
  if (!cachedJWT || currentTime - lastJWTUpdate > JWT_CACHE_DURATION) {
    try {
      const authCredentials = await getCurrentAuthCredentials();
      cachedJWT = authCredentials.jwt;
      lastJWTUpdate = currentTime;
    } catch (err) {
      console.error('Failed to get authentication credentials:', err);
      cachedJWT = null;
    }
  }
  return cachedJWT;
};

export const graphqlBaseQuery = graphqlRequestBaseQuery({
  client,
  prepareHeaders: async (
    headers: Headers,
    _: Pick<BaseQueryApi, 'getState'>,
  ) => {
    try {
      const jwt = await getJWTWithCache();
      if (jwt) {
        headers.set('Authorization', jwt);
      }
    } catch (err) {
      console.error('Failed to get authentication credentials:', err);
      throw new Error('Failed to get authentication credentials');
    }
    return headers;
  },
});

export const baseQueryWithGraphQLAuth: GraphQLBaseQueryFn = async (
  { document, variables }: GraphQLRequestParams,
  api: BaseQueryApi,
  extraOptions?: Partial<Pick<ClientError, 'request' | 'response'>>,
) => {
  try {
    const response = await graphqlBaseQuery(
      { document, variables },
      api,
      extraOptions,
    );
    return response;
  } catch (err: any) {
    console.error(err);
    return { error: { message: err.message, errors: err.errors } };
  }
};

export const baseApiWithGraphQL = createApi({
  baseQuery: baseQueryWithGraphQLAuth,
  endpoints: () => ({}),
  tagTypes: [],
});
