import { useState } from 'react';
import { Box, Menu, Slider, Typography } from '@mui/material';

const SLIDER_CONFIG = [
  { name: 'stability', label: 'Stability' },
  { name: 'similarity', label: 'Similarity' },
  { name: 'intensity', label: 'Intensity' },
];

const SliderMenuItem = ({ name, label, value, onChange }) => (
  <Box sx={{ width: 250, px: 4, pt: 2 }}>
    <Typography gutterBottom>{label}</Typography>
    <Slider
      step={0.1}
      marks
      max={1}
      value={value}
      onChange={onChange(name)}
      aria-labelledby={`${name}-slider`}
    />
  </Box>
);

const TuneMenu = ({ anchorEl, open, onClose }) => {
  const [sliderValues, setSliderValues] = useState(
    Object.fromEntries(SLIDER_CONFIG.map(({ name }) => [name, 50])),
  );

  const handleSliderChange = (name) => (event, newValue) => {
    setSliderValues((prev) => ({ ...prev, [name]: newValue }));
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {SLIDER_CONFIG.map(({ name, label }, index) => (
        <Box key={name}>
          <SliderMenuItem
            name={name}
            label={label}
            value={sliderValues[name]}
            onChange={handleSliderChange}
          />
        </Box>
      ))}
    </Menu>
  );
};

export default TuneMenu;
