import { Box, Typography } from '@mui/material';
import { useSuperfeelUser } from 'use/superfeel-user';
import { userFields } from './local';
import SectionCard from './components/section';
import EndCard from './components/endcard';

export default function UserHome() {
  const { superfeelUser } = useSuperfeelUser();

  if (!superfeelUser) {
    return <Typography variant="h6">No user data available.</Typography>;
  }

  return (
    <Box>
      <SectionCard
        title="User Information"
        fields={userFields}
        user={superfeelUser}
        endCard={<EndCard />}
      />
    </Box>
  );
}
