import { Box, Divider, Grid, TextField, Typography } from '@mui/material';
import { useVoice } from 'use/voice';

function VoiceDetails(): JSX.Element {
  const { voice, updateVoice, setVoice } = useVoice();
  const updateName = async (name: string) => {
    try {
      await updateVoice({ ...voice, name });
      setVoice({ ...voice, name });
    } catch (e) {
      console.error('Failed to update voice name:', e);
    }
  };
  const handleUpdateName = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateName(event.target.value);
  };
  return (
    <Box>
      <Grid
        container
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <Grid
          item
          md={2}
        >
          <Typography variant="h3">Voice</Typography>
        </Grid>
        <Grid
          item
          md={10}
        >
          <TextField
            value={voice?.name || ''}
            size="small"
            variant="filled"
            fullWidth
            label="Voice Name"
            onChange={handleUpdateName}
          />
        </Grid>
      </Grid>
      <Divider />
    </Box>
  );
}

export default VoiceDetails;
