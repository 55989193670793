import {
  Card,
  CardContent,
  Grid,
  Box,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Email, Phone, Wc } from '@mui/icons-material';
import { useSuperfeelUser } from 'use/superfeel-user';

function EndCard() {
  const { superfeelUser } = useSuperfeelUser();
  return (
    <Grid
      item
      xs={12}
      md={4}
    >
      <Card>
        <CardContent sx={{ pb: 0 }}>
          <Box>
            <Typography
              sx={{
                textTransform: 'uppercase',
                color: 'text.secondary',
                fontWeight: 'bold',
              }}
              variant="caption"
              gutterBottom
            >
              Account Features
            </Typography>
          </Box>
          <Stack
            sx={{ mt: 2.5 }}
            direction="row"
            spacing={4}
          >
            <Tooltip title="Email">
              <Email color={superfeelUser?.hasEmail ? 'inherit' : 'disabled'} />
            </Tooltip>
            <Tooltip title="Phone">
              <Phone
                color={superfeelUser?.hasPhoneNo ? 'inherit' : 'disabled'}
              />
            </Tooltip>
            <Tooltip title="Gender">
              <Wc color={superfeelUser?.hasGender ? 'inherit' : 'disabled'} />
            </Tooltip>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default EndCard;
