import {
  confirmSignUp,
  deleteUserAttributes,
  resendSignUpCode,
  signIn,
  signOut,
  signUp,
  updateUserAttributes,
  fetchAuthSession,
  confirmUserAttribute,
  resetPassword,
  confirmResetPassword,
  confirmSignIn,
  autoSignIn,
  fetchUserAttributes,
  FetchUserAttributesOutput,
  deleteUser,
  UserAttributeKey,
} from 'aws-amplify/auth';

const USER_ATTRIBUTES: ReadonlyArray<UserAttributeKey> = [
  'address',
  'birthdate',
  'email',
  'email_verified',
  'family_name',
  'gender',
  'given_name',
  'locale',
  'middle_name',
  'name',
  'nickname',
  'phone_number',
  'phone_number_verified',
  'picture',
  'preferred_username',
  'profile',
  'sub',
  'updated_at',
  'website',
  'zoneinfo',
] as const;

type UserCredentials = {
  username: string;
  jwt: string;
  expiry: number;
  userAttributes: FetchUserAttributesOutput;
  challengeName?: string;
};

type UserAttributes = Partial<
  Record<
    'phone_number' | 'email' | 'birthdate' | 'name' | 'preferred_username',
    string
  >
>;

export async function logIn(
  username: string,
  password: string,
  passwordlessLogin = false,
) {
  return signIn({
    username,
    password,
    options: {
      authFlowType: passwordlessLogin ? 'CUSTOM_WITHOUT_SRP' : 'USER_SRP_AUTH',
    },
  });
}

export async function logOut() {
  return signOut();
}

export async function signUpUser(
  username: string,
  password: string,
  autoSignin: boolean,
  userAttributes: UserAttributes = {},
) {
  return signUp({
    username,
    password,
    options: {
      userAttributes,
      autoSignIn: autoSignin,
    },
  });
}

export async function updateCurrentUserAttributes(
  userAttributes: UserAttributes,
) {
  return updateUserAttributes({ userAttributes });
}

export async function removeCurrentUserAttribute(
  attribute: 'email' | 'phone_number',
) {
  return deleteUserAttributes({ userAttributeKeys: [attribute] });
}

export async function deleteUserProfile() {
  return deleteUser();
}

export async function getCurrentUserAttributes() {
  try {
    const attrs = await fetchUserAttributes();
    return attrs;
  } catch (err) {
    return {};
  }
}

export async function submitAttributeVerificationCode(
  attribute: 'email' | 'phone_number',
  code: string,
) {
  return confirmUserAttribute({
    userAttributeKey: attribute,
    confirmationCode: code,
  });
}

export async function submitSignupVerificationCode(
  username: string,
  code: string,
  autoLogin = false,
) {
  const res = await confirmSignUp({
    username,
    confirmationCode: code,
    options: { forceAliasCreation: true },
  });
  if (res.isSignUpComplete && autoLogin) {
    const output = await autoSignIn();
    if (!output.isSignedIn) {
      throw new Error('Auto signin failed');
    }
  }
  return res;
}

export async function resendSignupVerificationCode(username: string) {
  return resendSignUpCode({ username });
}

export async function sendPasswordResetCode(username: string) {
  return resetPassword({ username });
}

export async function resetPasswordSubmit(
  username: string,
  code: string,
  password: string,
) {
  return confirmResetPassword({
    username,
    newPassword: password,
    confirmationCode: code,
  });
}

export async function forceChangePassword(newPassword: string) {
  return confirmSignIn({ challengeResponse: newPassword });
}

export async function submitOneTimePasscode(otp: string) {
  return confirmSignIn({ challengeResponse: otp });
}

async function getIdToken() {
  try {
    const creds = await fetchAuthSession({ forceRefresh: false });
    return creds;
  } catch (err) {
    return null;
  }
}

export async function getAuthCredentials(shouldRefresh = false) {
  const output: UserCredentials = {
    username: '',
    jwt: '',
    expiry: -1,
    userAttributes: {},
  };
  const creds = await getIdToken();
  if (!creds) {
    return output;
  }
  output.jwt = creds.tokens?.idToken?.toString() || '';
  output.expiry = creds.tokens?.idToken?.payload?.exp || -1;
  if (output.jwt && shouldRefresh) {
    // can only be called when authenticated
    output.userAttributes = await getCurrentUserAttributes();
  } else if (output.jwt) {
    Object.entries(creds.tokens?.idToken?.payload || {}).forEach(
      ([key, val]) => {
        if (USER_ATTRIBUTES.includes(key)) {
          output.userAttributes[key] = String(val); // same format as api request response
        }
      },
    );
  }
  return output;
}

export async function getCurrentAuthCredentials(forceRefresh = false) {
  try {
    // check user currently logged in
    // Request made to cognito only if force refresh is true, otherwise reads from local storage
    await fetchAuthSession({ forceRefresh });
    return getAuthCredentials(forceRefresh);
  } catch (err) {
    return {
      username: '',
      jwt: '',
      expiry: -1,
      userAttributes: {},
    } as UserCredentials;
  }
}
