import { CallContextProvider, useCallContext } from './state';

export const useCall = () => {
  const context = useCallContext();

  return {
    ...context,
  };
};

export { CallContextProvider };
