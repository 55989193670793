import { PaletteOptions } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    border: {
      primary: string;
      secondary: string;
    };
    tertiary: {
      main: string;
      contrastText: string;
    };
    modal: {
      background: string;
    };
  }
  interface PaletteOptions {
    border: {
      primary: string;
      secondary: string;
    };
    tertiary: {
      main: string;
      contrastText: string;
    };
    modal: {
      background: string;
    };
  }
}

const palette: PaletteOptions = {
  mode: 'dark',
  background: {
    default: '#0d0d0d',
    paper: '#0d0d0d',
  },
  border: {
    primary: '#343434',
    secondary: '#272727',
  },
  text: {
    primary: '#fff',
    secondary: '#B0B0B0',
  },
  primary: {
    main: '#fff',
    contrastText: '#000',
  },
  secondary: {
    main: '#f50057',
    contrastText: '#fff',
  },
  tertiary: {
    main: '#3e3e3e',
    contrastText: '#fff',
  },
  error: {
    main: '#f44336',
  },
  warning: {
    main: '#ffa726',
  },
  info: {
    main: '#29b6f6',
  },
  success: {
    main: '#66bb6a',
  },
  divider: '#555',
  modal: {
    background: '#0f1011',
  },
};

export default palette;
