import { api as generatedApi } from 'services/generated';

export const collectionApi = generatedApi.enhanceEndpoints({
  addTagTypes: ['Collections'],
  endpoints: {
    TwinsGetCollection: {
      providesTags: ['Collections'],
      transformResponse: (res: any) => {
        return res;
      },
    },
    TwinsUpdateCollection: {
      invalidatesTags: ['Collections'],
      async onQueryStarted({ input }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          collectionApi.util.updateQueryData(
            'TwinsGetCollection',
            { input: { id: input.id } },
            (draft) => {
              const collectionToUpdate = draft.twinsGetCollection.data.find(
                (collection) => collection.id === input.id,
              );
              if (collectionToUpdate) {
                Object.assign(collectionToUpdate);
              }
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    },
    TwinsDeleteCollection: {
      invalidatesTags: ['Collections'],
    },
  },
});

export const {
  useTwinsGetCollectionQuery,
  useTwinsUpdateCollectionMutation,
  useTwinsDeleteCollectionMutation,
  useLazyTwinsGetCollectionQuery,
} = collectionApi;
