import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { ButtonIconTooltip, VoiceSpeedMenu, VoiceTuneMenu } from '..';
import { usePlayHT } from 'use/playht';
import { useVoice } from 'use/voice';
import { Status, VoiceStep } from 'types/enums';

const VoiceTestActionButtons: React.FC = () => {
  const { generating, generateSample, sampleText } = usePlayHT();
  const { voice } = useVoice();
  const [tuneAnchorEl, setTuneAnchorEl] = useState<HTMLElement | null>(null);
  const [speedAnchorEl, setSpeedAnchorEl] = useState<HTMLElement | null>(null);

  const handleTuneClick = (event?: {
    currentTarget: React.SetStateAction<HTMLElement>;
  }) => {
    setTuneAnchorEl(event.currentTarget);
  };

  const handleSpeedClick = (event?: {
    currentTarget: React.SetStateAction<HTMLElement>;
  }) => {
    setSpeedAnchorEl(event.currentTarget);
  };

  const handleTuneClose = () => {
    setTuneAnchorEl(null);
  };

  const handleSpeedClose = () => {
    setSpeedAnchorEl(null);
  };

  const handleSpeedChange = (newSpeed: number) => {
    console.log('Speed changed to', newSpeed);
  };

  const handleGenerateText = () => {
    generateSample();
    console.log('Generating text');
  };

  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
      >
        <ButtonIconTooltip
          isDisabled
          isLoading={false}
          tooltipTitle="Advanced Controls"
          icon="tune"
          onClick={handleTuneClick}
        />
        <ButtonIconTooltip
          isDisabled
          isLoading={false}
          tooltipTitle="Voice Speed"
          icon="speed"
          onClick={handleSpeedClick}
        />
        <LoadingButton
          disabled={
            !sampleText ||
            sampleText?.length < 5 ||
            voice?.step !== VoiceStep.complete ||
            (voice?.step === VoiceStep.complete &&
              voice?.status !== Status.complete)
          }
          size="small"
          variant="contained"
          sx={{ fontWeight: 600 }}
          loading={generating}
          onClick={handleGenerateText}
        >
          Generate
        </LoadingButton>
      </Stack>
      <VoiceTuneMenu
        anchorEl={tuneAnchorEl}
        open={Boolean(tuneAnchorEl)}
        onClose={handleTuneClose}
      />
      <VoiceSpeedMenu
        anchorEl={speedAnchorEl}
        open={Boolean(speedAnchorEl)}
        onClose={handleSpeedClose}
        onSpeedChange={handleSpeedChange}
      />
    </>
  );
};

export default VoiceTestActionButtons;
