import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useBlocker } from 'react-router-dom';
import { DeleteOutlineRounded, FileUploadOutlined } from '@mui/icons-material';
import { Divider, LinearProgress, Stack } from '@mui/material';
import { File as JobFile } from '@twins/types';
import { formatDate, formatFileSize, getFileStepIndex } from 'common/utils';
import {
  MetadataGroup,
  PreviewCard,
  PreviewHeader,
  PreviewOpenButton,
  ProgressStepper,
  ButtonTooltip,
  Button,
} from 'components/atoms';
import { useSnackbar } from 'use/snackbar';
import { useSuperfeelUser } from 'use/superfeel-user';
import { FileStage, FileStatus } from 'types/enums';
import {
  useTwinsDeleteFileMutation,
  useTwinsUpdateFileMutation,
} from 'services/collection.services.ts/file';
import { Modal } from 'components/organisms';
import { multipartUpload } from 'utils/multipart-upload';

interface FilePreviewProps {
  file: JobFile;
}

const steps = ['Upload', 'Process', 'Review', 'Submit'];

export default function FilePreview({ file }: FilePreviewProps) {
  const activeStep = getFileStepIndex(
    (file?.stage as FileStage) || FileStage.upload,
  );
  const { showSnackbar } = useSnackbar();
  const { superfeelUser } = useSuperfeelUser();
  const navigate = useNavigate();
  const { username } = useParams<{ username: string }>();

  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const uploadInProgress =
    isUploading || (uploadProgress > 0 && uploadProgress < 100);
  const blocker = useBlocker(uploadInProgress);

  const [updateFile, { isLoading: isUpdateLoading }] =
    useTwinsUpdateFileMutation();
  const [deleteFile, { isLoading: isDeleteLoading }] =
    useTwinsDeleteFileMutation();

  const handleUpload = useCallback(
    async (jobFile: JobFile, selectedFile: File) => {
      try {
        const key = `${superfeelUser?.userId}/${jobFile.collectionID}/${jobFile.id}.${jobFile.extension}`;
        // Set this env var in Amplify console on a per region basis
        const region = process.env.REACT_APP_AWS_REGION || 'eu-west-2';
        const bucketName = `superfeel-twins-raw-${process.env.REACT_APP_STAGE}-${region}`;

        setIsUploading(true);
        setUploadProgress(0);

        const uploadResult = await multipartUpload({
          file: selectedFile,
          onProgress: (percentage) => {
            setUploadProgress(percentage);
          },
          key,
          bucketName,
        });

        if (uploadResult.success) {
          const updatedJobFile: JobFile = {
            ...jobFile,
            key,
            stage: FileStage.process,
            status: FileStatus.pending,
          };
          await updateFile({ input: updatedJobFile });
          setUploadProgress(100);
          showSnackbar('File uploaded successfully', 'success');
        } else {
          showSnackbar(`File upload failed: ${uploadResult.message}`, 'error');
        }
      } catch (error) {
        showSnackbar(
          `Error uploading file: ${
            error instanceof Error ? error.message : String(error)
          }`,
          'error',
        );
      } finally {
        setUploadProgress(0);
        setIsUploading(false);
      }
    },
    [showSnackbar, superfeelUser?.userId, updateFile],
  );

  const toggleDeleteModal = useCallback(() => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  }, [isDeleteModalOpen]);

  const handleFileSelect = useCallback(
    async (event: ChangeEvent<HTMLInputElement>, fileId: string) => {
      const selectedFile = event.target.files?.[0];
      if (!selectedFile || isUpdateLoading) return;
      try {
        const fileExtension = selectedFile.name.split('.').pop() || '';
        const updatedJobFile: JobFile = {
          interviewer: file.interviewer,
          jobID: file.jobID,
          length: file.length,
          medallion: file.medallion,
          selected: file.selected,
          status: file.status,
          speakerID: file.speakerID,
          id: fileId,
          name: selectedFile.name,
          size: selectedFile.size,
          extension: fileExtension,
          speakers: 2,
          collectionID: file.collectionID,
          userID: file.userID,
          createdBy: file.createdBy,
          username,
        };

        await Promise.all([
          await updateFile({ input: updatedJobFile }),
          await handleUpload(updatedJobFile, selectedFile),
        ]);
      } catch (error) {
        console.error(error);
      }
    },
    [file, handleUpload, isUpdateLoading, updateFile, username],
  );

  const handleOpenFile = useCallback(
    () =>
      navigate(
        `/user/${superfeelUser.username}/data/collection/${file.collectionID}/file/${file.id}`,
      ),
    [file.collectionID, file.id, navigate, superfeelUser.username],
  );

  const handleDeleteFile = useCallback(
    async (fileId: string) => {
      if (!fileId) return;
      try {
        const result = await deleteFile({ input: { id: fileId } }).unwrap();
        if (result?.twinsDeleteFile?.success) {
          showSnackbar('File successfully deleted', 'success');
        }
      } catch (err) {
        console.error(err);
        showSnackbar('Could not delete file.', 'error');
      }
    },
    [deleteFile, showSnackbar],
  );

  useEffect(() => {
    if (blocker.state === 'blocked') {
      setIsConfirmationOpen(true);
    }
  }, [blocker.state]);

  const handleConfirmNavigation = useCallback(() => {
    blocker.proceed();
    setIsConfirmationOpen(false);
  }, [blocker]);

  const handleCancelNavigation = useCallback(() => {
    blocker.reset();
    setIsConfirmationOpen(false);
  }, [blocker]);

  const handleBrowserStateNavigation = useCallback(() => {
    if (!uploadInProgress) return;

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
      return '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [uploadInProgress]);

  useEffect(() => {
    handleBrowserStateNavigation();
  }, [handleBrowserStateNavigation, uploadInProgress]);

  const header = useMemo(
    () => (
      <PreviewHeader
        title={file?.name || 'File Name'}
        tooltip="Delete File"
        deleting={false}
        onDelete={toggleDeleteModal}
      />
    ),
    [file?.name, toggleDeleteModal],
  );

  const indicator = useMemo(
    () => (
      <>
        {file?.stage !== FileStage.upload && <Divider />}
        {uploadInProgress || file?.stage === FileStage.upload ? (
          <LinearProgress
            sx={{ height: 4 }}
            variant="determinate"
            value={uploadProgress}
          />
        ) : null}
      </>
    ),
    [file?.stage, uploadInProgress, uploadProgress],
  );

  const content = useMemo(
    () => (
      <ProgressStepper
        steps={steps}
        activeStep={activeStep}
      />
    ),
    [activeStep],
  );

  const actions = useMemo(
    () => (
      <>
        <ButtonTooltip
          disabled={
            file?.stage !== FileStage.upload ||
            isDeleteLoading ||
            isUpdateLoading ||
            uploadInProgress
          }
          title="Upload File"
          placement="top"
          size="small"
          variant="outlined"
          sx={{
            fontWeight: file?.stage === FileStage.upload ? 'bold' : 'normal',
          }}
          color={file?.stage === FileStage.upload ? 'info' : 'primary'}
          tooltipColorVariant={
            file?.stage === FileStage.upload ? 'info' : undefined
          }
          startIcon={<FileUploadOutlined fontSize="small" />}
          onClick={() =>
            document.getElementById(`file-input-${file.id}`)?.click()
          }
        >
          File
        </ButtonTooltip>
        <input
          id={`file-input-${file.id}`}
          type="file"
          accept="video/*,audio/*"
          style={{ display: 'none' }}
          onChange={(event) => handleFileSelect(event, file.id)}
          disabled={isDeleteLoading}
        />
        <MetadataGroup
          items={[
            { label: 'Speakers', value: file?.speakers || 0 },
            { label: 'File Size', value: formatFileSize(file?.size || 0) },
            {
              label: 'Uploaded',
              value: formatDate(file?.createdAt || new Date().toISOString()),
            },
            {
              label: 'Updated',
              value: formatDate(file?.updatedAt || new Date().toISOString()),
            },
          ]}
        />
        <PreviewOpenButton
          tooltip="Open File"
          onClick={handleOpenFile}
          disabled={
            file?.stage === FileStage.upload ||
            isDeleteLoading ||
            isUpdateLoading
          }
          action={
            file?.stage === FileStage.review || file?.stage === FileStage.submit
          }
        />
      </>
    ),
    [
      file?.createdAt,
      file.id,
      file?.size,
      file?.speakers,
      file?.stage,
      file?.updatedAt,
      handleFileSelect,
      handleOpenFile,
      isDeleteLoading,
      isUpdateLoading,
      uploadInProgress,
    ],
  );

  return (
    <>
      <PreviewCard
        header={header}
        indicator={indicator}
        content={content}
        actions={actions}
      />
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={toggleDeleteModal}
        title="Delete Confirmation"
        width={480}
        actions={
          <Stack
            width="100%"
            direction="row"
            justifyContent="space-between"
          >
            <Button
              startIcon={<DeleteOutlineRounded />}
              variant="contained"
              color="error"
              label="Delete"
              onClick={() => handleDeleteFile(file.id)}
              disabled={isDeleteLoading || isUpdateLoading}
              loading={isDeleteLoading}
              width={100}
            />
          </Stack>
        }
      >
        Are you sure you want to delete? <br />
        This action is irreversible.
      </Modal>

      <Modal
        isOpen={isConfirmationOpen}
        onClose={handleCancelNavigation}
        title="Upload in Progress"
        width={480}
        actions={
          <Stack
            width="100%"
            direction="row"
            justifyContent="flex-end"
            spacing={2}
          >
            <Button
              variant="contained"
              color="primary"
              label="Stay"
              onClick={handleCancelNavigation}
              width={100}
            />
            <Button
              variant="contained"
              color="secondary"
              label="Leave"
              onClick={handleConfirmNavigation}
              width={100}
            />
          </Stack>
        }
      >
        An upload is in progress. Are you sure you want to navigate away?
      </Modal>
    </>
  );
}
