interface AppsyncError {
  path: string[];
  data: unknown;
  errorType: string;
  errorInfo: unknown;
  locations: {
    line: number;
    column: number;
    sourceName: string;
  }[];
  message: string;
}

export interface AppsyncErrorResponse {
  data: unknown;
  errors: AppsyncError[];
}

export const appsyncErrorConverter = (
  appsyncError: AppsyncErrorResponse | AppsyncError,
  query = '',
  variables = {},
) => {
  const init = appsyncError as AppsyncError;
  if (init?.message) {
    return new Error(init.message);
  }

  const error = appsyncError as AppsyncErrorResponse;
  const allErrors = error?.errors || [];
  if (!allErrors.length) {
    return new Error('Unknown Appsync error');
  }

  const primaryError = allErrors[0];
  const errorPath = primaryError.path?.[0] || 'Unknown handler';
  const message = primaryError.message || 'Unknown error';
  const queryStem = query ? query.split('(')[0] : 'Unknown';
  return new Error(
    `Error at ${errorPath}: ${message}. Query: ${queryStem}. Variables: ${JSON.stringify(
      variables,
    )}`,
  );
};

type AWSErrorCode =
  | 'InvalidParameterException'
  | 'UsernameExistsException'
  | 'UserNotConfirmedException'
  | 'NotAuthorizedException'
  | 'UserNotFoundException'
  | 'AliasExistsException';

export type AWSError = {
  name: AWSErrorCode;
  message: string;
};
