import { VOICE } from 'common/model/options';
import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useMemo,
  useCallback,
} from 'react';
import { ModelConfig, Prompt, Model, Memory, Document } from '@twins/types';

interface ModelConfigState {
  changed: boolean;
  setChanged: (value: boolean) => void;
  modelTemperature: number;
  setModelTemperature: (value: number) => void;
  systemPrompt: string;
  setSystemPrompt: (value: string) => void;
  firstMessage: string;
  setFirstMessage: (value: string) => void;
  modelConfig: ModelConfig | undefined | null;
  setModelConfig: (
    value:
      | ModelConfig
      | ((
          prev: ModelConfig | undefined | null,
        ) => ModelConfig | undefined | null),
  ) => void;
  voiceID: string;
  setVoiceID: (value: string) => void;
  voiceStability: number;
  setVoiceStability: (value: number) => void;
  voiceClarity: number;
  setVoiceClarity: (value: number) => void;
  voiceExaggeration: number;
  setVoiceExaggeration: (value: number) => void;
  voiceLatency: number;
  setVoiceLatency: (value: number) => void;
  voiceSpeakerBoost: boolean;
  setVoiceSpeakerBoost: (value: boolean) => void;
  prompts: Prompt[];
  setPrompts: (value: Prompt[]) => void;
  models: Model[];
  setModels: (value: Model[]) => void;
  memory: Memory[];
  setMemory: (value: Memory[]) => void;
  documents: Document[];
  setDocuments: (value: Document[]) => void;
  selectedDocument: Document | null;
  setSelectedDocument: (value: Document | null) => void;
  deletingModelConfig: boolean;
  setDeletingModelConfig: (value: boolean) => void;
  reset: () => void;
}

const ModelConfigContext = createContext<ModelConfigState | undefined>(
  undefined,
);

export const useModelConfigContext = () => {
  const context = useContext(ModelConfigContext);
  if (!context) {
    throw new Error(
      'useModelConfig must be used within an ModelConfigProvider',
    );
  }
  return context;
};

interface ModelConfigProviderProps {
  children: ReactNode;
}

export function ModelConfigProvider({
  children,
}: ModelConfigProviderProps): JSX.Element {
  const [systemPrompt, setSystemPrompt] = useState<string>('');
  const [firstMessage, setFirstMessage] = useState<string>('');
  const [modelConfig, setModelConfig] = useState<
    ModelConfig | undefined | null
  >(undefined);
  const [voiceID, setVoiceID] = useState<string>(VOICE.Muddy);
  const [voiceStability, setVoiceStability] = useState<number>(0.7);
  const [voiceClarity, setVoiceClarity] = useState<number>(0.9);
  const [voiceExaggeration, setVoiceExaggeration] = useState<number>(0);
  const [voiceLatency, setVoiceLatency] = useState<number>(0);
  const [voiceSpeakerBoost, setVoiceSpeakerBoost] = useState<boolean>(false);
  const [modelTemperature, setModelTemperature] = useState<number>(0.2);
  const [prompts, setPrompts] = useState<Prompt[]>([]);
  const [models, setModels] = useState<Model[]>([]);
  const [changed, setChanged] = useState<boolean>(false);
  const [memory, setMemory] = useState<Memory[]>([]);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(
    null,
  );
  const [deletingModelConfig, setDeletingModelConfig] =
    useState<boolean>(false);

  const reset = useCallback(() => {
    setSystemPrompt('');
    setFirstMessage('');
    setModelConfig(undefined);
    setVoiceID('');
    setVoiceStability(0);
    setVoiceClarity(0);
    setVoiceExaggeration(0);
    setVoiceLatency(0);
    setVoiceSpeakerBoost(false);
    setModelTemperature(0.2);
    setPrompts([]);
    setModels([]);
    setChanged(false);
    setMemory([]);
    setDocuments([]);
    setSelectedDocument(null);
    setDeletingModelConfig(false);
  }, [
    setSystemPrompt,
    setFirstMessage,
    setModelConfig,
    setVoiceID,
    setVoiceStability,
    setVoiceClarity,
    setVoiceExaggeration,
    setVoiceLatency,
    setVoiceSpeakerBoost,
    setModelTemperature,
    setPrompts,
    setModels,
    setChanged,
    setMemory,
    setDocuments,
    setSelectedDocument,
    setDeletingModelConfig,
  ]);

  const contextValue = useMemo(
    () => ({
      modelTemperature,
      setModelTemperature,
      systemPrompt,
      setSystemPrompt,
      firstMessage,
      setFirstMessage,
      modelConfig,
      setModelConfig,
      voiceID,
      setVoiceID,
      voiceStability,
      setVoiceStability,
      voiceClarity,
      setVoiceClarity,
      voiceExaggeration,
      setVoiceExaggeration,
      voiceLatency,
      setVoiceLatency,
      voiceSpeakerBoost,
      setVoiceSpeakerBoost,
      prompts,
      setPrompts,
      models,
      setModels,
      changed,
      setChanged,
      memory,
      setMemory,
      documents,
      setDocuments,
      selectedDocument,
      setSelectedDocument,
      reset,
      setDeletingModelConfig,
      deletingModelConfig,
    }),
    [
      modelTemperature,
      setModelTemperature,
      systemPrompt,
      setSystemPrompt,
      firstMessage,
      setFirstMessage,
      modelConfig,
      setModelConfig,
      voiceID,
      setVoiceID,
      voiceStability,
      setVoiceStability,
      voiceClarity,
      setVoiceClarity,
      voiceExaggeration,
      setVoiceExaggeration,
      voiceLatency,
      setVoiceLatency,
      voiceSpeakerBoost,
      setVoiceSpeakerBoost,
      prompts,
      setPrompts,
      models,
      setModels,
      changed,
      setChanged,
      memory,
      setMemory,
      documents,
      setDocuments,
      selectedDocument,
      setSelectedDocument,
      reset,
      setDeletingModelConfig,
      deletingModelConfig,
    ],
  );

  return (
    <ModelConfigContext.Provider value={contextValue}>
      {children}
    </ModelConfigContext.Provider>
  );
}
