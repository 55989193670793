import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormLabel,
  Stack,
  Typography,
} from '@mui/material';
import { executeGraphqlOperation } from 'api';
import { GraphQLResult } from 'aws-amplify/api';
import { menuOptions } from 'common/utils';
import { ButtonIconTooltip, FormField, Select } from 'components/atoms';
import { useSnackbar } from 'use/snackbar';
import { twinsUpdateVoiceMutation } from 'graphql/mutations';
import { useState } from 'react';
import { AIProvider, AIType, MODEL_PROVIDERS } from 'types/enums';
import { v4 as uuid } from 'uuid';
import { useUser } from 'use/user';
interface AddVoiceProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  refetchVoices: () => void;
}

export function AddVoice({ open, setOpen, refetchVoices }: AddVoiceProps) {
  const { showSnackbar } = useSnackbar();
  const [name, setName] = useState('');
  const [voiceID, setVoiceID] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [userID, setUserID] = useState('');
  const [manifestURI, setManifestURI] = useState('');
  const [provider, setProvider] = useState(AIProvider.ELEVENLABS);
  const handleProviderChange = (value: string) => {
    setProvider(value as AIProvider);
  };
  const [saving, setSaving] = useState(false);
  const { getJWT } = useUser();

  const availableProviders = Object.keys(
    MODEL_PROVIDERS[AIType.TTS] || {},
  ) as AIProvider[];
  const selectedProvider = (provider ||
    AIProvider.ELEVENLABS) as keyof (typeof MODEL_PROVIDERS)[typeof AIType.TTS];

  const saveVoice = async () => {
    try {
      setSaving(true);

      const saveVoiceInput = {
        id: voiceID === '' ? uuid() : voiceID,
        name,
        provider,
        userID,
        apiKey,
        manifestURI,
      };
      const jwt = await getJWT();
      const response: GraphQLResult<unknown> = await executeGraphqlOperation(
        twinsUpdateVoiceMutation,
        { input: saveVoiceInput },
        jwt,
      );
      if (response.data) {
        const result = response.data as {
          twinsUpdateVoice: {
            success: boolean;
            message: string;
          };
        };
        if (result.twinsUpdateVoice.success) {
          refetchVoices();
          setOpen(false);
        } else {
          showSnackbar('Failed to save voice', 'error');
        }
      }
    } catch (e) {
      showSnackbar(`Failed to save voice: ${e as string}`, 'error');
    } finally {
      setSaving(false);
    }
  };

  const handleSaveVoice = () => {
    saveVoice();
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      fullWidth
      maxWidth="sm"
    >
      <Stack
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h5">Add Voice</Typography>
        <ButtonIconTooltip
          onClick={() => setOpen(false)}
          isLoading={false}
          tooltipTitle="Close"
          icon="close"
          tooltipColorVariant="info"
        />
      </Stack>
      <Divider />
      <DialogContent>
        <Stack
          spacing={2}
          direction="column"
        >
          {selectedProvider === AIProvider.ELEVENLABS && (
            <FormField
              value={voiceID}
              setValue={setVoiceID}
              label="Voice ID"
            />
          )}
          <FormField
            value={name}
            setValue={setName}
            label="Name"
          />
          <FormLabel>Provider</FormLabel>
          <Select
            value={selectedProvider}
            defaultValue={AIProvider.ELEVENLABS}
            onChange={handleProviderChange}
            options={menuOptions(availableProviders)}
          />
          {selectedProvider === AIProvider.PLAYHT && (
            <FormField
              value={userID}
              setValue={setUserID}
              label="User ID"
            />
          )}
          <FormField
            value={apiKey}
            setValue={setApiKey}
            label="API Key"
          />
          {selectedProvider === AIProvider.PLAYHT && (
            <FormField
              value={manifestURI}
              setValue={setManifestURI}
              label="Manifest URI"
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          size="small"
          variant="outlined"
          sx={{ fontWeight: 'bold' }}
          loading={saving}
          onClick={handleSaveVoice}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
