/* eslint-disable no-shadow */

export enum PromptRole {
  SYSTEM = 'system',
  USER = 'user',
  ASSISTANT = 'assistant',
}

export enum MemoryType {
  LOCALVECTOR = 'local_vector',
  GRAPH = 'graph',
}

export enum AIType {
  LLM = 'llm',
  TTS = 'tts',
  ASR = 'asr',
}

export enum AIProvider {
  OPENAI = 'openai',
  ANTHROPIC = 'anthropic',
  DEEPGRAM = 'deepgram',
  ELEVENLABS = 'elevenlabs',
  PLAYHT = 'playht',
  UNKNOWN = 'unknown',
}

export enum AIModels {
  CLAUDE_3_5_HAIKU = 'claude-3-haiku-20240307',
  CLAUDE_3_5_SONNET = 'claude-3-5-sonnet_20240620',
  CLAUDE_3_5_OPUS = 'claude-3-opus-20240229',
  GPT_3_5_TURBO = 'gpt-3.5-turbo-0125',
  GPT_4O = 'gpt-4o',
  GPT_4 = 'gpt-4-turbo',
  ELEVEN_TURBO_V2 = 'eleven_turbo_v2',
  ELEVEN_MULTILINGUAL_V2 = 'eleven_multilingual_v2',
  ELEVEN_MULTILINGUAL_V1 = 'eleven_multilingual_v1',
  ELEVEN_MONOLINGUAL_V1 = 'eleven_monolingual_v1',
  NOVA_2 = 'nova_2',
  NOVA_2_GENERAL = 'nova_2_general',
  NOVA_2_MEDICAL = 'nova_2_medical',
  NOVA_2_LEGAL = 'nova_2_legal',
  NOVA_2_FINANCIAL = 'nova_2_financial',
  NOVA_2_MEETING = 'nova_2_meeting',
  NOVA_2_PHONECALL = 'nova_2_phonecall',
  NOVA_2_CONVERSATIONALAI = 'nova_2_conversationalai',
  NOVA_2_VIDEO = 'nova_2_video',
  NOVA_2_DRIVETHRU = 'nova_2_drivethru',
  NOVA_2_AUTOMOTIVE = 'nova_2_automotive',
  PLAY_HT_2_TURBO = 'play_ht_2_turbo',
  PLAY_HT_2 = 'play_ht_2',
  PLAY_HT_1 = 'play_ht_1',
}

interface ModelProviders {
  [AIType.TTS]: {
    [AIProvider.ELEVENLABS]: AIModels[];
    [AIProvider.PLAYHT]: AIModels[];
  };
  [AIType.LLM]: {
    [AIProvider.OPENAI]: AIModels[];
    [AIProvider.ANTHROPIC]: AIModels[];
  };
  [AIType.ASR]: {
    [AIProvider.DEEPGRAM]: AIModels[];
  };
}

export const MODEL_PROVIDERS: ModelProviders = {
  [AIType.TTS]: {
    [AIProvider.ELEVENLABS]: [
      AIModels.ELEVEN_TURBO_V2,
      AIModels.ELEVEN_MULTILINGUAL_V2,
      AIModels.ELEVEN_MULTILINGUAL_V1,
      AIModels.ELEVEN_MONOLINGUAL_V1,
    ],
    [AIProvider.PLAYHT]: [
      AIModels.PLAY_HT_2_TURBO,
      AIModels.PLAY_HT_2,
      AIModels.PLAY_HT_1,
    ],
  },
  [AIType.LLM]: {
    [AIProvider.ANTHROPIC]: [
      AIModels.CLAUDE_3_5_HAIKU,
      AIModels.CLAUDE_3_5_OPUS,
      AIModels.CLAUDE_3_5_SONNET,
    ],
    [AIProvider.OPENAI]: [
      AIModels.GPT_3_5_TURBO,
      AIModels.GPT_4O,
      AIModels.GPT_4,
    ],
  },
  [AIType.ASR]: {
    [AIProvider.DEEPGRAM]: [
      AIModels.NOVA_2,
      AIModels.NOVA_2_GENERAL,
      AIModels.NOVA_2_MEDICAL,
      AIModels.NOVA_2_LEGAL,
      AIModels.NOVA_2_FINANCIAL,
      AIModels.NOVA_2_MEETING,
      AIModels.NOVA_2_PHONECALL,
      AIModels.NOVA_2_CONVERSATIONALAI,
      AIModels.NOVA_2_VIDEO,
      AIModels.NOVA_2_DRIVETHRU,
      AIModels.NOVA_2_AUTOMOTIVE,
    ],
  },
};

export enum Languages {
  EN = 'en',
  ES = 'es',
  FR = 'fr',
  DE = 'de',
  ZH = 'zh',
  JA = 'ja',
  KO = 'ko',
  IT = 'it',
  PT = 'pt',
  RU = 'ru',
  AR = 'ar',
  HI = 'hi',
  BN = 'bn',
  UR = 'ur',
  NL = 'nl',
  EL = 'el',
  HE = 'he',
  SV = 'sv',
  TR = 'tr',
  VI = 'vi',
}

export enum PromptPosition {
  MAIN = 'MAIN',
  START = 'START',
}

export enum PromptRoles {
  ASSISTANT = 'assistant',
  SYSTEM = 'system',
  USER = 'user',
}

export enum Role {
  User = 'user',
  Admin = 'admin',
}

export enum numbererviewsStatus {
  Waiting = 'waiting',
  Loading = 'loading',
  PreProcessing = 'preProcessing',
  PreProcessed = 'preProcessed',
}

export enum GraphStatus {
  Waiting = 'waiting',
  Loading = 'loading',
  PreProcessing = 'preProcessing',
  PreProcessed = 'preProcessed',
}

export enum ModelStatus {
  Waiting = 'waiting',
  Loading = 'loading',
  PreProcessing = 'preProcessing',
  PreProcessed = 'preProcessed',
}

export enum JobStage {
  capture = 'capture',
  process = 'process',
  review = 'review',
  submit = 'submit',
}

export enum JobAction {
  interview_process_start = 'interview_process_start',
  tts_select_start = 'tts_select_start',
  tts_train_start = 'tts_train_start',
  graph_ingestion_start = 'graph_ingestion_start',
}

export enum JobType {
  interview = 'interview',
  tts = 'tts',
  graph = 'graph',
}

// COLLECTION

export enum CollectionType {
  interview = 'interview',
  tts = 'tts',
  memory = 'memory',
}

// FILES

export enum FileStatus {
  submitted = 'submitted',
  pending = 'pending',
  processing = 'processing',
  complete = 'complete',
  failed = 'failed',
}

export enum Status {
  submitted = 'submitted',
  pending = 'pending',
  running = 'running',
  processing = 'processing',
  complete = 'complete',
  failed = 'failed',
}

export enum FileStage {
  upload = 'upload',
  process = 'process',
  review = 'review',
  submit = 'submit',
  complete = 'complete',
}

export enum FileType {
  wav = 'wav',
  mp3 = 'mp3',
  flac = 'flac',
  ogg = 'ogg',
  aac = 'aac',
  m4a = 'm4a',
  mov = 'mov',
  mp4 = 'mp4',
  mkv = 'mkv',
  avi = 'avi',
  wmv = 'wmv',
  pdf = 'pdf',
  doc = 'doc',
  docx = 'docx',
  txt = 'txt',
  rtf = 'rtf',
  md = 'md',
  unknown = 'unknown',
}

// VOICE

export enum VoiceType {
  instant = 'instant',
}

export enum VoiceStep {
  select = 'select',
  clean = 'clean',
  review = 'review',
  train = 'train',
  complete = 'complete',
}
export enum Audience {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
  Friends = 'FRIENDS',
  Circle = 'CIRCLE',
}

export enum AdminEmailRole {
  IDEABANK = 'IDEABANK',
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum JobStatus {
  submitted = 'submitted',
  pending = 'pending',
  starting = 'starting',
  running = 'running',
  complete = 'complete',
  failed = 'failed',
  unknown = 'unknown',
}

export enum FileMedallion {
  gold = 'gold',
  silver = 'silver',
  bronze = 'bronze',
}

export enum FileSource {
  interview = 'interview',
  feel = 'feel',
}
