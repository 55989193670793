import React from 'react';
import {
  Grid,
  Stack,
  FormLabel,
  Box,
  Tooltip,
  IconButton,
} from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';

interface ModelSettingsItemProps {
  label: string;
  action?: React.ReactNode;
  tooltip?: string;
  infoTooltip?: string;
  children: React.ReactNode;
}

export function ModelSettingsItem({
  label,
  action,
  tooltip,
  infoTooltip,
  children,
}: ModelSettingsItemProps) {
  return (
    <Grid
      item
      xs={12}
      md={6}
    >
      <Stack
        direction="column"
        spacing={1}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
        >
          <FormLabel>{label}</FormLabel>
          {infoTooltip && (
            <Tooltip
              title={infoTooltip}
              placement="top"
            >
              <IconButton size="small">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          {action && (
            <Tooltip
              title={tooltip || ''}
              placement="top"
            >
              <Box>{action}</Box>
            </Tooltip>
          )}
          <Box height={34} />
        </Stack>
        {children}
      </Stack>
    </Grid>
  );
}
