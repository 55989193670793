import React, { useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { DateSelect, InfoLabel } from 'components/atoms';
import InfoTextField from 'components/atoms/info-text-field';
import { Collection } from '@twins/types';
import { DateTime } from 'luxon';
import { parseAwsDateTime } from 'common/utils';
import { useTwinsUpdateCollectionMutation } from 'services/collection.services.ts/collection';
import { omit } from 'lodash';

interface FileCollectionDetailsProps {
  collection: Collection;
}

function FileCollectionDetails({
  collection,
}: FileCollectionDetailsProps): JSX.Element {
  const [updateCollection] = useTwinsUpdateCollectionMutation();
  const [collectionName, setCollectionName] = useState(collection.name || '');
  const [interviewerName, setInterviewerName] = useState(collection.interviewer || '');

  const saveNewCollectionName = async (newName: string) => {
    try {
      const updatedCollection = {
        ...collection,
        name: newName,
      };
      const input = omit(updatedCollection, ['createdAt', 'updatedAt']);
      await updateCollection({
        input,
      }).unwrap();
    } catch (error) {
      console.error('Failed to update collection name:', error);
    }
  };

  const handleDateChange = async (newDate: DateTime | null) => {
    try {
      const updatedCollection = {
        ...collection,
        date: newDate ? newDate.toUTC().toISO() : null,
      };
      const input = omit(updatedCollection, ['createdAt', 'updatedAt']);
      await updateCollection({
        input,
      }).unwrap();
    } catch (error) {
      console.error('Failed to update collection date:', error);
    }
  };

  const saveNewInterviewerName = async (newInterviewer: string) => {
    try {
      const updatedCollection = {
        ...collection,
        interviewer: newInterviewer,
      };
      const input = omit(updatedCollection, ['createdAt', 'updatedAt']);
      await updateCollection({
        input,
      }).unwrap();
    } catch (error) {
      console.error('Failed to update interviewer:', error);
    }
  };

  return (
    <Box>
      <Typography variant="h3">File Collection</Typography>
      <Divider sx={{ my: 2 }} />
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          md={6}
        >
          <InfoTextField
            label="Collection Name"
            tooltip="Give a name to this group of files, e.g. 'Initial Bio-Encoding'."
            value={collectionName}
            onChange={setCollectionName}
            onBlur={() => saveNewCollectionName(collectionName)}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
        >
          <InfoLabel
            label="Capture Date"
            tooltip="Enter the date (in your local timezone) where these files were recorded."
            placement="top"
          />
          <DateSelect
            boxStyles={{}}
            value={parseAwsDateTime(collection?.date)}
            onChange={handleDateChange}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
        >
          <InfoTextField
            label="Interviewer"
            tooltip="Enter the first and last name of the interviewer."
            value={interviewerName}
            onChange={setInterviewerName}
            onBlur={() => saveNewInterviewerName(interviewerName)}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default FileCollectionDetails;
