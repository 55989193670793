import { useAppContext, AppContextProvider } from './state';

export const useApp = () => {
  const context = useAppContext();

  return {
    ...context,
  };
};

export { AppContextProvider };
