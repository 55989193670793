export const MODEL_TRANSCRIBERS = {
  deepgram: 'deepgram',
};

export const MODELPROVIDERS = {
  OPENAI: 'OpenAI',
  META: 'Meta',
  MICROSOFT: 'Microsoft',
  ELEVENLABS: 'ElevenLabs',
};

export const OPENAI_MODELS = {
  GPT4: 'GPT-4',
  GPT4O: 'GPT-4o',
  GPT35TURBO: 'GPT-3.5 Turbo',
};

export const MODElTYPES = {
  ASR: 'ASR',
  TTS: 'TTS',
  LLM: 'LLM',
};

export const VOICE = {
  JeshV2: 'FcMCw6cVLiSSdWUMRPpj',
  Muddy: 'dukGa2zyS5RPAIo9ADSy',
  Cally: 'IQeoMUBoM7ojDoYlykS8',
  Khalid: 'tlETan7Okc4pzjD0z62P',
};

export const VOICE_MODEL = [
  {
    modal: 'Eleven Multilingual V2',
    description:
      'Our state-of-the-art multilingual speech synthesis model, able to generate life-like speech in 29 languages.',
  },
  {
    modal: 'Eleven Turbo V2',
    description:
      'Our cutting-edge turbo model is ideally suited for tasks demanding extremely low latency.',
  },
  {
    modal: 'Eleven Turbo V2.5 (Prototype)',
    description: 'Currently only available with our default credentials.',
  },
  {
    modal: 'Eleven English V1',
    description:
      'Use our standard English language model to generate speech in a variety of voices, styles and moods.',
  },
];

export const MODELROLES = {
  CLASSIFICATION: 'Classification',
  CONTEXTSUMMARY: 'ContextSummary',
  CONVERSATIONAL: 'Conversational',
  STYLETRANSFER: 'Style Transfer',
  ASR: 'Speech To Text',
  TTS: 'Text To Speech',
};

export const LANGUAGES = [
  { label: 'English', value: 'en' },
  { label: 'Spanish', value: 'es' },
  { label: 'French', value: 'fr' },
  { label: 'German', value: 'de' },
  { label: 'Chinese', value: 'zh' },
  { label: 'Japanese', value: 'ja' },
  { label: 'Korean', value: 'ko' },
  { label: 'Italian', value: 'it' },
  { label: 'Portuguese', value: 'pt' },
  { label: 'Russian', value: 'ru' },
  { label: 'Arabic', value: 'ar' },
  { label: 'Hindi', value: 'hi' },
  { label: 'Bengali', value: 'bn' },
  { label: 'Urdu', value: 'ur' },
  { label: 'Dutch', value: 'nl' },
  { label: 'Greek', value: 'el' },
  { label: 'Hebrew', value: 'he' },
  { label: 'Swedish', value: 'sv' },
  { label: 'Turkish', value: 'tr' },
  { label: 'Vietnamese', value: 'vi' },
];

export const TRANSCRIBER_MODELS = [
  { value: 'nova-2', label: 'Nova 2' },
  {
    value: 'nova-2-general',
    label: 'Nova 2 - General',
  },
  { label: 'Nova 2 - Meeting', value: 'nova-2-meeting' },
  { label: 'Nova 2 - Phone Call', value: 'nova-2-phonecall' },
  { label: 'Nova 2 - Finance', value: 'nova-2-finance' },
  { label: 'Nova 2 - Conversational AI', value: 'nova-2-conversationala' },
  { label: 'Nova 2 - Video', value: 'nova-2-video' },
  {
    label: 'Nova 2 - Medical',
    value: 'nova-2-medical',
  },
  {
    label: 'Nova 2 - DriveThru',
    value: 'nova-2-drivethru',
  },
  {
    label: 'Nova 2 - Automative',
    value: 'nova-2-automotive',
  },
];
