import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { formatFileSize, getFileStepIndex } from 'common/utils';
import { FileReviewSample, ProgressStepper } from 'components/atoms';
import { FileBottomRow } from 'components/molecules';
import { useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useFile } from 'use/file';
import { useJob } from 'use/job';
import { useSnackbar } from 'use/snackbar';
import { FileStage, FileStatus } from 'types/enums';
import { CloudSync } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { File } from '@twins/types';
import {
  useTwinsGetFileQuery,
  useTwinsUpdateFileMutation,
} from 'services/collection.services.ts/file';

export default function UserFile() {
  const { fileID } = useParams<{ fileID: string }>();
  const { jobs, getJobsByFileID } = useJob();
  const { data } = useTwinsGetFileQuery({
    input: {
      id: fileID,
    },
  });
  let file;
  if (data && data.twinsGetFile.data[0]) {
    file = data.twinsGetFile.data[0];
  }

  const [updateFile] = useTwinsUpdateFileMutation();
  const { updateSpeakerIDs, setSubmitting } = useFile();

  const { showSnackbar } = useSnackbar();
  const hasFetchedRef = useRef(false);

  const fetchFileAndJobs = useCallback(
    async (fileID: string) => {
      getJobsByFileID(fileID);
    },
    [getJobsByFileID],
  );

  const adjustSpeakerIDs = (
    speakerIDs: string[],
    numSamples: number,
  ): string[] => {
    if (speakerIDs.length === numSamples) {
      return speakerIDs;
    }
    if (speakerIDs.length > numSamples) {
      return speakerIDs.slice(0, numSamples);
    }
    return [...speakerIDs, ...Array(numSamples - speakerIDs.length).fill('')];
  };

  const allSpeakersAssigned = (speakerIDs: string[]): boolean => {
    return speakerIDs.every((id) => id !== '');
  };

  useEffect(() => {
    if (!hasFetchedRef.current && fileID) {
      fetchFileAndJobs(fileID);
      hasFetchedRef.current = true;
    }
  }, [fileID, fetchFileAndJobs]);

  const handleSpeakerChange = useCallback(
    async (speakerLabel: string, newSpeakerID: string) => {
      const speakerIndex = parseInt(speakerLabel.split('_')[1], 10);

      const numSamples = jobs[0]?.samples?.length ?? 1;

      if (data) {
        let updatedSpeakerIDs = [...(file.speakerID || [])];
        updatedSpeakerIDs = adjustSpeakerIDs(updatedSpeakerIDs, numSamples);
        updatedSpeakerIDs[speakerIndex] = newSpeakerID;
        let input: File = {
          id: file.id,
          speakerID: updatedSpeakerIDs,
        };

        if (
          allSpeakersAssigned(updatedSpeakerIDs) &&
          file.stage === FileStage.review
        ) {
          input.stage = FileStage.submit;
        }

        try {
          const result = await updateFile({ input }).unwrap();
          if (!result.twinsUpdateFile.success) {
            console.error(
              'File update failed:',
              result.twinsUpdateFile.message,
            );
          }
        } catch (error) {
          console.error('Error updating file:', error);
        }
      }
    },
    [data, file, jobs, updateFile],
  );

  const assignSpeakers = useCallback(async () => {
    if (file && jobs?.length > 0) {
      const numSamples = jobs[0]?.samples?.length ?? 1;
      const adjustedSpeakerIDs = adjustSpeakerIDs(
        file.speakerID || [],
        numSamples,
      );

      if (
        allSpeakersAssigned(adjustedSpeakerIDs) &&
        file.stage === FileStage.review
      ) {
        console.log('All speakers have been assigned');
        const input = {
          ...file,
          stage: FileStage.submit,
          speakerID: adjustedSpeakerIDs,
        };
        await updateFile({ input }).unwrap();
      }
    }
  }, [file, jobs, updateFile]);

  useEffect(() => {
    assignSpeakers();
  }, [assignSpeakers, file, jobs]);

  const handleSubmit = async () => {
    if (file) {
      setSubmitting(true);
      let input: File;
      if (file.stage === FileStage.submit) {
        await updateSpeakerIDs(file, file.speakerID);
        input = {
          ...file,
          stage: FileStage.complete,
        };
      } else {
        input = {
          ...file,
          stage: FileStage.submit,
          status: FileStatus.pending,
        };
      }

      try {
        const result = await updateFile({ input }).unwrap();
        if (result.twinsUpdateFile.success) {
          if (input.stage === FileStage.complete) {
            showSnackbar('File submitted successfully', 'success');
          } else {
            showSnackbar('File submitted for processing', 'success');
          }
        } else {
          showSnackbar(
            `Update failed: ${result.twinsUpdateFile.message}`,
            'error',
          );
        }
      } catch (error) {
        console.error('Error updating file:', error);
        showSnackbar('An error occurred while updating the file', 'error');
      } finally {
        setSubmitting(false);
      }
    }
  };

  const activeStep = getFileStepIndex(
    (file?.stage as FileStage) || FileStage.upload,
  );

  const steps = ['Upload', 'Process', 'Review', 'Submit'];
  return (
    <Box pb={5}>
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Stack direction="column">
            <Typography
              variant="h3"
              lineHeight={1}
            >
              {file?.name}
            </Typography>
            <Typography
              color="grey"
              sx={{
                textTransform: 'uppercase',
                fontSize: '0.7rem',
                lineHeight: 1.5,
              }}
              variant="h6"
            >
              File Name
            </Typography>
          </Stack>
          <Stack direction="column">
            <Typography
              lineHeight={0.95}
              variant="h3"
              fontWeight={300}
              color="grey"
            >
              {formatFileSize(file?.size || 0)}
            </Typography>
            <Typography
              textAlign={'right'}
              color="grey"
              sx={{
                textTransform: 'uppercase',
                fontSize: '0.7rem',
                lineHeight: 1.5,
              }}
              variant="h6"
            >
              File Size
            </Typography>
          </Stack>
        </Box>
        <Divider />
        <Box pt={3}>
          <Box my={2}>
            <ProgressStepper
              steps={steps}
              activeStep={activeStep}
            />
          </Box>
        </Box>
        {file?.stage === FileStage.process &&
          file?.status === FileStatus.pending && (
            <Box>
              <Divider sx={{ my: 1 }} />
              <Box>
                <Alert
                  severity="info"
                  sx={{ m: 2 }}
                >
                  <AlertTitle>Processing ...</AlertTitle>
                  Please check again in a few minutes.
                </Alert>
              </Box>
            </Box>
          )}
        <Divider sx={{ my: 1, opacity: 0.25 }} />
        <Box
          pt={3}
          pb={6}
        >
          <Box>
            <Typography
              sx={{ textTransform: 'uppercase', fontSize: '0.7rem' }}
              fontWeight="bold"
              variant="h6"
            >
              Samples
            </Typography>
            {jobs && jobs[0].samples?.length > 0 ? (
              jobs[0].samples?.map((sample, index) => (
                <Box key={sample.speaker}>
                  <FileReviewSample
                    sample={sample}
                    speakerIDs={file?.speakerID || []}
                    onSpeakerChange={(newSpeakerID) => {
                      handleSpeakerChange(sample.speaker, newSpeakerID);
                    }}
                  />
                </Box>
              ))
            ) : (
              <Card sx={{ m: 2 }}>
                <CardContent
                  sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                  >
                    <CloudSync sx={{ color: grey[500] }} />
                    <Typography
                      variant="h5"
                      sx={{ color: grey[500] }}
                      fontWeight={600}
                    >
                      No samples available
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            )}
          </Box>
        </Box>
      </Box>
      <FileBottomRow onSubmit={handleSubmit} />
    </Box>
  );
}
