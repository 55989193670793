export const PERMISSION_LEVELS = {
  USER: 'user',
  ADMIN: 'admin',
  SUPERADMIN: 'superadmin',
};

export const PERMISSION_LEVEL_OPTIONS = [
  { value: PERMISSION_LEVELS.USER, label: 'User' },
  { value: PERMISSION_LEVELS.ADMIN, label: 'Admin' },
  { value: PERMISSION_LEVELS.SUPERADMIN, label: 'Super Admin' },
];
