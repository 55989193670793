import { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
} from '@mui/material';
import { ModelSettingsSection } from 'components/atoms';
import { useModelConfig } from 'use/model-config';
import { Model, ModelOptions } from '@twins/types';
import { AIModels, AIProvider, AIType, MODEL_PROVIDERS } from 'types/enums';
import { useApp } from 'use/app';
import { useSnackbar } from 'use/snackbar';
import { AddVoice } from '../add-voice';
import { useTwinsGetVoiceQuery } from 'services/voice.services.ts/voice';
import {
  AggregationSettings,
  FormSection,
  SliderSetting,
  VoiceModelSelect,
  VoiceProviderSelect,
  VoiceSelect,
  VoiceSettings,
} from './local';

export function ModelVoice() {
  const { setAddVoice, addVoice } = useApp();
  const { models, setModels, changed, setChanged } = useModelConfig();
  const { showSnackbar } = useSnackbar();
  const [ttsModel, setTtsModel] = useState<Model>();

  const availableProviders = Object.keys(
    MODEL_PROVIDERS[AIType.TTS] || {},
  ) as AIProvider[];
  const selectedProvider = (ttsModel?.provider ||
    AIProvider.PLAYHT) as keyof (typeof MODEL_PROVIDERS)[typeof AIType.TTS];
  const availableModels = MODEL_PROVIDERS[AIType.TTS]?.[selectedProvider] || [];
  const selectedModel = (ttsModel?.model ||
    AIModels.PLAY_HT_2_TURBO) as AIModels;

  const {
    data,
    isLoading: isLoadingVoices,
    refetch,
  } = useTwinsGetVoiceQuery({
    input: {
      provider: selectedProvider,
    },
  });

  const changeStatus = useCallback(
    (value: boolean): void => {
      if (!changed) {
        setChanged(value);
      }
    },
    [changed, setChanged],
  );

  const updateModel = useCallback(
    (updatedModel: Partial<Model>): void => {
      if (ttsModel) {
        const newModel: Model = {
          ...ttsModel,
          ...updatedModel,
          options: {
            ...ttsModel.options,
            ...(updatedModel.options || {}),
          },
        };
        const updatedModels = models.map((model) =>
          model.type === AIType.TTS ? newModel : model,
        );
        setModels(updatedModels);
        setTtsModel(newModel);
        changeStatus(true);
      }
    },
    [ttsModel, models, setModels, changeStatus],
  );

  useEffect(() => {
    switch (selectedProvider) {
      case AIProvider.ELEVENLABS:
        if (!availableModels.includes(selectedModel)) {
          updateModel({ model: AIModels.ELEVEN_TURBO_V2 });
        }
        break;
      case AIProvider.PLAYHT:
        if (!availableModels.includes(selectedModel)) {
          updateModel({ model: AIModels.PLAY_HT_2_TURBO });
        }
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProvider]);

  useEffect(() => {
    if (ttsModel?.options && typeof ttsModel.options === 'string') {
      try {
        const parsedOptions = ttsModel.options as ModelOptions;
        setTtsModel({
          ...ttsModel,
          options: parsedOptions,
        });
      } catch (error) {
        showSnackbar(
          `Failed to parse model options: ${error as string}`,
          'error',
        );
      }
    }
  }, [showSnackbar, ttsModel]);

  useEffect(() => {
    const ttsModelIndex = models.findIndex((m) => m.type === AIType.TTS);
    setTtsModel(models[ttsModelIndex]);
  }, [models]);

  const isModelOptions = (options: unknown): options is ModelOptions => {
    return (
      typeof options === 'object' &&
      options !== null &&
      'stability' in options &&
      typeof (options as Record<string, unknown>).stability === 'number'
    );
  };

  const handleChange = (
    field: keyof Model | keyof ModelOptions,
    value: unknown,
  ): void => {
    if (ttsModel) {
      if (field in ttsModel) {
        updateModel({ [field]: value } as Partial<Model>);
      } else if (
        isModelOptions(ttsModel.options) &&
        field in ttsModel.options
      ) {
        updateModel({ options: { [field]: value } } as Partial<Model>);
      }
      changeStatus(true);
    }
  };

  const TTS_SETTINGS: SliderSetting[] = [
    {
      label: 'Stability',
      field: 'stability',
      max: 1,
      step: 0.1,
      value: ttsModel?.options?.stability || 0,
    },
    {
      label: 'Clarity + Similarity',
      field: 'clarity',
      max: 1,
      step: 0.1,
      value: ttsModel?.options?.clarity || 0,
    },
    {
      label: 'Style Exaggeration',
      field: 'exaggeration',
      max: 1,
      step: 0.1,
      value: ttsModel?.options?.exaggeration || 0,
    },
    {
      label: 'Optimize Streaming Latency',
      field: 'streamingLatency',
      max: 4,
      step: 1,
      value: ttsModel?.options?.streamingLatency || 0,
    },
    {
      label: 'Audio Speed',
      field: 'speed',
      min: 0.8,
      max: 1.2,
      step: 0.01,
      value: ttsModel?.options?.speed || 1.0,
    },
  ];

  return (
    <Box>
      <ModelSettingsSection
        title="Voice Configuration"
        subtitle="Choose from the list of voices, or add a new voice by clicking the plus button."
      >
        <AddVoice
          open={addVoice}
          setOpen={setAddVoice}
          refetchVoices={refetch}
        />

        <FormSection>
          <VoiceProviderSelect
            options={availableProviders}
            value={selectedProvider}
            onChange={handleChange}
          />
          <VoiceSelect
            voices={data?.twinsGetVoice?.data || []}
            value={ttsModel?.voiceID}
            onChange={handleChange}
            onAddVoice={() => setAddVoice(true)}
            loadingVoices={isLoadingVoices}
          />
          <VoiceModelSelect
            models={availableModels}
            value={selectedModel}
            onChange={handleChange}
          />
        </FormSection>
      </ModelSettingsSection>
      <ModelSettingsSection
        title="Additional Configuration"
        subtitle="Configure additional settings for the voice of your assistant."
      >
        <FormSection>
          <VoiceSettings settings={TTS_SETTINGS} onChange={handleChange} />
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={ttsModel?.options?.useSpeakerBoost || false}
                  onChange={(e) =>
                    handleChange('useSpeakerBoost', e.target.checked)
                  }
                />
              }
              label="Use Speaker Boost"
            />
          </Grid>
        </FormSection>
      </ModelSettingsSection>
      <ModelSettingsSection
        title="Aggregation Settings"
        subtitle="As the LLM streams output, aggregate it according to these settings."
      >
        <FormSection>
          <Grid item xs={12}>
            <Stack sx={{ pt: 1, pr: 2 }} direction="column" spacing={2}>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={ttsModel?.options?.aggregatorOn || false}
                    onChange={(e) =>
                      handleChange('aggregatorOn', e.target.checked)
                    }
                  />
                }
                label="Turn Aggregator On"
              />
            </Stack>
          </Grid>
          <AggregationSettings ttsModel={ttsModel} onChange={handleChange} />
        </FormSection>
      </ModelSettingsSection>
    </Box>
  );
}
