import {
  ModelHome,
  ModelTranscriber,
  ModelVoice,
} from 'components/molecules';
import { JSXElementConstructor, ReactElement } from 'react';
import { ModelTabView } from 'types/app';

interface TabDefinition {
  key: number;
  value: ModelTabView;
  label: string;
  component: React.ReactNode;
  icon?:
    | string
    | ReactElement<unknown, string | JSXElementConstructor<unknown>>
    | undefined;
  disabled?: boolean;
}

export const tabs: TabDefinition[] = [
  {
    key: 0,
    value: 'model',
    label: 'Model',
    component: <ModelHome />,
  },
  {
    key: 1,
    value: 'voice',
    label: 'Voice',
    component: <ModelVoice />,
  },
  {
    key: 2,
    value: 'transcriber',
    label: 'Transcriber',
    component: <ModelTranscriber />,
  },
  // {
  //   key: 3,
  //   value: 'memory',
  //   label: 'Memory',
  //   component: <ModelMemory />,
  //   disabled: true,
  // },
  // {
  //   key: 4,
  //   value: 'functions',
  //   label: 'Functions',
  //   component: <ModelFunctions />,
  //   disabled: true,
  // },
  // {
  //   key: 5,
  //   value: 'template',
  //   label: 'Template',
  //   component: <ModelTemplate />,
  //   disabled: true,
  // },
];
