import { FormLabel, Stack } from '@mui/material';

interface SectionProps {
  children: React.ReactNode;
  label?: string;
}

export function Section({ children, label }: SectionProps) {
  return (
    <Stack
      direction="column"
      spacing={1}
    >
      {label && <FormLabel>{label}</FormLabel>}
      {children}
    </Stack>
  );
}
