import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { Collection as CollectionType, File } from '@twins/types';
import { executeGraphqlOperation } from 'api';
import { GraphQLResult } from 'aws-amplify/api';
import { formatDate, formatFileSize } from 'common/utils';
import { useSnackbar } from 'use/snackbar';
import { twinsGetFileQuery } from 'graphql/queries';
import { memo, useCallback, useEffect, useState } from 'react';
import { useUser } from 'use/user';
import { useNavigate } from 'react-router-dom';
import { useSuperfeelUser } from 'use/superfeel-user';
import { ButtonIconTooltip } from 'components/atoms';
import { FileMedallion } from 'types/enums';

interface CollectionProps {
  collection: CollectionType;
}

function Collection({ collection }: CollectionProps) {
  const [files, setFiles] = useState<File[]>([]);
  const { showSnackbar } = useSnackbar();
  const { getJWT } = useUser();
  const navigate = useNavigate();
  const { superfeelUser } = useSuperfeelUser();

  const getFilesForCollection = useCallback(async () => {
    try {
      if (!collection?.id) return;
      const input = {
        collectionID: collection?.id,
        medallion: FileMedallion.bronze,
      };
      const jwt = await getJWT();
      const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
        twinsGetFileQuery,
        { input },
        jwt,
      );
      console.log('Data:', data);
      if (data) {
        const result = data as {
          twinsGetFile: {
            success: boolean;
            message: string;
            data: File[];
          };
        };
        if (
          result.twinsGetFile.success &&
          result.twinsGetFile.data.length > 0
        ) {
          const bronzeFiles = result.twinsGetFile.data.filter(
            (file) => file.medallion === FileMedallion.bronze,
          );
          setFiles(bronzeFiles);
        }
      }
    } catch (e) {
      showSnackbar(`Error getting collection: ${e}`, 'error');
    }
  }, [collection?.id, getJWT, showSnackbar]);

  useEffect(() => {
    getFilesForCollection();
  }, [collection, getFilesForCollection]);

  const handleViewCollection = async () => {
    navigate(
      `/user/${superfeelUser?.username}/data/collection/${collection.id}`,
    );
  };

  return (
    <Box
      key={collection.id}
      mb={4}
    >
      <Card variant="outlined">
        <CardHeader
          title={<Typography variant="h4">{collection.name}</Typography>}
          subheader={
            collection.interviewer && `Interviewer: ${collection.interviewer}`
          }
          action={
            <ButtonIconTooltip
              isLoading={false}
              onClick={handleViewCollection}
              tooltipTitle="View Collection"
              icon="go"
              tooltipColorVariant="info"
            />
          }
        />
        <CardContent>
          <Box>
            <Grid
              container
              spacing={2}
            >
              {files?.map((file) => (
                <Grid
                  item
                  xs={12}
                  md={4}
                  key={file.id}
                >
                  <Card>
                    <CardHeader
                      title={file.name}
                      subheader={file.type}
                    />
                    <CardContent>
                      <Typography variant="body1">
                        {formatFileSize(file.size || 0)}
                      </Typography>
                      <Typography variant="body1">{file.status}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box mt={4}>
            <Stack
              direction="row"
              spacing={4}
            >
              <Box>
                <Typography
                  variant="caption"
                  sx={{
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                  }}
                >
                  Created at:
                </Typography>
                <Typography variant="body2">
                  {formatDate(collection.createdAt)}
                </Typography>
              </Box>
            </Stack>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default memo(Collection);
