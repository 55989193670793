import { SuperfeelPost } from '@twins/types';
import { createContext, useContext, useState, ReactNode, useMemo } from 'react';

interface PostContextState {
  posts: SuperfeelPost[] | null;
  setPosts: (posts: SuperfeelPost[] | null) => void;
  loadingPosts: boolean;
  setLoadingPosts: (loadingPosts: boolean) => void;
}

const PostContext = createContext<PostContextState | undefined>(undefined);

export const usePostContext = () => {
  const context = useContext(PostContext);
  if (!context) {
    throw new Error('usePostContext must be used within a PostContextProvider');
  }
  return context;
};

interface PostContextProviderProps {
  children: ReactNode;
}

export function PostContextProvider({
  children,
}: PostContextProviderProps): JSX.Element {
  const [loadingPosts, setLoadingPosts] = useState(false);
  const [posts, setPosts] = useState<SuperfeelPost[] | null>(null);

  const contextValue = useMemo(
    () => ({
      loadingPosts,
      setLoadingPosts,
      posts,
      setPosts,
    }),
    [loadingPosts, setLoadingPosts, posts, setPosts],
  );

  return (
    <PostContext.Provider value={contextValue}>{children}</PostContext.Provider>
  );
}
