import { LoadingButton } from '@mui/lab';
import {
  AppBar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { executeGraphqlOperation } from 'api';
import { GraphQLResult } from 'aws-amplify/api';
import { useSnackbar } from 'use/snackbar';
import { useState } from 'react';
import {
  twinsUpdateMemoryMutation,
  twinsUpdateModelConfigMutation,
  twinsUpdateModelMutation,
  twinsUpdatePromptMutation,
} from 'graphql/mutations';
import { useSuperfeelUser } from 'use/superfeel-user';
import { useDefaultTemplate } from 'components/templates';
import { useUser } from 'use/user';
import { ButtonIconTooltip } from 'components/atoms';

interface NewModelConfigProps {
  title: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  onUpdate: () => void;
}

export function NewModelConfig({
  title,
  open,
  setOpen,
  onUpdate,
}: NewModelConfigProps) {
  const [saving, setSaving] = useState(false);
  const [twinName, setTwinName] = useState<string>('');
  const [twinDescription, setTwinDescription] = useState<string>('');
  const { showSnackbar } = useSnackbar();
  const { superfeelUser } = useSuperfeelUser();
  const { modelConfigID, template } = useDefaultTemplate();
  const handleToggleOpen = () => {
    setOpen(!open);
  };
  const { getJWT } = useUser();

  const createModelConfig = async () => {
    try {
      setSaving(true);

      const jwt = await getJWT();

      const graphqlOperations = [
        { mutation: twinsUpdatePromptMutation, input: template.startPrompt },
        { mutation: twinsUpdatePromptMutation, input: template.mainPrompt },
        { mutation: twinsUpdateModelMutation, input: template.llm },
        { mutation: twinsUpdateModelMutation, input: template.asr },
        { mutation: twinsUpdateModelMutation, input: template.tts },
        { mutation: twinsUpdateMemoryMutation, input: template.memory },
      ];

      await Promise.all(
        graphqlOperations.map(({ mutation, input }) =>
          executeGraphqlOperation(mutation, { input }, jwt),
        ),
      );

      const modelConfigInput = {
        id: modelConfigID,
        name: twinName,
        description: twinDescription,
        active: true,
        username: superfeelUser?.username,
        userID: superfeelUser?.userId,
      };

      const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
        twinsUpdateModelConfigMutation,
        { input: modelConfigInput },
        jwt,
      );

      if (data) {
        const modelResponse = data as {
          twinsUpdateModelConfig: {
            success: boolean;
            message: string;
          };
        };

        if (modelResponse.twinsUpdateModelConfig.success) {
          showSnackbar('Created twin', 'success');
          onUpdate();
          setOpen(false);
        } else {
          showSnackbar('Failed to create twin', 'error');
        }
      } else {
        showSnackbar('Failed to create twin', 'error');
      }
    } catch (e) {
      showSnackbar(`Failed to create twin: ${e as string}`, 'error');
    } finally {
      setSaving(false);
    }
  };

  const handleClick = () => {
    createModelConfig();
  };

  const disable = () => {
    if (!twinName || !twinDescription) {
      return true;
    }
    return false;
  };

  return (
    <Box>
      <Dialog
        maxWidth="xs"
        fullWidth
        onClose={handleToggleOpen}
        open={open}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h4"
              component="div"
            >
              {title || 'New Model'}
            </Typography>

            <ButtonIconTooltip
              onClick={() => setOpen(false)}
              isLoading={false}
              tooltipTitle="Close"
              icon="close"
              tooltipColorVariant="info"
            />
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              size="small"
              fullWidth
              label="Twin Name"
              variant="outlined"
              placeholder="Twin name"
              value={twinName}
              onChange={(e) => {
                setTwinName(e.target.value);
              }}
            />
            <TextField
              size="small"
              fullWidth
              label="Twin Description"
              variant="outlined"
              placeholder="Twin Description"
              value={twinDescription}
              onChange={(e) => {
                setTwinDescription(e.target.value);
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <LoadingButton
            disabled={disable()}
            loading={saving}
            onClick={handleClick}
            variant="outlined"
            sx={{ fontWeight: 'bold' }}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
