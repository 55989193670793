import { useVoiceAPI } from './api';
import { VoiceContextProvider, useVoiceContext } from './state';

export const useVoice = () => {
  const context = useVoiceContext();
  const api = useVoiceAPI();

  return {
    ...context,
    ...api,
  };
};

export { VoiceContextProvider };
