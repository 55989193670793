// pkg/frontend/app/src/components/molecules/voice-test/index.tsx
import { Box, Divider, Stack, TextField } from '@mui/material';
import {
  VoiceSample,
  SectionTitle,
  SubSectionTitle,
  Caption,
  VoiceSampleActionButtons,
  VoiceTestActionButtons,
} from 'components/atoms';
import { useFile } from 'use/file';
import { usePlayHT } from 'use/playht';
import { useVoice } from 'use/voice';

export default function VoiceTest() {
  const { file } = useFile();
  const { voice } = useVoice();
  const { sampleText, setSampleText } = usePlayHT();
  console.log('file:', file);
  return (
    <Box mb={10}>
      <SectionTitle>Ready to Use</SectionTitle>
      <SubSectionTitle>Generate Sample</SubSectionTitle>
      <Box mb={4}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Caption>Sample Text</Caption>
          <VoiceTestActionButtons />
        </Stack>
        <Box my={1}>
          <TextField
            value={sampleText || ''}
            onChange={(e) => setSampleText(e.target.value)}
            fullWidth
            multiline
            minRows={2}
            maxRows={5}
            variant="outlined"
          />
        </Box>
      </Box>
      <Divider sx={{ opacity: 0.2 }} />
      {file && file?.key && voice?.sampleID && file?.id === voice?.sampleID && (
        <Box>
          <SubSectionTitle>Samples</SubSectionTitle>
          <VoiceSample
            action={<VoiceSampleActionButtons file={file} />}
            file={file}
            sample={{
              speaker: '1',
              transcriptURI: '',
              audioURI: file?.key,
            }}
          />
        </Box>
      )}
    </Box>
  );
}
