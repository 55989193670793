import { usePostAPI } from './api';
import { usePostContext, PostContextProvider } from './state';

export const usePost = () => {
  const context = usePostContext();
  const api = usePostAPI();

  return {
    ...context,
    ...api,
  };
};

export { PostContextProvider };
