import React from 'react';
import {
  FormControlLabel,
  Checkbox,
  FormControlLabelProps,
} from '@mui/material';

interface CheckboxWithLabelProps
  extends Omit<FormControlLabelProps, 'control'> {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxWithLabel: React.FC<CheckboxWithLabelProps> = ({
  checked,
  onChange,
  ...props
}) => (
  <FormControlLabel
    control={
      <Checkbox
        checked={checked}
        onChange={onChange}
      />
    }
    {...props}
  />
);

export default CheckboxWithLabel;
