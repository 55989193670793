import { ReactNode, useMemo } from 'react';
import {
  Box,
  Typography,
  Checkbox,
  Chip
} from '@mui/material';
import { PreviewCard } from 'components/atoms';
import { styled } from '@mui/material/styles';
import {
  RadioButtonCheckedRounded,
  RadioButtonUncheckedRounded,
} from '@mui/icons-material';
import { AudienceType } from '@twins/types';

interface GraphContentCardProps {
  id: string;
  title: string;
  content: string | ReactNode;
  actions: ReactNode;
  selected: boolean;
  disabled: boolean;
  audience?: AudienceType;
  onToggleSelect: () => void;
}

const WhiteCheckbox = styled(Checkbox)({
  color: 'white',
  '&.Mui-checked': { color: 'white' },
});

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: '#333',
  color: 'white',
  fontSize: '0.75rem',
  height: '24px',
}));

export function GraphContentCard({
  id,
  title,
  content,
  actions,
  selected,
  onToggleSelect,
  disabled,
  audience,
}: GraphContentCardProps) {
  const Header = useMemo(
    () => (
      <Box
        sx={{
          p: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          gap={2}
        >
          <Typography
            variant="h5"
            color="white"
            fontWeight={600}
          >
            {title}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          gap={2}
        >
          {audience && (
            <StyledChip
              label={audience}
              size="small"
            />
          )}
          <WhiteCheckbox
            disabled={disabled}
            icon={<RadioButtonUncheckedRounded />}
            checkedIcon={<RadioButtonCheckedRounded />}
            checked={selected}
            onChange={onToggleSelect}
            inputProps={{ 'aria-label': `select-${id}` }}
          />
        </Box>
      </Box>
    ),
    [title, audience, disabled, selected, onToggleSelect, id]
  );

  return (
    <PreviewCard
      header={Header}
      content={content}
      actions={actions}
    />
  );
}