/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Select as SelectMUI } from '@mui/material';
import { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';

type OptionType = {
  value: string;
  label: string;
};

type CustomSelectProps = Omit<SelectProps<string>, 'onChange'> & {
  options: OptionType[];
  label?: string;
  value?: string;
  defaultValue?: string;
  onChange: (value: string) => void;
};

const Select = forwardRef<HTMLButtonElement, CustomSelectProps>(
  (props, ref) => {
    const { options, label, value, defaultValue, onChange } = props;
    const [selectedValue, setSelectedValue] = useState(defaultValue || '');

    const handleChange = useCallback(
      (event: SelectChangeEvent<string>) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
        onChange(newValue);
      },
      [onChange],
    );

    return (
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          {label && <InputLabel id="custom-select-label">{label}</InputLabel>}
          <SelectMUI
            size="small"
            labelId="custom-select-label"
            value={value || selectedValue}
            label={label}
            onChange={handleChange}
            IconComponent={UnfoldMoreRoundedIcon}
            ref={ref}
          >
            {options.map((option: OptionType) => (
              <MenuItem
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </SelectMUI>
        </FormControl>
      </Box>
    );
  },
);

export default Select;
