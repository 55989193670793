import { api as generatedApi } from 'services/generated';

export const jobsApi = generatedApi.enhanceEndpoints({
  addTagTypes: ['Jobs', 'Files'],
  endpoints: {
    TwinsGetIngestionData: {
      providesTags: ['Jobs'],
    },
    TwinsCreateIngestionJobs: {
      invalidatesTags: ['Jobs'],
    },
  },
});

export const {
  useTwinsGetIngestionDataQuery,
  useTwinsCreateIngestionJobsMutation,
} = jobsApi;
