import { Box, CircularProgress } from '@mui/material';

interface LoadingProps {
  minHeight?: string;
}
export default function Loading({ minHeight }: LoadingProps) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight={minHeight || '75vh'}
      width="100%"
      height="100%"
    >
      <CircularProgress />
    </Box>
  );
}
