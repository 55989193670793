import {
  Animation,
  BlurCircular,
  FiberManualRecord,
  HdrWeak,
  Interests,
  SurroundSound,
  Workspaces,
} from '@mui/icons-material';
import { SideBarItemProps } from 'types/app';

export const getUserSideBarItems = (username: string): SideBarItemProps[] => [
  {
    title: username,
    icon: <FiberManualRecord />,
    path: (username) => `/user/${username}`,
  },
  {
    title: 'Data',
    icon: <Interests />,
    path: (username) => `/user/${username}/data`,
  },
  {
    title: 'Twin',
    icon: <HdrWeak />,
    path: (username) => `/user/${username}/twin`,
  },
  {
    title: 'Voice',
    icon: <SurroundSound />,
    path: (username) => `/user/${username}/voice`,
  },
  {
    title: 'SMM',
    icon: <BlurCircular />,
    path: (username) => `/user/${username}/smm`,
  },
  {
    title: 'Logs',
    icon: <Animation />,
    path: (username) => `/user/${username}/log`,
  },
];

export const MainSideBarItems: SideBarItemProps[] = [
  { title: 'Users', icon: <Workspaces />, path: () => '/' },
];
