import React, {
  ReactNode,
  SyntheticEvent,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { Box, Tabs, Tab, Button, Tooltip } from '@mui/material';
import Layout from 'layouts';
import { TabPanel } from 'components/atoms';
import { Add } from '@mui/icons-material';
import { ChangePassword, TeamSettings, AddUser } from './components';

type TabLabel = 'Account' | 'Team' | 'Invite';

interface TabProps {
  component: ReactNode;
  label: TabLabel;
  disabled?: boolean;
  tooltipTitle?: string;
}

export function Settings() {
  const [value, setValue] = useState<number>(2);
  const [addUser, setAddUser] = useState<boolean>(false);

  const handleChange = useCallback(
    (event: SyntheticEvent, newValue: number) => {
      setValue(newValue);
    },
    [],
  );

  const handleAddUser = useCallback(() => {
    setAddUser(true);
  }, []);

  const tabs: TabProps[] = useMemo(
    () => [
      {
        component: <ChangePassword />,
        label: 'Account',
      },
      {
        component: <TeamSettings />,
        label: 'Team',
        disabled: true,
        tooltipTitle: 'Ask Oseh',
      },
    ],
    [],
  );

  const renderAppBarAction = useMemo(() => {
    if (value === 1) {
      return (
        <Button
          startIcon={<Add />}
          variant="contained"
          sx={{ fontWeight: 'bold' }}
          onClick={handleAddUser}
          aria-label="Add user"
        >
          Add
        </Button>
      );
    }
    return null;
  }, [value, handleAddUser]);

  return (
    <Layout appBarAction={renderAppBarAction}>
      <AddUser
        addUser={addUser}
        setAddUser={setAddUser}
      />
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="settings tabs"
        >
          {tabs.map((tab, index) => {
            const tabElement = (
              <Tab
                key={tab.label}
                label={tab.label}
                disabled={tab.disabled}
                aria-label={`${tab.label} tab`}
                id={`settings-tab-${index}`}
                aria-controls={`settings-tabpanel-${index}`}
              />
            );
            return tab.label === 'Team' ? (
              <Tooltip
                key={tab.label}
                placement="top"
                title="Ask Oseh"
                arrow
              >
                <span>{tabElement}</span>
              </Tooltip>
            ) : (
              tabElement
            );
          })}
        </Tabs>
        {tabs.map((tab, index) => (
          <TabPanel
            key={tab.label}
            value={value}
            index={index}
          >
            {tab.component}
          </TabPanel>
        ))}
      </Box>
    </Layout>
  );
}

export default React.memo(Settings);
