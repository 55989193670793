import React, { useState } from 'react';
import { Stack } from '@mui/material';
import ButtonIconTooltip from '../button-icon-tooltip';
import { File } from '@twins/types';
import { useMedia } from 'use/media';

interface VoiceSampleActionButtonsProps {
  file: File;
}

const VoiceSampleActionButtons: React.FC<VoiceSampleActionButtonsProps> = ({
  file,
}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const { getCloudfrontUrl } = useMedia();

  const handleDownload = async () => {
    if (file && file.key) {
      try {
        setIsDownloading(true);
        const key = file?.key;
        const result = await getCloudfrontUrl(key);
        if (!result) {
          throw new Error('Failed to get signed URL');
        }
        const signedUrl = result;
        const response = await fetch(signedUrl);
        const blob = await response.blob();
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `${file.name}` || 'sample.mp3';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error('Error downloading file:', error);
      } finally {
        setIsDownloading(false);
      }
    }
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
    >
      <ButtonIconTooltip
        isDisabled
        isLoading={false}
        tooltipTitle="Copy Preview Link URL"
        icon="copy"
        onClick={() => {}}
      />
      <ButtonIconTooltip
        isDisabled
        isLoading={false}
        tooltipTitle="Open Preview Link"
        icon="launch"
        onClick={() => {}}
      />
      <ButtonIconTooltip
        isLoading={isDownloading}
        tooltipTitle="Download Sample"
        icon="download"
        tooltipColorVariant="info"
        onClick={handleDownload}
      />
    </Stack>
  );
};

export default VoiceSampleActionButtons;
