import { baseApiWithGraphQL } from './baseApi';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AWSDate: { input: string; output: string; }
  AWSDateTime: { input: string; output: string; }
  AWSJSON: { input: any; output: any; }
};

export type AiLanguages =
  | 'ar'
  | 'bn'
  | 'de'
  | 'el'
  | 'en'
  | 'es'
  | 'fr'
  | 'he'
  | 'hi'
  | 'it'
  | 'ja'
  | 'ko'
  | 'nl'
  | 'pt'
  | 'ru'
  | 'sv'
  | 'tr'
  | 'ur'
  | 'vi'
  | 'zh';

export type AiModels =
  | 'eleven_monolingual_v1'
  | 'eleven_multilingual_v1'
  | 'eleven_multilingual_v2'
  | 'eleven_turbo_v2'
  | 'nova_2'
  | 'nova_2_automotive'
  | 'nova_2_conversationalai'
  | 'nova_2_drivethru'
  | 'nova_2_financial'
  | 'nova_2_general'
  | 'nova_2_legal'
  | 'nova_2_medical'
  | 'nova_2_meeting'
  | 'nova_2_phonecall'
  | 'nova_2_video'
  | 'play_ht_1'
  | 'play_ht_2'
  | 'play_ht_2_turbo';

export type AiProvider =
  | 'anthropic'
  | 'deepgram'
  | 'elevenlabs'
  | 'openai'
  | 'playht'
  | 'unknown';

export type AiType =
  | 'asr'
  | 'llm'
  | 'tts';

export type AdminEmailRole =
  | 'IDEABANK';

export type AdminObjectType =
  | 'INTERVIEW';

export type AppPublisher =
  | 'APPLE'
  | 'GOOGLE';

export type AppReleaseHistory = {
  __typename?: 'AppReleaseHistory';
  id?: Maybe<Scalars['Int']['output']>;
  publicationDate: Scalars['AWSDateTime']['output'];
  stage: AppStage;
  store: AppPublisher;
  url?: Maybe<Scalars['String']['output']>;
  version: Scalars['String']['output'];
};

export type AppStage =
  | 'DEV'
  | 'PROD';

export type AudienceType =
  | 'CIRCLE'
  | 'FRIENDS'
  | 'PRIVATE'
  | 'PUBLIC';

export type BackgroundMusicInput = {
  songId: Scalars['String']['input'];
  startTimestamp: Scalars['Float']['input'];
  volume: Scalars['Float']['input'];
};

export type Chunk = {
  __typename?: 'Chunk';
  collectionName?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  documentID?: Maybe<Scalars['ID']['output']>;
  embedding?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  modelConfigID?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type Collection = {
  __typename?: 'Collection';
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  interviewer?: Maybe<Scalars['String']['output']>;
  jobID?: Maybe<Scalars['ID']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<CollectionType>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  userID?: Maybe<Scalars['ID']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type CollectionType =
  | 'interview'
  | 'memory'
  | 'tts';

export type CompleteMultipartUploadInput = {
  bucketName: Scalars['String']['input'];
  key: Scalars['String']['input'];
  parts: Array<PartInput>;
  uploadId: Scalars['String']['input'];
};

export type CompleteMultipartUploadResponse = {
  __typename?: 'CompleteMultipartUploadResponse';
  bucket: Scalars['String']['output'];
  eTag: Scalars['String']['output'];
  key: Scalars['String']['output'];
  location: Scalars['String']['output'];
};

export type ConnectionProperties = {
  status: ConnectionStatus;
  targetUserId?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Deprecated in v2.0. Use targetUserId instead */
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type ConnectionStatus =
  | 'CIRCLE'
  | 'CONNECTED'
  | 'FOLLOWED_BY'
  | 'FOLLOWS'
  | 'HIDDEN'
  | 'NONE';

export type DataSource = {
  __typename?: 'DataSource';
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  currentVersion?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  modelConfigID?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  userID?: Maybe<Scalars['ID']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type DeleteAccountReason =
  | 'DATA_CONCERNS'
  | 'DIFFICULT_USING'
  | 'NEED_A_BREAK'
  | 'NO_FRIENDS'
  | 'OTHER'
  | 'PRIVACY_CONCERNS'
  | 'SECOND_ACCOUNT'
  | 'TOO_BUSY';

export type DeleteItemInput = {
  /** @deprecated Deprecated in v2.0. Use targetId instead */
  itemId?: InputMaybe<Scalars['Int']['input']>;
  targetId?: InputMaybe<Scalars['String']['input']>;
  type: ItemType;
};

export type DeleteUserOptions = {
  isPermanent: Scalars['Boolean']['input'];
  reason: DeleteAccountReason;
};

export type Document = {
  __typename?: 'Document';
  category?: Maybe<DocumentCategory>;
  chunkSize?: Maybe<Scalars['Int']['output']>;
  collectionName?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  fileExtension?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  fileType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  link?: Maybe<Scalars['String']['output']>;
  modelConfigID?: Maybe<Scalars['ID']['output']>;
  overlapPercentage?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  userID?: Maybe<Scalars['ID']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type DocumentCategory =
  | 'link'
  | 'text'
  | 'upload';

export type FeedType =
  | 'CIRCLE'
  | 'DEMO'
  | 'FRIENDS'
  | 'HIDDEN'
  | 'PERSONAL'
  | 'PUBLIC'
  | 'SAVED';

export type Feedback = {
  __typename?: 'Feedback';
  accuracy?: Maybe<Scalars['Int']['output']>;
  audio?: Maybe<Scalars['Int']['output']>;
  chatHistoryID?: Maybe<Scalars['ID']['output']>;
  coherent?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  dataSourceID?: Maybe<Scalars['ID']['output']>;
  evaluationID?: Maybe<Scalars['ID']['output']>;
  expectations?: Maybe<Scalars['Int']['output']>;
  experience?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  modelConfigID?: Maybe<Scalars['ID']['output']>;
  modelVersionID?: Maybe<Scalars['ID']['output']>;
  overall?: Maybe<Scalars['Int']['output']>;
  promptVersionID?: Maybe<Scalars['ID']['output']>;
  representative?: Maybe<Scalars['Int']['output']>;
  safe?: Maybe<Scalars['Int']['output']>;
  sessionID?: Maybe<Scalars['ID']['output']>;
  submittedBy?: Maybe<Scalars['String']['output']>;
  transcription?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  userID?: Maybe<Scalars['ID']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type FeedbackType =
  | 'BUG'
  | 'FEATURE_REQUEST'
  | 'GENERAL';

export type FeelRequest = {
  __typename?: 'FeelRequest';
  dateCreated: Scalars['AWSDateTime']['output'];
  prompt?: Maybe<SuperfeelPrompt>;
  recipient?: Maybe<SuperfeelUser>;
  recipientUserId: Scalars['String']['output'];
  requestId: Scalars['String']['output'];
  responsePostId?: Maybe<Scalars['String']['output']>;
  sender?: Maybe<SuperfeelUser>;
  senderUserId: Scalars['String']['output'];
  status: RequestStatus;
  targetPromptId: Scalars['String']['output'];
  type: RequestType;
};

export type File = {
  __typename?: 'File';
  audience?: Maybe<AudienceType>;
  collectionID?: Maybe<Scalars['ID']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['Float']['output']>;
  extension?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  identity?: Maybe<Scalars['ID']['output']>;
  interviewer?: Maybe<Scalars['String']['output']>;
  jobID?: Maybe<Scalars['ID']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  length?: Maybe<Scalars['Float']['output']>;
  medallion?: Maybe<FileMedallion>;
  name?: Maybe<Scalars['String']['output']>;
  originID?: Maybe<Scalars['ID']['output']>;
  selected?: Maybe<Scalars['Boolean']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  source?: Maybe<FilesSource>;
  speakerID?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  speakers?: Maybe<Scalars['Int']['output']>;
  stage?: Maybe<FileStage>;
  startTime?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<FileStatus>;
  transcript?: Maybe<Scalars['String']['output']>;
  type?: Maybe<FileType>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  userID?: Maybe<Scalars['ID']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type FileMedallion =
  | 'bronze'
  | 'gold'
  | 'silver';

export type FileStage =
  | 'complete'
  | 'process'
  | 'review'
  | 'submit'
  | 'upload';

export type FileStatus =
  | 'complete'
  | 'failed'
  | 'pending'
  | 'processing'
  | 'submitted';

export type FileType =
  | 'aac'
  | 'avi'
  | 'doc'
  | 'docx'
  | 'flac'
  | 'm4a'
  | 'md'
  | 'mkv'
  | 'mov'
  | 'mp3'
  | 'mp4'
  | 'ogg'
  | 'pdf'
  | 'rtf'
  | 'txt'
  | 'unknown'
  | 'wav'
  | 'wmv';

export type FilesSource =
  | 'feel'
  | 'interview';

export type Gender =
  | 'FEMALE'
  | 'MALE'
  | 'OTHER'
  | 'UNKNOWN';

export type GenericResponse = {
  __typename?: 'GenericResponse';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type GetCurrentVersionInput = {
  stage?: InputMaybe<AppStage>;
  store?: InputMaybe<AppPublisher>;
};

export type GetSignedUrlInput = {
  expiry: Scalars['Int']['input'];
  headers?: InputMaybe<Scalars['AWSJSON']['input']>;
  key: Scalars['String']['input'];
  method: HttpMethod;
  type: ObjectType;
};

export type GetUserInput = {
  /** @deprecated Deprecated in v2.0. Use userId instead */
  id?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type HttpMethod =
  | 'GET'
  | 'POST'
  | 'PUT';

export type IngestionAsset = {
  __typename?: 'IngestionAsset';
  audience: AudienceType;
  content?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  source: FilesSource;
  status: JobStatus;
};

export type InvitationSortMethod =
  | 'DATE_CREATED'
  | 'FIRST_NAME';

export type InviteStatus =
  | 'ACCEPTED'
  | 'CANCELLED'
  | 'PENDING';

export type ItemType =
  | 'COMMENT'
  | 'GROUP'
  | 'POST';

export type Job = {
  __typename?: 'Job';
  action?: Maybe<JobAction>;
  batchJobId?: Maybe<Scalars['String']['output']>;
  collectionID?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  endedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  fileID?: Maybe<Scalars['ID']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  removeMultiSpeaker?: Maybe<Scalars['Boolean']['output']>;
  s3Bucket?: Maybe<Scalars['String']['output']>;
  s3Key?: Maybe<Scalars['String']['output']>;
  samples?: Maybe<Array<Maybe<SampleData>>>;
  stage?: Maybe<JobStage>;
  startedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  status?: Maybe<JobStatus>;
  step?: Maybe<VoiceStep>;
  transcriptLanguage?: Maybe<Scalars['String']['output']>;
  transcriptMaxChars?: Maybe<Scalars['Int']['output']>;
  transcriptMinChars?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<JobType>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  useFiles?: Maybe<Scalars['Boolean']['output']>;
  usePosts?: Maybe<Scalars['Boolean']['output']>;
  userID?: Maybe<Scalars['ID']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  voiceID?: Maybe<Scalars['ID']['output']>;
};

export type JobAction =
  | 'graph_ingestion_start'
  | 'interview_process_start'
  | 'tts_select_start'
  | 'tts_train_start';

export type JobLog = {
  __typename?: 'JobLog';
  action?: Maybe<JobAction>;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  id: Scalars['ID']['output'];
  jobID: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  stage?: Maybe<JobStage>;
  status?: Maybe<JobStatus>;
  type?: Maybe<JobType>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  userID: Scalars['ID']['output'];
};

export type JobPreview = {
  __typename?: 'JobPreview';
  posts?: Maybe<Array<Maybe<SuperfeelPost>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type JobStage =
  | 'capture'
  | 'ingest'
  | 'review'
  | 'train';

export type JobStatus =
  | 'complete'
  | 'failed'
  | 'pending'
  | 'running'
  | 'starting'
  | 'submitted'
  | 'unknown';

export type JobType =
  | 'graph'
  | 'interview'
  | 'tts';

export type Memory = {
  __typename?: 'Memory';
  active?: Maybe<Scalars['Boolean']['output']>;
  chunkSize?: Maybe<Scalars['Int']['output']>;
  collectionName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  id: Scalars['ID']['output'];
  ingestedFeels?: Maybe<Scalars['Boolean']['output']>;
  modelConfigID?: Maybe<Scalars['ID']['output']>;
  overlapPercentage?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<MemoryType>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  userID?: Maybe<Scalars['ID']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type MemoryType =
  | 'graph'
  | 'local_vector';

export type Model = {
  __typename?: 'Model';
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  currentVersion?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  language?: Maybe<AiLanguages>;
  model: Scalars['String']['output'];
  modelConfigID?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  options?: Maybe<ModelOptions>;
  provider?: Maybe<AiProvider>;
  role?: Maybe<Scalars['String']['output']>;
  type?: Maybe<AiType>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  userID?: Maybe<Scalars['ID']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  voiceID?: Maybe<Scalars['ID']['output']>;
};

export type ModelConfig = {
  __typename?: 'ModelConfig';
  active?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  userID?: Maybe<Scalars['ID']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type ModelOptions = {
  __typename?: 'ModelOptions';
  aggregatorCharacters?: Maybe<Scalars['Int']['output']>;
  aggregatorOn?: Maybe<Scalars['Boolean']['output']>;
  aggregatorTimer?: Maybe<Scalars['Int']['output']>;
  clarity?: Maybe<Scalars['Float']['output']>;
  exaggeration?: Maybe<Scalars['Float']['output']>;
  maxTokens?: Maybe<Scalars['Int']['output']>;
  speed?: Maybe<Scalars['Float']['output']>;
  stability?: Maybe<Scalars['Float']['output']>;
  streamingLatency?: Maybe<Scalars['Int']['output']>;
  temperature?: Maybe<Scalars['Float']['output']>;
  useSpeakerBoost?: Maybe<Scalars['Boolean']['output']>;
};

export type ModelOptionsInput = {
  aggregatorCharacters?: InputMaybe<Scalars['Int']['input']>;
  aggregatorOn?: InputMaybe<Scalars['Boolean']['input']>;
  aggregatorTimer?: InputMaybe<Scalars['Int']['input']>;
  clarity?: InputMaybe<Scalars['Float']['input']>;
  exaggeration?: InputMaybe<Scalars['Float']['input']>;
  maxTokens?: InputMaybe<Scalars['Int']['input']>;
  speed?: InputMaybe<Scalars['Float']['input']>;
  stability?: InputMaybe<Scalars['Float']['input']>;
  streamingLatency?: InputMaybe<Scalars['Int']['input']>;
  temperature?: InputMaybe<Scalars['Float']['input']>;
  useSpeakerBoost?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MultipartUpload = {
  partNumber?: InputMaybe<Scalars['Int']['input']>;
  uploadId?: InputMaybe<Scalars['String']['input']>;
};

export type MusicSortMethod =
  | 'CATEGORY_NAME'
  | 'DATE_CREATED'
  | 'SONG_NAME';

export type Mutation = {
  __typename?: 'Mutation';
  completeMultipartUpload: CompleteMultipartUploadResponse;
  deleteItem?: Maybe<GenericResponse>;
  postComment?: Maybe<PostComment>;
  publishNotificationUpdate?: Maybe<SuperfeelNotification>;
  publishRequestUpdate?: Maybe<FeelRequest>;
  sendInvitations?: Maybe<Array<UserInvitation>>;
  submitFeedback?: Maybe<GenericResponse>;
  submitReport?: Maybe<SuperfeelReport>;
  twinsCreateIngestionJobs?: Maybe<GenericResponse>;
  twinsDeleteCollection?: Maybe<GenericResponse>;
  twinsDeleteDocument?: Maybe<GenericResponse>;
  twinsDeleteFile?: Maybe<GenericResponse>;
  twinsDeleteModelConfig?: Maybe<GenericResponse>;
  twinsDeleteVoice?: Maybe<GenericResponse>;
  twinsGenerateSample?: Maybe<TwinsGenerateSampleResponse>;
  twinsParseDocument: TwinsParseDocumentResponse;
  twinsProgressVoice?: Maybe<GenericResponse>;
  twinsSetPostsLocalContext?: Maybe<GenericResponse>;
  twinsUpdateCollection?: Maybe<GenericResponse>;
  twinsUpdateDataSource?: Maybe<GenericResponse>;
  twinsUpdateDocument?: Maybe<GenericResponse>;
  twinsUpdateFeedback?: Maybe<GenericResponse>;
  twinsUpdateFile?: Maybe<GenericResponse>;
  twinsUpdateJob?: Maybe<GenericResponse>;
  twinsUpdateJobLog?: Maybe<GenericResponse>;
  twinsUpdateMemory?: Maybe<GenericResponse>;
  twinsUpdateModel?: Maybe<GenericResponse>;
  twinsUpdateModelConfig?: Maybe<GenericResponse>;
  twinsUpdatePrompt?: Maybe<GenericResponse>;
  twinsUpdateSpeakerID?: Maybe<GenericResponse>;
  twinsUpdateVoice?: Maybe<GenericResponse>;
  updateConnection?: Maybe<SuperfeelUser>;
  updateContent?: Maybe<SuperfeelPost>;
  updateFlags?: Maybe<GenericResponse>;
  updateRating?: Maybe<PostRating>;
  updateRequest?: Maybe<Array<FeelRequest>>;
  updateSettings?: Maybe<SuperfeelUser>;
  updateUser?: Maybe<SuperfeelUser>;
};


export type MutationCompleteMultipartUploadArgs = {
  input: CompleteMultipartUploadInput;
};


export type MutationDeleteItemArgs = {
  input: DeleteItemInput;
};


export type MutationPostCommentArgs = {
  input: PostCommentInput;
};


export type MutationPublishNotificationUpdateArgs = {
  actorUserId: Scalars['String']['input'];
  data: Scalars['AWSJSON']['input'];
  recipientUserId: Scalars['String']['input'];
};


export type MutationPublishRequestUpdateArgs = {
  data: Scalars['AWSJSON']['input'];
  recipientUserId: Scalars['String']['input'];
  senderUserId: Scalars['String']['input'];
};


export type MutationSendInvitationsArgs = {
  input: SendInvitationInput;
};


export type MutationSubmitFeedbackArgs = {
  input: SubmitFeedbackInput;
};


export type MutationSubmitReportArgs = {
  input: SubmitReportInput;
};


export type MutationTwinsCreateIngestionJobsArgs = {
  input: TwinsCreateIngestionJobsInput;
};


export type MutationTwinsDeleteCollectionArgs = {
  input: TwinsDeleteCollectionInput;
};


export type MutationTwinsDeleteDocumentArgs = {
  input: TwinsDeleteDocumentInput;
};


export type MutationTwinsDeleteFileArgs = {
  input: TwinsDeleteFileInput;
};


export type MutationTwinsDeleteModelConfigArgs = {
  input: TwinsDeleteModelConfigInput;
};


export type MutationTwinsDeleteVoiceArgs = {
  input: TwinsDeleteVoiceInput;
};


export type MutationTwinsGenerateSampleArgs = {
  input: TwinsGenerateSampleInput;
};


export type MutationTwinsParseDocumentArgs = {
  input: TwinsParseDocumentInput;
};


export type MutationTwinsProgressVoiceArgs = {
  input: TwinsProgressVoiceInput;
};


export type MutationTwinsSetPostsLocalContextArgs = {
  input: TwinsSetPostsLocalContextInput;
};


export type MutationTwinsUpdateCollectionArgs = {
  input: TwinsUpdateCollectionInput;
};


export type MutationTwinsUpdateDataSourceArgs = {
  input: TwinsUpdateDataSourceInput;
};


export type MutationTwinsUpdateDocumentArgs = {
  input: TwinsUpdateDocumentInput;
};


export type MutationTwinsUpdateFeedbackArgs = {
  input: TwinsUpdateFeedbackInput;
};


export type MutationTwinsUpdateFileArgs = {
  input: TwinsUpdateFileInput;
};


export type MutationTwinsUpdateJobArgs = {
  input: TwinsUpdateJobInput;
};


export type MutationTwinsUpdateJobLogArgs = {
  input: TwinsUpdateJobLogInput;
};


export type MutationTwinsUpdateMemoryArgs = {
  input: TwinsUpdateMemoryInput;
};


export type MutationTwinsUpdateModelArgs = {
  input: TwinsUpdateModelInput;
};


export type MutationTwinsUpdateModelConfigArgs = {
  input: TwinsUpdateModelConfigInput;
};


export type MutationTwinsUpdatePromptArgs = {
  input: TwinsUpdatePromptInput;
};


export type MutationTwinsUpdateSpeakerIdArgs = {
  input: TwinsUpdateSpeakerIdInput;
};


export type MutationTwinsUpdateVoiceArgs = {
  input: TwinsUpdateVoiceInput;
};


export type MutationUpdateConnectionArgs = {
  input: UpdateConnectionInput;
};


export type MutationUpdateContentArgs = {
  input: UpdateContentInput;
};


export type MutationUpdateFlagsArgs = {
  input: UpdateFlagsInput;
};


export type MutationUpdateRatingArgs = {
  input: UpdateRatingInput;
};


export type MutationUpdateRequestArgs = {
  input: UpdateRequestInput;
};


export type MutationUpdateSettingsArgs = {
  input: UpdateSettingsInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type NotificationActionStatus =
  | 'COMPLETE'
  | 'NONE'
  | 'PENDING';

export type NotificationDirection =
  | 'ALL'
  | 'RECEIVER'
  | 'SENDER';

export type NotificationSetting =
  | 'ALL'
  | 'CIRCLE'
  | 'CONNECTED'
  | 'FOLLOWING'
  | 'OFF';

export type NotificationSubjectType =
  | 'FEEL_REQUEST'
  | 'POST'
  | 'PROMPT'
  | 'USER';

export type NotificationType =
  | 'FEEL_REQUEST_RESPONSE'
  | 'FOTD_REMINDER'
  | 'MORNING_SUMMARY'
  | 'NEW_COMMENT'
  | 'NEW_CONNECTION'
  | 'NEW_FEEL_REQUEST'
  | 'NEW_FOLLOWER'
  | 'NEW_POST'
  | 'NEW_RATING'
  | 'NUDGE'
  | 'NUDGE_REQUEST';

export type NotificationsInput = {
  fotdReminder?: InputMaybe<Scalars['Boolean']['input']>;
  newComment?: InputMaybe<NotificationSetting>;
  newConnection?: InputMaybe<Scalars['Boolean']['input']>;
  newFeelRequest?: InputMaybe<Scalars['Boolean']['input']>;
  newFollower?: InputMaybe<Scalars['Boolean']['input']>;
  newPost?: InputMaybe<NotificationSetting>;
  newRating?: InputMaybe<NotificationSetting>;
  nudge?: InputMaybe<Scalars['Boolean']['input']>;
  nudgeRequest?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ObjectType =
  | 'CONTENT'
  | 'CONTENT_AUDIO'
  | 'FEEDBACK'
  | 'MUSIC'
  | 'PROFILE'
  | 'THUMBNAIL'
  | 'TRANSCRIPTION';

export type PartInput = {
  ETag: Scalars['String']['input'];
  PartNumber: Scalars['Int']['input'];
};

export type PostComment = {
  __typename?: 'PostComment';
  author?: Maybe<SuperfeelUser>;
  /** @deprecated Deprecated V2.0. Use authorUserId instead */
  authorId?: Maybe<Scalars['Int']['output']>;
  authorUserId: Scalars['String']['output'];
  body: Scalars['String']['output'];
  commentId: Scalars['String']['output'];
  dateCreated: Scalars['AWSDateTime']['output'];
  dateDeleted?: Maybe<Scalars['AWSDateTime']['output']>;
  /** @deprecated Deprecated in V2.0. Use commentId instead */
  id?: Maybe<Scalars['Int']['output']>;
  isFlagged?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Deprecated V2.0. Use targetPostId instead */
  postId?: Maybe<Scalars['Int']['output']>;
  targetPostId: Scalars['String']['output'];
};

export type PostCommentInput = {
  body: Scalars['String']['input'];
  /** @deprecated Deprecated in v2.0. Use targetPostId instead */
  postId?: InputMaybe<Scalars['Int']['input']>;
  targetPostId?: InputMaybe<Scalars['String']['input']>;
};

export type PostFiltersInput = {
  excludeHidden?: InputMaybe<Scalars['Boolean']['input']>;
  excludeSelf?: InputMaybe<Scalars['Boolean']['input']>;
  excludeUnsaved?: InputMaybe<Scalars['Boolean']['input']>;
  includeDemo?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PostFlags = {
  __typename?: 'PostFlags';
  hidden?: Maybe<Scalars['Boolean']['output']>;
  saved?: Maybe<Scalars['Boolean']['output']>;
};

export type PostFlagsInput = {
  hidden: Scalars['Boolean']['input'];
  saved: Scalars['Boolean']['input'];
};

export type PostInfoInput = {
  audience: AudienceType;
  caption: Scalars['String']['input'];
  customThumbnailUri?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Deprecated in v2.0. Use postId instead */
  id?: InputMaybe<Scalars['Int']['input']>;
  key: Scalars['String']['input'];
  music?: InputMaybe<BackgroundMusicInput>;
  postId?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Deprecated in v2.0. Use targetPromptId instead */
  promptId?: InputMaybe<Scalars['Int']['input']>;
  tag: PostTopic;
  targetPromptId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<PostType>;
  videoStartTimestamp?: InputMaybe<Scalars['Float']['input']>;
  videoStopTimestamp?: InputMaybe<Scalars['Float']['input']>;
  volume?: InputMaybe<Scalars['Float']['input']>;
};

export type PostMetadata = {
  __typename?: 'PostMetadata';
  bitRate?: Maybe<Scalars['Int']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  framesPerSecond?: Maybe<Scalars['Float']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  videoHeight?: Maybe<Scalars['Int']['output']>;
  videoWidth?: Maybe<Scalars['Int']['output']>;
};

export type PostRating = {
  __typename?: 'PostRating';
  author?: Maybe<SuperfeelUser>;
  /** @deprecated Deprecated V2.0. Use authorUserId instead */
  authorId?: Maybe<Scalars['Int']['output']>;
  authorUserId: Scalars['String']['output'];
  dateCreated: Scalars['AWSDateTime']['output'];
  dateDeleted?: Maybe<Scalars['AWSDateTime']['output']>;
  /** @deprecated Deprecated in V2.0. Use ratingId instead */
  id?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Deprecated V2.0. Use targetPostId instead */
  postId?: Maybe<Scalars['Int']['output']>;
  ratingId: Scalars['String']['output'];
  targetPostId: Scalars['String']['output'];
  type: RatingType;
};

export type PostSortMethod =
  | 'COMBINED_SCORE'
  | 'DATE'
  | 'FUNNY'
  | 'HELPFUL'
  | 'INSPIRING'
  | 'RELATABLE'
  | 'VIEWS';

export type PostStatistics = {
  __typename?: 'PostStatistics';
  comments: Scalars['Int']['output'];
  funny: Scalars['Int']['output'];
  helpful: Scalars['Int']['output'];
  inspiring: Scalars['Int']['output'];
  relatable: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  views3secs: Scalars['Int']['output'];
  views50pct: Scalars['Int']['output'];
  viewsComplete: Scalars['Int']['output'];
};

export type PostTopic =
  | 'ART'
  | 'BUSINESS'
  | 'EDUCATION'
  | 'FASHION'
  | 'FOOD'
  | 'GAMES'
  | 'HOME'
  | 'MOVIES'
  | 'MUSIC'
  | 'NONE'
  | 'RELATIONSHIPS'
  | 'SCIENCE';

export type PostType =
  | 'CUSTOM'
  | 'FOR_YOU'
  | 'FOTD'
  | 'REALS';

export type PreferencesInput = {
  allowAnalytics?: InputMaybe<Scalars['Boolean']['input']>;
  feelsPromptDisplayLength?: InputMaybe<Scalars['Int']['input']>;
  initialLandingPage?: InputMaybe<UserLandingPagePreference>;
  language?: InputMaybe<Scalars['String']['input']>;
  onboardingBookmark?: InputMaybe<Scalars['AWSDateTime']['input']>;
  onboardingFeed?: InputMaybe<Scalars['AWSDateTime']['input']>;
  onboardingInviteUsers?: InputMaybe<Scalars['AWSDateTime']['input']>;
  onboardingRating?: InputMaybe<Scalars['AWSDateTime']['input']>;
  onboardingRecordVideo?: InputMaybe<Scalars['AWSDateTime']['input']>;
  onboardingReputation?: InputMaybe<Scalars['AWSDateTime']['input']>;
  receiveRequests?: InputMaybe<NotificationSetting>;
  showOnboarding?: InputMaybe<Scalars['Boolean']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type Prompt = {
  __typename?: 'Prompt';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  currentVersion?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  modelConfigID?: Maybe<Scalars['ID']['output']>;
  modelID?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  position?: Maybe<PromptPosition>;
  promptLayerActive?: Maybe<Scalars['Boolean']['output']>;
  promptLayerName?: Maybe<Scalars['String']['output']>;
  role?: Maybe<PromptRole>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  userID?: Maybe<Scalars['ID']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type PromptCategory =
  | 'EMOTION'
  | 'FAMILY'
  | 'FUN'
  | 'GENDER'
  | 'INTERESTS'
  | 'NONE'
  | 'PAST_RELATIONSHIPS'
  | 'PERSONAL_GOALS'
  | 'PHYSICAL_HEALTH'
  | 'SELF_ESTEEM'
  | 'SEXUALITY'
  | 'SOCIAL'
  | 'STRESS'
  | 'WORK';

export type PromptPosition =
  | 'MAIN'
  | 'START';

export type PromptRole =
  | 'assistant'
  | 'system'
  | 'user';

export type Query = {
  __typename?: 'Query';
  getCurrentVersions?: Maybe<Array<AppReleaseHistory>>;
  getSelf?: Maybe<SuperfeelUser>;
  getSignedUrl: UploadUrl;
  getUser?: Maybe<SuperfeelUser>;
  searchComments?: Maybe<Array<PostComment>>;
  searchInvitations: Array<UserInvitation>;
  searchMusic?: Maybe<Array<SuperfeelMusic>>;
  searchNotifications: Array<SuperfeelNotification>;
  /** @deprecated Deprecated in v2.0. Use searchPostsV2 instead */
  searchPosts?: Maybe<Array<SuperfeelPost>>;
  searchPostsV2?: Maybe<Array<SuperfeelPost>>;
  searchPrompts?: Maybe<Array<SuperfeelPrompt>>;
  searchRatings?: Maybe<Array<PostRating>>;
  searchRequests: Array<FeelRequest>;
  searchUsers?: Maybe<Array<SuperfeelUser>>;
  testHandler?: Maybe<GenericResponse>;
  twinsGetCollection?: Maybe<TwinsGetCollectionResponse>;
  twinsGetDataSource?: Maybe<TwinsGetDataSourceResponse>;
  twinsGetDocument?: Maybe<TwinsGetDocumentResponse>;
  twinsGetFeedback?: Maybe<TwinsGetFeedbackResponse>;
  twinsGetFile?: Maybe<TwinsGetFileResponse>;
  twinsGetGraphAuth?: Maybe<TwinsGetGraphAuthResponse>;
  twinsGetIngestionData?: Maybe<TwinsGetIngestionDataResponse>;
  twinsGetJob?: Maybe<TwinsGetJobResponse>;
  twinsGetJobLog?: Maybe<TwinsGetJobLogResponse>;
  twinsGetJobPreview?: Maybe<TwinsGetJobPreviewResponse>;
  twinsGetMemory?: Maybe<TwinsGetMemoryResponse>;
  twinsGetModel?: Maybe<TwinsGetModelResponse>;
  twinsGetModelConfig?: Maybe<TwinsGetModelConfigResponse>;
  twinsGetPrompt?: Maybe<TwinsGetPromptResponse>;
  twinsGetSignedCloudfrontUrl?: Maybe<TwinsGetSignedCloudfrontUrlResponse>;
  twinsGetSignedUrl: UploadUrl;
  twinsGetUser?: Maybe<TwinsGetUserResponse>;
  twinsGetVoice?: Maybe<TwinsGetVoiceResponse>;
  twinsSearchMedia?: Maybe<Array<TwinsMedia>>;
  twinsSearchPosts?: Maybe<TwinsSearchPostsResponse>;
  twinsSearchUsers?: Maybe<TwinsSearchUsersResponse>;
  unifiedAPIUser?: Maybe<UnifiedApiUserResponse>;
  unifiedAPIUserContent?: Maybe<UnifiedApiUserContentResponse>;
  unifiedAPIUserContentFile?: Maybe<UnifiedApiUserContentFileResponse>;
  unifiedAPIUserContentPost?: Maybe<UnifiedApiUserContentPostResponse>;
};


export type QueryGetCurrentVersionsArgs = {
  input: GetCurrentVersionInput;
};


export type QueryGetSignedUrlArgs = {
  input: GetSignedUrlInput;
};


export type QueryGetUserArgs = {
  input: GetUserInput;
};


export type QuerySearchCommentsArgs = {
  input: SearchCommentsInput;
};


export type QuerySearchInvitationsArgs = {
  input: SearchInvitationInput;
};


export type QuerySearchMusicArgs = {
  input: SearchMusicInput;
};


export type QuerySearchNotificationsArgs = {
  input: SearchNotificationInput;
};


export type QuerySearchPostsArgs = {
  input: SearchPostsInput;
};


export type QuerySearchPostsV2Args = {
  input: SearchPostsInputV2;
};


export type QuerySearchPromptsArgs = {
  input: SearchPromptsInput;
};


export type QuerySearchRatingsArgs = {
  input: SearchRatingsInput;
};


export type QuerySearchRequestsArgs = {
  input: SearchRequestInput;
};


export type QuerySearchUsersArgs = {
  input: SearchUsersInput;
};


export type QueryTestHandlerArgs = {
  input?: InputMaybe<TestInput>;
};


export type QueryTwinsGetCollectionArgs = {
  input: TwinsGetCollectionInput;
};


export type QueryTwinsGetDataSourceArgs = {
  input: TwinsGetDataSourceInput;
};


export type QueryTwinsGetDocumentArgs = {
  input: TwinsGetDocumentInput;
};


export type QueryTwinsGetFeedbackArgs = {
  input: TwinsGetFeedbackInput;
};


export type QueryTwinsGetFileArgs = {
  input: TwinsGetFileInput;
};


export type QueryTwinsGetGraphAuthArgs = {
  input: TwinsGetGraphAuthInput;
};


export type QueryTwinsGetIngestionDataArgs = {
  input: TwinsGetIngestionDataInput;
};


export type QueryTwinsGetJobArgs = {
  input: TwinsGetJobInput;
};


export type QueryTwinsGetJobLogArgs = {
  input: TwinsGetJobLogInput;
};


export type QueryTwinsGetJobPreviewArgs = {
  input: TwinsGetJobPreviewInput;
};


export type QueryTwinsGetMemoryArgs = {
  input: TwinsGetMemoryInput;
};


export type QueryTwinsGetModelArgs = {
  input: TwinsGetModelInput;
};


export type QueryTwinsGetModelConfigArgs = {
  input: TwinsGetModelConfigInput;
};


export type QueryTwinsGetPromptArgs = {
  input: TwinsGetPromptInput;
};


export type QueryTwinsGetSignedCloudfrontUrlArgs = {
  input: TwinsGetSignedCloudfrontUrlInput;
};


export type QueryTwinsGetSignedUrlArgs = {
  input: TwinsGetSignedUrlInput;
};


export type QueryTwinsGetUserArgs = {
  input: TwinsGetUserInput;
};


export type QueryTwinsGetVoiceArgs = {
  input: TwinsGetVoiceInput;
};


export type QueryTwinsSearchMediaArgs = {
  input: TwinsSearchMediaInput;
};


export type QueryTwinsSearchPostsArgs = {
  input: TwinsSearchPostsInput;
};


export type QueryTwinsSearchUsersArgs = {
  input: TwinsSearchUsersInput;
};


export type QueryUnifiedApiUserArgs = {
  input: UnifiedApiUserInput;
};


export type QueryUnifiedApiUserContentArgs = {
  input: UnifiedApiUserContentInput;
};


export type QueryUnifiedApiUserContentFileArgs = {
  input: UnifiedApiUserContentFileInput;
};


export type QueryUnifiedApiUserContentPostArgs = {
  input: UnifiedApiUserContentPostInput;
};

export type RatingType =
  | 'FUNNY'
  | 'HELPFUL'
  | 'INSPIRING'
  | 'NONE'
  | 'RELATABLE';

export type ReportReason =
  | 'BULLYING'
  | 'FALSE_INFORMATION'
  | 'HATE_SPEECH'
  | 'ILLEGAL_ACTIVITY'
  | 'IMPERSONATION'
  | 'NO_REASON'
  | 'SCAM'
  | 'SELF_HARM'
  | 'SEXUAL'
  | 'SPAM'
  | 'UNDERAGE'
  | 'VIOLENCE';

export type ReportStatus =
  | 'FLAGGED'
  | 'OK'
  | 'PENDING';

export type ReportSubjectType =
  | 'COMMENT'
  | 'POST'
  | 'PROMPT'
  | 'USER';

export type ReportSubmitMethod =
  | 'AUTOMATED'
  | 'MANUAL'
  | 'USER_SUBMITTED';

export type RequestDirection =
  | 'RECEIVED'
  | 'SENT';

export type RequestStatus =
  | 'CANCELLED'
  | 'COMPLETED'
  | 'SENT';

export type RequestType =
  | 'FEEL_REQUEST'
  | 'NUDGE';

export type SampleData = {
  __typename?: 'SampleData';
  audioURI: Scalars['String']['output'];
  speaker: Scalars['String']['output'];
  transcript?: Maybe<SampleTranscriptData>;
  transcriptURI: Scalars['String']['output'];
};

export type SampleTranscriptData = {
  __typename?: 'SampleTranscriptData';
  endTime?: Maybe<Scalars['Float']['output']>;
  speaker?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['Float']['output']>;
  transcript?: Maybe<Scalars['String']['output']>;
};

export type SearchCommentsInput = {
  /** @deprecated Deprecated V2.0. Use targetUserIds instead */
  aboutIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** @deprecated Deprecated V2.0. Use authorUserIds instead */
  authorIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  authorUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  friendsOnly?: InputMaybe<Scalars['Boolean']['input']>;
  fromDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** @deprecated Deprecated V2.0. Use targetPostIds instead */
  postIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortDirection?: InputMaybe<SortDirection>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
  targetPostIds?: InputMaybe<Array<Scalars['String']['input']>>;
  targetUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  toDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SearchInvitationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<InvitationSortMethod>;
  sortDirection?: InputMaybe<SortDirection>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<InviteStatus>;
};

export type SearchMusicInput = {
  categories?: InputMaybe<Array<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  songIds?: InputMaybe<Array<Scalars['String']['input']>>;
  sort?: InputMaybe<MusicSortMethod>;
  sortDirection?: InputMaybe<SortDirection>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchNotificationInput = {
  direction?: InputMaybe<NotificationDirection>;
  fromDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
  targetUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  toDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  types?: InputMaybe<Array<NotificationType>>;
};

export type SearchPostsInput = {
  /** @deprecated Use feedType instead */
  audience?: InputMaybe<AudienceType>;
  authorIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** @deprecated Use feedType instead */
  filters?: InputMaybe<PostFiltersInput>;
  fromDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  postIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  promptIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  sort?: InputMaybe<PostSortMethod>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
  toDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SearchPostsInputV2 = {
  authorUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  feed?: InputMaybe<FeedType>;
  fromDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  postIds?: InputMaybe<Array<Scalars['String']['input']>>;
  sort?: InputMaybe<PostSortMethod>;
  sortDirection?: InputMaybe<SortDirection>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
  targetPromptIds?: InputMaybe<Array<Scalars['String']['input']>>;
  toDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  type?: InputMaybe<Array<PostType>>;
  unwatchedOnly?: InputMaybe<Scalars['Boolean']['input']>;
  useSuggested?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SearchPromptsInput = {
  authorUserId?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<Array<PromptCategory>>;
  fromDate?: InputMaybe<Scalars['AWSDate']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** @deprecated Deprecated in v2.0. Use promptIdsNew instead */
  promptIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  promptIdsNew?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
  toDate?: InputMaybe<Scalars['AWSDate']['input']>;
  type?: InputMaybe<PostType>;
  unansweredOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SearchProperties = {
  exactMatch: Scalars['Boolean']['input'];
  properties: Array<Scalars['String']['input']>;
  terms: Array<Scalars['String']['input']>;
};

export type SearchRatingsInput = {
  /** @deprecated Deprecated V2.0. Use authorUserIds instead */
  authorIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  authorUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** @deprecated Deprecated V2.0. Use targetPostIds instead */
  postIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
  targetPostIds?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<RatingType>;
};

export type SearchRequestInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  recipientUserId?: InputMaybe<Scalars['String']['input']>;
  requestDirection?: InputMaybe<RequestDirection>;
  requestIds?: InputMaybe<Array<Scalars['String']['input']>>;
  requestType?: InputMaybe<Array<RequestType>>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Array<RequestStatus>>;
};

export type SearchUsersInput = {
  includeHidden?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  relationship?: InputMaybe<ConnectionProperties>;
  search?: InputMaybe<SearchProperties>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
};

export type SendInvitationInput = {
  users: Array<UserInviteInput>;
};

export type SongCategory =
  | 'ANGST'
  | 'AWE'
  | 'EUPHORIA'
  | 'HYPE'
  | 'MELANCHOLY'
  | 'NOSTALGIA'
  | 'TRANQUILITY';

export type SortDirection =
  | 'ASC'
  | 'DESC'
  | 'FIELD'
  | 'RAND';

export type Status =
  | 'complete'
  | 'failed'
  | 'pending'
  | 'processing'
  | 'submitted';

export type SubmissionsHistoryInput = {
  lastSkipped?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SubmitFeedbackInput = {
  body: Scalars['String']['input'];
  imageKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  model: Scalars['String']['input'];
  osVersion: Scalars['String']['input'];
  platform: Scalars['String']['input'];
  type: FeedbackType;
};

export type SubmitReportInput = {
  reason: ReportReason;
  targetId: Scalars['String']['input'];
  targetType: ReportSubjectType;
  targetUserId: Scalars['String']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  empty?: Maybe<GenericResponse>;
  notification?: Maybe<SuperfeelNotification>;
  request?: Maybe<FeelRequest>;
};


export type SubscriptionNotificationArgs = {
  actorUserId?: InputMaybe<Scalars['String']['input']>;
  recipientUserId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionRequestArgs = {
  recipientUserId?: InputMaybe<Scalars['String']['input']>;
  senderUserId?: InputMaybe<Scalars['String']['input']>;
};

export type SuperfeelMusic = {
  __typename?: 'SuperfeelMusic';
  /** @deprecated Use parentCategoryId instead. Will be removed after v3.0 migration */
  category: SongCategory;
  categoryName?: Maybe<Scalars['String']['output']>;
  contentUri: Scalars['String']['output'];
  dateCreated: Scalars['AWSDateTime']['output'];
  duration: Scalars['Float']['output'];
  lastUpdated?: Maybe<Scalars['AWSDateTime']['output']>;
  parentCategoryId: Scalars['ID']['output'];
  songId: Scalars['ID']['output'];
  songName: Scalars['String']['output'];
  startTimestamp?: Maybe<Scalars['Float']['output']>;
  volume?: Maybe<Scalars['Float']['output']>;
};

export type SuperfeelMusicCategory = {
  __typename?: 'SuperfeelMusicCategory';
  categoryId: Scalars['ID']['output'];
  categoryName: Scalars['String']['output'];
  dateCreated: Scalars['AWSDateTime']['output'];
};

export type SuperfeelNotification = {
  __typename?: 'SuperfeelNotification';
  actionStatus?: Maybe<NotificationActionStatus>;
  actor?: Maybe<SuperfeelUser>;
  /** @deprecated Deprecated in V2.0. Use actorUserId instead */
  actorId?: Maybe<Scalars['Int']['output']>;
  actorUserId: Scalars['String']['output'];
  body?: Maybe<Scalars['String']['output']>;
  customValue?: Maybe<Scalars['String']['output']>;
  dateCreated: Scalars['AWSDateTime']['output'];
  /** @deprecated Deprecated in V2.0. Use notificationId instead */
  id?: Maybe<Scalars['Int']['output']>;
  notificationId: Scalars['String']['output'];
  notificationThumbnail?: Maybe<Scalars['String']['output']>;
  prompt?: Maybe<Scalars['String']['output']>;
  recipient?: Maybe<SuperfeelUser>;
  /** @deprecated Deprecated in V2.0. Use recipientUserId instead */
  recipientId?: Maybe<Scalars['Int']['output']>;
  recipientUserId: Scalars['String']['output'];
  /** @deprecated Deprecated in V2.0. Use targetId instead */
  subjectId?: Maybe<Scalars['Int']['output']>;
  subjectType: NotificationSubjectType;
  targetId: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  type: NotificationType;
};

export type SuperfeelPost = {
  __typename?: 'SuperfeelPost';
  audience: AudienceType;
  author?: Maybe<SuperfeelUser>;
  /** @deprecated Deprecated V2.0. Use authorUserId instead */
  authorId?: Maybe<Scalars['Int']['output']>;
  authorUserId: Scalars['String']['output'];
  backgroundSongId?: Maybe<Scalars['String']['output']>;
  caption?: Maybe<Scalars['String']['output']>;
  contentUri: Scalars['String']['output'];
  customThumbnailUri?: Maybe<Scalars['String']['output']>;
  dateCreated: Scalars['AWSDateTime']['output'];
  dateDeleted?: Maybe<Scalars['AWSDateTime']['output']>;
  flags?: Maybe<PostFlags>;
  /** @deprecated Deprecated in V2.0. Use postId instead */
  id?: Maybe<Scalars['Int']['output']>;
  isFlagged?: Maybe<Scalars['Boolean']['output']>;
  metadata?: Maybe<PostMetadata>;
  music?: Maybe<SuperfeelMusic>;
  postId: Scalars['String']['output'];
  prompt?: Maybe<SuperfeelPrompt>;
  /** @deprecated Deprecated V2.0. Use targetPromptId instead */
  promptId?: Maybe<Scalars['Int']['output']>;
  rating?: Maybe<PostRating>;
  stats?: Maybe<PostStatistics>;
  tag: PostTopic;
  targetPromptId: Scalars['String']['output'];
  thumbnailUri: Scalars['String']['output'];
  transcript?: Maybe<Scalars['String']['output']>;
  type: PostType;
  videoStartTimestamp?: Maybe<Scalars['Float']['output']>;
  videoStopTimestamp?: Maybe<Scalars['Float']['output']>;
  volume: Scalars['Float']['output'];
};

export type SuperfeelPrompt = {
  __typename?: 'SuperfeelPrompt';
  author?: Maybe<SuperfeelUser>;
  authorUserId?: Maybe<Scalars['String']['output']>;
  body: Scalars['String']['output'];
  category?: Maybe<PromptCategory>;
  dateCreated?: Maybe<Scalars['AWSDateTime']['output']>;
  hasCompleted?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Deprecated in V2.0. Use promptId instead */
  id?: Maybe<Scalars['Int']['output']>;
  intensity?: Maybe<Scalars['Int']['output']>;
  isFlagged?: Maybe<Scalars['Boolean']['output']>;
  promptId: Scalars['String']['output'];
  publishDate?: Maybe<Scalars['AWSDate']['output']>;
  type: PostType;
};

export type SuperfeelReport = {
  __typename?: 'SuperfeelReport';
  dateCreated: Scalars['AWSDateTime']['output'];
  dateReviewed?: Maybe<Scalars['AWSDateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  reason: ReportReason;
  reportId: Scalars['String']['output'];
  reporterUserId: Scalars['String']['output'];
  status: ReportStatus;
  submitMethod: ReportSubmitMethod;
  targetId: Scalars['String']['output'];
  targetType: ReportSubjectType;
  targetUserId: Scalars['String']['output'];
};

export type SuperfeelUser = {
  __typename?: 'SuperfeelUser';
  aboutMe?: Maybe<Scalars['String']['output']>;
  connectionStatus?: Maybe<ConnectionStatus>;
  dateCreated: Scalars['AWSDateTime']['output'];
  dateDeleted?: Maybe<Scalars['AWSDateTime']['output']>;
  dateOfBirth: Scalars['AWSDate']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  flags?: Maybe<UserFlags>;
  gender: Gender;
  genderDesc?: Maybe<Scalars['String']['output']>;
  /** @deprecated Deprecated in V2.0. Use userId instead */
  id?: Maybe<Scalars['Int']['output']>;
  interactions?: Maybe<UserInteractions>;
  isFlagged?: Maybe<Scalars['Boolean']['output']>;
  lastActivity: Scalars['AWSDateTime']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  notifications?: Maybe<UserNotifications>;
  phone?: Maybe<Scalars['String']['output']>;
  preferences?: Maybe<UserPreferences>;
  profilePictureUri?: Maybe<Scalars['String']['output']>;
  requests?: Maybe<Array<FeelRequest>>;
  stats?: Maybe<UserStatistics>;
  sub: Scalars['String']['output'];
  submissions?: Maybe<UserSubmitHistory>;
  userId: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type TestInput = {
  property?: InputMaybe<Scalars['String']['input']>;
};

export type TwinsCreateIngestionJobsInput = {
  index: Scalars['String']['input'];
  interviewIds?: InputMaybe<Array<Scalars['String']['input']>>;
  postIds?: InputMaybe<Array<Scalars['String']['input']>>;
  userId: Scalars['String']['input'];
};

export type TwinsDeleteCollectionInput = {
  collectionID: Scalars['ID']['input'];
};

export type TwinsDeleteDocumentInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  modelConfigID?: InputMaybe<Scalars['ID']['input']>;
};

export type TwinsDeleteFileInput = {
  collectionID?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  jobID?: InputMaybe<Scalars['ID']['input']>;
};

export type TwinsDeleteModelConfigInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type TwinsDeleteVoiceInput = {
  id: Scalars['ID']['input'];
};

export type TwinsGenerateSampleInput = {
  text: Scalars['String']['input'];
  userID: Scalars['ID']['input'];
  voiceID: Scalars['ID']['input'];
};

export type TwinsGenerateSampleResponse = {
  __typename?: 'TwinsGenerateSampleResponse';
  fileID?: Maybe<Scalars['ID']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type TwinsGetCollectionInput = {
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  jobID?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<CollectionType>;
  userID?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type TwinsGetCollectionResponse = {
  __typename?: 'TwinsGetCollectionResponse';
  data?: Maybe<Array<Maybe<Collection>>>;
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type TwinsGetDataSourceInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  modelConfigID?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  userID?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type TwinsGetDataSourceResponse = {
  __typename?: 'TwinsGetDataSourceResponse';
  data?: Maybe<Array<Maybe<DataSource>>>;
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type TwinsGetDocumentInput = {
  collectionName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modelConfigID?: InputMaybe<Scalars['ID']['input']>;
  userID?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type TwinsGetDocumentResponse = {
  __typename?: 'TwinsGetDocumentResponse';
  data?: Maybe<Array<Maybe<Document>>>;
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type TwinsGetFeedbackInput = {
  chatHistoryID?: InputMaybe<Scalars['ID']['input']>;
  dataSourceID?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modelConfigID?: InputMaybe<Scalars['ID']['input']>;
  modelVersionID?: InputMaybe<Scalars['ID']['input']>;
  promptVersionID?: InputMaybe<Scalars['ID']['input']>;
  sessionID?: InputMaybe<Scalars['ID']['input']>;
  submittedBy?: InputMaybe<Scalars['ID']['input']>;
  userID?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type TwinsGetFeedbackResponse = {
  __typename?: 'TwinsGetFeedbackResponse';
  data?: Maybe<Array<Maybe<Feedback>>>;
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type TwinsGetFileInput = {
  collectionID?: InputMaybe<Scalars['ID']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  medallion?: InputMaybe<FileMedallion>;
  sort?: InputMaybe<SortDirection>;
  userID?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type TwinsGetFileResponse = {
  __typename?: 'TwinsGetFileResponse';
  data?: Maybe<Array<Maybe<File>>>;
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type TwinsGetGraphAuthInput = {
  requester?: InputMaybe<Scalars['String']['input']>;
  requesting?: InputMaybe<Scalars['String']['input']>;
};

export type TwinsGetGraphAuthResponse = {
  __typename?: 'TwinsGetGraphAuthResponse';
  friends?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type TwinsGetIngestionDataInput = {
  userId: Scalars['String']['input'];
};

export type TwinsGetIngestionDataResponse = {
  __typename?: 'TwinsGetIngestionDataResponse';
  data?: Maybe<Array<Maybe<IngestionAsset>>>;
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type TwinsGetJobInput = {
  createdBy?: InputMaybe<Scalars['String']['input']>;
  fileID?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  stage?: InputMaybe<JobStage>;
  step?: InputMaybe<VoiceStep>;
  type?: InputMaybe<JobType>;
  userID?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  voiceID?: InputMaybe<Scalars['ID']['input']>;
};

export type TwinsGetJobLogInput = {
  action?: InputMaybe<JobAction>;
  id?: InputMaybe<Scalars['ID']['input']>;
  jobID?: InputMaybe<Scalars['ID']['input']>;
  stage?: InputMaybe<JobStage>;
  status?: InputMaybe<JobStatus>;
  type?: InputMaybe<JobType>;
  userID?: InputMaybe<Scalars['ID']['input']>;
};

export type TwinsGetJobLogResponse = {
  __typename?: 'TwinsGetJobLogResponse';
  data?: Maybe<Array<Maybe<JobLog>>>;
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type TwinsGetJobPreviewInput = {
  jobID?: InputMaybe<Scalars['ID']['input']>;
  step?: InputMaybe<VoiceStep>;
  userID?: InputMaybe<Scalars['ID']['input']>;
  voiceID: Scalars['ID']['input'];
};

export type TwinsGetJobPreviewResponse = {
  __typename?: 'TwinsGetJobPreviewResponse';
  data?: Maybe<JobPreview>;
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type TwinsGetJobResponse = {
  __typename?: 'TwinsGetJobResponse';
  data?: Maybe<Array<Maybe<Job>>>;
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type TwinsGetMemoryInput = {
  collectionName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modelConfigID?: InputMaybe<Scalars['ID']['input']>;
  userID?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type TwinsGetMemoryResponse = {
  __typename?: 'TwinsGetMemoryResponse';
  data?: Maybe<Array<Maybe<Memory>>>;
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type TwinsGetModelConfigInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  userID?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type TwinsGetModelConfigResponse = {
  __typename?: 'TwinsGetModelConfigResponse';
  data?: Maybe<Array<Maybe<ModelConfig>>>;
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type TwinsGetModelInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  modelConfigID?: InputMaybe<Scalars['ID']['input']>;
  userID?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type TwinsGetModelResponse = {
  __typename?: 'TwinsGetModelResponse';
  data?: Maybe<Array<Maybe<Model>>>;
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type TwinsGetPromptInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  modelConfigID?: InputMaybe<Scalars['ID']['input']>;
  modelID?: InputMaybe<Scalars['ID']['input']>;
  userID?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type TwinsGetPromptResponse = {
  __typename?: 'TwinsGetPromptResponse';
  data?: Maybe<Array<Maybe<Prompt>>>;
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type TwinsGetSignedCloudfrontUrlInput = {
  key: Scalars['String']['input'];
  type: TwinsObjectType;
};

export type TwinsGetSignedCloudfrontUrlResponse = {
  __typename?: 'TwinsGetSignedCloudfrontUrlResponse';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
  uri?: Maybe<Scalars['String']['output']>;
};

export type TwinsGetSignedUrlInput = {
  bucketName: Scalars['String']['input'];
  expiry: Scalars['Int']['input'];
  headers?: InputMaybe<Scalars['AWSJSON']['input']>;
  key: Scalars['String']['input'];
  method: HttpMethod;
  multipartUpload?: InputMaybe<MultipartUpload>;
  type: AdminObjectType;
};

export type TwinsGetUserInput = {
  /** @deprecated Deprecated in v2.0. Use userId instead */
  id?: InputMaybe<Scalars['Int']['input']>;
  userID?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type TwinsGetUserResponse = {
  __typename?: 'TwinsGetUserResponse';
  totalCount?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<SuperfeelUser>;
};

export type TwinsGetVoiceInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<AiProvider>;
  userID?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type TwinsGetVoiceResponse = {
  __typename?: 'TwinsGetVoiceResponse';
  data?: Maybe<Array<Maybe<Voice>>>;
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type TwinsMedia = {
  __typename?: 'TwinsMedia';
  contentUri?: Maybe<Scalars['String']['output']>;
  groupID: Scalars['String']['output'];
  groupName: Scalars['String']['output'];
  itemID: Scalars['String']['output'];
  itemName: Scalars['String']['output'];
  s3Key: Scalars['String']['output'];
  transcription?: Maybe<Scalars['AWSJSON']['output']>;
};

export type TwinsObjectType =
  | 'CONTENT'
  | 'MEDIA';

export type TwinsParseDocumentInput = {
  bucket: Scalars['String']['input'];
  key: Scalars['String']['input'];
  outputFormat?: InputMaybe<Scalars['String']['input']>;
};

export type TwinsParseDocumentResponse = {
  __typename?: 'TwinsParseDocumentResponse';
  content?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  outputKey?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type TwinsProgressVoiceInput = {
  createdBy: Scalars['ID']['input'];
  jobID?: InputMaybe<Scalars['ID']['input']>;
  step: VoiceStep;
  userID: Scalars['ID']['input'];
  voiceID: Scalars['ID']['input'];
};

export type TwinsSearchMediaInput = {
  groupID?: InputMaybe<Scalars['String']['input']>;
  itemID?: InputMaybe<Scalars['String']['input']>;
};

export type TwinsSearchPostsInput = {
  authorUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  fromDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  postIds?: InputMaybe<Array<Scalars['String']['input']>>;
  sort?: InputMaybe<PostSortMethod>;
  sortDirection?: InputMaybe<SortDirection>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
  targetPromptIds?: InputMaybe<Array<Scalars['String']['input']>>;
  toDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  type?: InputMaybe<Array<PostType>>;
};

export type TwinsSearchPostsResponse = {
  __typename?: 'TwinsSearchPostsResponse';
  posts: Array<SuperfeelPost>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type TwinsSearchUsersInput = {
  includeHidden?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  relationship?: InputMaybe<ConnectionProperties>;
  search?: InputMaybe<SearchProperties>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
};

export type TwinsSearchUsersResponse = {
  __typename?: 'TwinsSearchUsersResponse';
  totalCount: Scalars['Int']['output'];
  users?: Maybe<Array<Maybe<SuperfeelUser>>>;
};

export type TwinsSetPostsLocalContextInput = {
  authorUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  collectionName?: InputMaybe<Scalars['String']['input']>;
  fromDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  postIds?: InputMaybe<Array<Scalars['String']['input']>>;
  sort?: InputMaybe<PostSortMethod>;
  sortDirection?: InputMaybe<SortDirection>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
  targetPromptIds?: InputMaybe<Array<Scalars['String']['input']>>;
  toDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  type?: InputMaybe<Array<PostType>>;
};

export type TwinsUpdateChunk = {
  collectionName?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  documentID?: InputMaybe<Scalars['ID']['input']>;
  embedding?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modelConfigID?: InputMaybe<Scalars['ID']['input']>;
};

export type TwinsUpdateCollectionInput = {
  createdBy?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  interviewer?: InputMaybe<Scalars['String']['input']>;
  jobID?: InputMaybe<Scalars['ID']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<CollectionType>;
  userID?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type TwinsUpdateDataSourceInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  currentVersion?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modelConfigID?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userID?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type TwinsUpdateDocumentInput = {
  category?: InputMaybe<DocumentCategory>;
  chunkSize?: InputMaybe<Scalars['Int']['input']>;
  collectionName?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  fileExtension?: InputMaybe<Scalars['String']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileType?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  modelConfigID?: InputMaybe<Scalars['ID']['input']>;
  overlapPercentage?: InputMaybe<Scalars['Int']['input']>;
  userID?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type TwinsUpdateFeedbackInput = {
  accuracy?: InputMaybe<Scalars['Int']['input']>;
  audio?: InputMaybe<Scalars['Int']['input']>;
  chatHistoryID?: InputMaybe<Scalars['ID']['input']>;
  coherent?: InputMaybe<Scalars['Int']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  dataSourceID?: InputMaybe<Scalars['ID']['input']>;
  evaluationID?: InputMaybe<Scalars['ID']['input']>;
  expectations?: InputMaybe<Scalars['Int']['input']>;
  experience?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modelConfigID?: InputMaybe<Scalars['ID']['input']>;
  modelVersionID?: InputMaybe<Scalars['ID']['input']>;
  overall?: InputMaybe<Scalars['Int']['input']>;
  promptVersionID?: InputMaybe<Scalars['ID']['input']>;
  safe?: InputMaybe<Scalars['Int']['input']>;
  sessionID?: InputMaybe<Scalars['ID']['input']>;
  submittedBy?: InputMaybe<Scalars['String']['input']>;
  transcription?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  userID?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type TwinsUpdateFileInput = {
  collectionID?: InputMaybe<Scalars['ID']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  extension?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  interviewer?: InputMaybe<Scalars['String']['input']>;
  jobID?: InputMaybe<Scalars['ID']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  length?: InputMaybe<Scalars['Float']['input']>;
  medallion?: InputMaybe<FileMedallion>;
  name?: InputMaybe<Scalars['String']['input']>;
  selected?: InputMaybe<Scalars['Boolean']['input']>;
  size?: InputMaybe<Scalars['Float']['input']>;
  source?: InputMaybe<FilesSource>;
  speakerID?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  speakers?: InputMaybe<Scalars['Int']['input']>;
  stage?: InputMaybe<FileStage>;
  status?: InputMaybe<FileStatus>;
  type?: InputMaybe<FileType>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userID?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type TwinsUpdateJobInput = {
  action?: InputMaybe<JobAction>;
  batchJobId?: InputMaybe<Scalars['String']['input']>;
  collectionID?: InputMaybe<Scalars['ID']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  fileID?: InputMaybe<Scalars['ID']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  removeMultiSpeaker?: InputMaybe<Scalars['Boolean']['input']>;
  s3Bucket?: InputMaybe<Scalars['String']['input']>;
  s3Key?: InputMaybe<Scalars['String']['input']>;
  stage?: InputMaybe<JobStage>;
  status?: InputMaybe<JobStatus>;
  transcriptLanguage?: InputMaybe<Scalars['String']['input']>;
  transcriptMaxChars?: InputMaybe<Scalars['Int']['input']>;
  transcriptMinChars?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<JobType>;
  useFiles?: InputMaybe<Scalars['Boolean']['input']>;
  usePosts?: InputMaybe<Scalars['Boolean']['input']>;
  userID?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  voiceID?: InputMaybe<Scalars['ID']['input']>;
};

export type TwinsUpdateJobLogInput = {
  action?: InputMaybe<JobAction>;
  id: Scalars['ID']['input'];
  jobID: Scalars['ID']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  stage?: InputMaybe<JobStage>;
  status?: InputMaybe<JobStatus>;
  type?: InputMaybe<JobType>;
  userID?: InputMaybe<Scalars['ID']['input']>;
};

export type TwinsUpdateMemoryInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  chunkSize?: InputMaybe<Scalars['Int']['input']>;
  collectionName?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ingestedFeels?: InputMaybe<Scalars['Boolean']['input']>;
  modelConfigID?: InputMaybe<Scalars['ID']['input']>;
  overlapPercentage?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<MemoryType>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
  userID?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type TwinsUpdateModelConfigInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['Boolean']['input']>;
  userID?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type TwinsUpdateModelInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  currentVersion?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  language?: InputMaybe<AiLanguages>;
  model: Scalars['String']['input'];
  modelConfigID?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<ModelOptionsInput>;
  provider?: InputMaybe<AiProvider>;
  role?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AiType>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userID?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  voiceID?: InputMaybe<Scalars['ID']['input']>;
};

export type TwinsUpdatePromptInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  currentVersion?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  modelConfigID?: InputMaybe<Scalars['ID']['input']>;
  modelID?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<PromptPosition>;
  promptLayerActive?: InputMaybe<Scalars['Boolean']['input']>;
  promptLayerName?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<PromptRole>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userID?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type TwinsUpdateSpeakerIdInput = {
  collectionID?: InputMaybe<Scalars['ID']['input']>;
  fileID?: InputMaybe<Scalars['ID']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  speaker?: InputMaybe<Scalars['String']['input']>;
  speakerID?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
  userID?: InputMaybe<Scalars['ID']['input']>;
};

export type TwinsUpdateVoiceInput = {
  apiKey?: InputMaybe<Scalars['String']['input']>;
  apiUserID?: InputMaybe<Scalars['ID']['input']>;
  collectionID?: InputMaybe<Scalars['ID']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  jobID?: InputMaybe<Scalars['ID']['input']>;
  language?: InputMaybe<AiLanguages>;
  manifestURI?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<AiModels>;
  name?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<AiProvider>;
  removeMultiSpeaker?: InputMaybe<Scalars['Boolean']['input']>;
  sampleID?: InputMaybe<Scalars['ID']['input']>;
  selectAll?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Status>;
  step?: InputMaybe<VoiceStep>;
  transcriptLanguage?: InputMaybe<Scalars['String']['input']>;
  transcriptMaxChars?: InputMaybe<Scalars['Int']['input']>;
  transcriptMinChars?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<VoiceType>;
  useFiles?: InputMaybe<Scalars['Boolean']['input']>;
  usePosts?: InputMaybe<Scalars['Boolean']['input']>;
  userID?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UnifiedApiUserContentFileInput = {
  collectionID?: InputMaybe<Scalars['ID']['input']>;
  files?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  fromDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  originID?: InputMaybe<Scalars['ID']['input']>;
  toDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userID: Scalars['ID']['input'];
};

export type UnifiedApiUserContentFileResponse = {
  __typename?: 'UnifiedAPIUserContentFileResponse';
  files?: Maybe<Scalars['String']['output']>;
  filesCount?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UnifiedApiUserContentInput = {
  files?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  fromDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  posts?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  toDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userID: Scalars['ID']['input'];
};

export type UnifiedApiUserContentPostInput = {
  fromDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  posts?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  prompt?: InputMaybe<Scalars['Boolean']['input']>;
  toDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  userID: Scalars['ID']['input'];
};

export type UnifiedApiUserContentPostResponse = {
  __typename?: 'UnifiedAPIUserContentPostResponse';
  message?: Maybe<Scalars['String']['output']>;
  posts?: Maybe<Scalars['String']['output']>;
  postsCount?: Maybe<Scalars['Int']['output']>;
  prompts?: Maybe<Scalars['String']['output']>;
  promptsCount?: Maybe<Scalars['Int']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UnifiedApiUserContentResponse = {
  __typename?: 'UnifiedAPIUserContentResponse';
  files?: Maybe<Scalars['String']['output']>;
  filesCount?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  posts?: Maybe<Scalars['String']['output']>;
  postsCount?: Maybe<Scalars['Int']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UnifiedApiUserInput = {
  userID: Scalars['ID']['input'];
};

export type UnifiedApiUserResponse = {
  __typename?: 'UnifiedAPIUserResponse';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
  user?: Maybe<Scalars['String']['output']>;
};

export type UpdateConnectionInput = {
  isCircle: Scalars['Boolean']['input'];
  isFollowing: Scalars['Boolean']['input'];
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  targetUserId?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Deprecated in V2.0. Use targetUserId instead */
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateContentInput = {
  action: UpdateType;
  post: PostInfoInput;
};

export type UpdateFlagsInput = {
  flags: PostFlagsInput;
  targetCommentId?: InputMaybe<Scalars['String']['input']>;
  targetPostId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRatingInput = {
  /** @deprecated Deprecated in v2.0. Use targetPostId instead */
  postId?: InputMaybe<Scalars['Int']['input']>;
  rating: RatingType;
  targetPostId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRequestInput = {
  action: UpdateType;
  customPrompt?: InputMaybe<Scalars['String']['input']>;
  recipientUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  targetPromptId?: InputMaybe<Scalars['String']['input']>;
  targetRequestId?: InputMaybe<Scalars['String']['input']>;
  type: RequestType;
};

export type UpdateSettingsInput = {
  notifications?: InputMaybe<NotificationsInput>;
  preferences?: InputMaybe<PreferencesInput>;
  submissions?: InputMaybe<SubmissionsHistoryInput>;
};

export type UpdateType =
  | 'CREATE'
  | 'DELETE'
  | 'UPDATE';

export type UpdateUserInput = {
  action: UpdateType;
  deleteOptions?: InputMaybe<DeleteUserOptions>;
  user: UserInfoInput;
};

export type UploadUrl = {
  __typename?: 'UploadUrl';
  headers: Scalars['AWSJSON']['output'];
  type: Scalars['String']['output'];
  uploadId?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type UserFlags = {
  __typename?: 'UserFlags';
  deactivatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  deleteReason?: Maybe<DeleteAccountReason>;
  deletedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  lastDeactivatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  lastReportedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  reportedAt?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type UserInfoInput = {
  aboutMe?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['AWSDate']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  genderDesc?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  profilePictureUri?: InputMaybe<Scalars['String']['input']>;
};

export type UserInteractions = {
  __typename?: 'UserInteractions';
  sendMessage: Scalars['Boolean']['output'];
  sendRequest: Scalars['Boolean']['output'];
};

export type UserInvitation = {
  __typename?: 'UserInvitation';
  dateAccepted?: Maybe<Scalars['AWSDateTime']['output']>;
  dateCreated?: Maybe<Scalars['AWSDateTime']['output']>;
  dateDeleted?: Maybe<Scalars['AWSDateTime']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  invitationId: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber: Scalars['String']['output'];
  senderUserId: Scalars['String']['output'];
  status?: Maybe<InviteStatus>;
};

export type UserInviteInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type UserLandingPagePreference =
  | 'CREATE_PAGE'
  | 'FAVOURITES';

export type UserNotifications = {
  __typename?: 'UserNotifications';
  fotdReminder: Scalars['Boolean']['output'];
  newComment: NotificationSetting;
  newConnection: Scalars['Boolean']['output'];
  newFeelRequest: Scalars['Boolean']['output'];
  newFollower: Scalars['Boolean']['output'];
  newPost: NotificationSetting;
  newRating: NotificationSetting;
  newRequest: NotificationSetting;
  nudge: Scalars['Boolean']['output'];
  nudgeRequest: Scalars['Boolean']['output'];
};

export type UserPreferences = {
  __typename?: 'UserPreferences';
  allowAnalytics: Scalars['Boolean']['output'];
  feelsPromptDisplayLength: Scalars['Int']['output'];
  initialLandingPage: UserLandingPagePreference;
  language: Scalars['String']['output'];
  onboardingBookmark?: Maybe<Scalars['AWSDateTime']['output']>;
  onboardingFeed?: Maybe<Scalars['AWSDateTime']['output']>;
  onboardingInviteUsers?: Maybe<Scalars['AWSDateTime']['output']>;
  onboardingRating?: Maybe<Scalars['AWSDateTime']['output']>;
  onboardingRecordVideo?: Maybe<Scalars['AWSDateTime']['output']>;
  onboardingReputation?: Maybe<Scalars['AWSDateTime']['output']>;
  receiveRequests: NotificationSetting;
  sendAutomatedInvites?: Maybe<Scalars['Boolean']['output']>;
  showOnboarding: Scalars['Boolean']['output'];
  timezone: Scalars['String']['output'];
};

export type UserStatistics = {
  __typename?: 'UserStatistics';
  /** @deprecated Deprecated in V2.0. No longer in use as has no significance in app */
  connected: Scalars['Int']['output'];
  followedBy: Scalars['Int']['output'];
  follows: Scalars['Int']['output'];
  hidden: Scalars['Int']['output'];
};

export type UserSubmitHistory = {
  __typename?: 'UserSubmitHistory';
  lastFotd?: Maybe<Scalars['AWSDateTime']['output']>;
  lastNudgeRequest?: Maybe<Scalars['AWSDateTime']['output']>;
  lastReal?: Maybe<Scalars['AWSDateTime']['output']>;
  lastRealId?: Maybe<Scalars['String']['output']>;
  lastSkipped?: Maybe<Scalars['AWSDateTime']['output']>;
  lastSubmission?: Maybe<Scalars['AWSDateTime']['output']>;
  parentUserId: Scalars['String']['output'];
  /** @deprecated Deprecated V2.0. Use parentUserId instead */
  userId?: Maybe<Scalars['Int']['output']>;
};

export type UserWatchHistory = {
  __typename?: 'UserWatchHistory';
  lastViewed: Scalars['String']['output'];
  parentPostId: Scalars['String']['output'];
  parentUserId: Scalars['String']['output'];
  /** @deprecated Deprecated V2.0. Use parentPostId instead */
  postId?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Deprecated V2.0. Use parentUserId instead */
  userId?: Maybe<Scalars['Int']['output']>;
  views3secs: Scalars['Int']['output'];
  views50pct: Scalars['Int']['output'];
  viewsComplete: Scalars['Int']['output'];
};

export type Voice = {
  __typename?: 'Voice';
  apiKey?: Maybe<Scalars['String']['output']>;
  apiUserID?: Maybe<Scalars['ID']['output']>;
  collectionID?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['AWSDateTime']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  jobID?: Maybe<Scalars['ID']['output']>;
  language?: Maybe<AiLanguages>;
  manifestURI?: Maybe<Scalars['String']['output']>;
  model?: Maybe<AiModels>;
  name?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<AiProvider>;
  removeMultiSpeaker?: Maybe<Scalars['Boolean']['output']>;
  sampleID?: Maybe<Scalars['ID']['output']>;
  selectAll?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<Status>;
  step?: Maybe<VoiceStep>;
  transcriptLanguage?: Maybe<Scalars['String']['output']>;
  transcriptMaxChars?: Maybe<Scalars['Int']['output']>;
  transcriptMinChars?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<VoiceType>;
  updatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  useFiles?: Maybe<Scalars['Boolean']['output']>;
  usePosts?: Maybe<Scalars['Boolean']['output']>;
  userID?: Maybe<Scalars['ID']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type VoiceStep =
  | 'clean'
  | 'complete'
  | 'review'
  | 'select'
  | 'train';

export type VoiceTag = {
  __typename?: 'VoiceTag';
  id: Scalars['ID']['output'];
  tag: Scalars['String']['output'];
  voiceID: Scalars['ID']['output'];
};

export type VoiceTagLookup = {
  __typename?: 'VoiceTagLookup';
  id: Scalars['ID']['output'];
  tag: Scalars['String']['output'];
  userID: Scalars['ID']['output'];
  voiceID: Scalars['ID']['output'];
};

export type VoiceType =
  | 'instant';

export type TwinsUpdateFileMutationVariables = Exact<{
  input: TwinsUpdateFileInput;
}>;


export type TwinsUpdateFileMutation = { __typename?: 'Mutation', twinsUpdateFile?: { __typename?: 'GenericResponse', success?: boolean | undefined, message?: string | undefined } | undefined };

export type TwinsDeleteFileMutationVariables = Exact<{
  input: TwinsDeleteFileInput;
}>;


export type TwinsDeleteFileMutation = { __typename?: 'Mutation', twinsDeleteFile?: { __typename?: 'GenericResponse', success?: boolean | undefined, message?: string | undefined } | undefined };

export type TwinsUpdateCollectionMutationVariables = Exact<{
  input: TwinsUpdateCollectionInput;
}>;


export type TwinsUpdateCollectionMutation = { __typename?: 'Mutation', twinsUpdateCollection?: { __typename?: 'GenericResponse', success?: boolean | undefined, message?: string | undefined } | undefined };

export type TwinsDeleteCollectionMutationVariables = Exact<{
  input: TwinsDeleteCollectionInput;
}>;


export type TwinsDeleteCollectionMutation = { __typename?: 'Mutation', twinsDeleteCollection?: { __typename?: 'GenericResponse', success?: boolean | undefined, message?: string | undefined } | undefined };

export type CompleteMultipartUploadMutationVariables = Exact<{
  input: CompleteMultipartUploadInput;
}>;


export type CompleteMultipartUploadMutation = { __typename?: 'Mutation', completeMultipartUpload: { __typename?: 'CompleteMultipartUploadResponse', location: string, bucket: string, key: string, eTag: string } };

export type TwinsGetCollectionQueryVariables = Exact<{
  input: TwinsGetCollectionInput;
}>;


export type TwinsGetCollectionQuery = { __typename?: 'Query', twinsGetCollection?: { __typename?: 'TwinsGetCollectionResponse', success?: boolean | undefined, message?: string | undefined, data?: Array<{ __typename?: 'Collection', id: string, name?: string | undefined, location?: string | undefined, date?: string | undefined, createdBy?: string | undefined, username?: string | undefined, userID?: string | undefined, interviewer?: string | undefined, type?: CollectionType | undefined, status?: string | undefined, jobID?: string | undefined, createdAt?: string | undefined, updatedAt?: string | undefined } | undefined> | undefined } | undefined };

export type TwinsGetFileQueryVariables = Exact<{
  input: TwinsGetFileInput;
}>;


export type TwinsGetFileQuery = { __typename?: 'Query', twinsGetFile?: { __typename?: 'TwinsGetFileResponse', success?: boolean | undefined, message?: string | undefined, data?: Array<{ __typename?: 'File', id: string, name?: string | undefined, medallion?: FileMedallion | undefined, key?: string | undefined, size?: number | undefined, length?: number | undefined, content?: string | undefined, status?: FileStatus | undefined, type?: FileType | undefined, collectionID?: string | undefined, speakerID?: Array<string | undefined> | undefined, interviewer?: string | undefined, jobID?: string | undefined, createdBy?: string | undefined, speakers?: number | undefined, stage?: FileStage | undefined, username?: string | undefined, userID?: string | undefined, selected?: boolean | undefined, createdAt?: string | undefined, updatedAt?: string | undefined } | undefined> | undefined } | undefined };

export type TwinsCreateIngestionJobsMutationVariables = Exact<{
  input: TwinsCreateIngestionJobsInput;
}>;


export type TwinsCreateIngestionJobsMutation = { __typename?: 'Mutation', twinsCreateIngestionJobs?: { __typename?: 'GenericResponse', success?: boolean | undefined, message?: string | undefined } | undefined };

export type TwinsGetIngestionDataQueryVariables = Exact<{
  input: TwinsGetIngestionDataInput;
}>;


export type TwinsGetIngestionDataQuery = { __typename?: 'Query', twinsGetIngestionData?: { __typename?: 'TwinsGetIngestionDataResponse', success?: boolean | undefined, message?: string | undefined, data?: Array<{ __typename?: 'IngestionAsset', id: string, content?: string | undefined, name?: string | undefined, source: FilesSource, status: JobStatus, audience: AudienceType } | undefined> | undefined } | undefined };

export type TwinsSearchUsersQueryVariables = Exact<{
  input: TwinsSearchUsersInput;
}>;


export type TwinsSearchUsersQuery = { __typename?: 'Query', twinsSearchUsers?: { __typename?: 'TwinsSearchUsersResponse', totalCount: number, users?: Array<{ __typename?: 'SuperfeelUser', id?: number | undefined, userId: string, username: string, sub: string, firstName: string, lastName?: string | undefined, dateOfBirth: string, email?: string | undefined, phone?: string | undefined, gender: Gender, genderDesc?: string | undefined, dateCreated: string, dateDeleted?: string | undefined, lastActivity: string, profilePictureUri?: string | undefined, aboutMe?: string | undefined, isFlagged?: boolean | undefined, connectionStatus?: ConnectionStatus | undefined } | undefined> | undefined } | undefined };

export type TwinsGetVoiceQueryVariables = Exact<{
  input: TwinsGetVoiceInput;
}>;


export type TwinsGetVoiceQuery = { __typename?: 'Query', twinsGetVoice?: { __typename?: 'TwinsGetVoiceResponse', success?: boolean | undefined, message?: string | undefined, data?: Array<{ __typename?: 'Voice', id: string, name?: string | undefined, collectionID?: string | undefined, description?: string | undefined, apiKey?: string | undefined, apiUserID?: string | undefined, manifestURI?: string | undefined, provider?: AiProvider | undefined, type?: VoiceType | undefined, model?: AiModels | undefined, username?: string | undefined, userID?: string | undefined, sampleID?: string | undefined, language?: AiLanguages | undefined, transcriptMinChars?: number | undefined, transcriptMaxChars?: number | undefined, transcriptLanguage?: string | undefined, removeMultiSpeaker?: boolean | undefined, step?: VoiceStep | undefined, status?: Status | undefined, selectAll?: boolean | undefined, createdBy?: string | undefined, createdAt?: string | undefined, updatedAt?: string | undefined } | undefined> | undefined } | undefined };

export type TwinsUpdateVoiceMutationVariables = Exact<{
  input: TwinsUpdateVoiceInput;
}>;


export type TwinsUpdateVoiceMutation = { __typename?: 'Mutation', twinsUpdateVoice?: { __typename?: 'GenericResponse', success?: boolean | undefined, message?: string | undefined } | undefined };

export type TwinsDeleteVoiceMutationVariables = Exact<{
  input: TwinsDeleteVoiceInput;
}>;


export type TwinsDeleteVoiceMutation = { __typename?: 'Mutation', twinsDeleteVoice?: { __typename?: 'GenericResponse', success?: boolean | undefined, message?: string | undefined } | undefined };


export const TwinsUpdateFileDocument = `
    mutation TwinsUpdateFile($input: TwinsUpdateFileInput!) {
  twinsUpdateFile(input: $input) {
    success
    message
  }
}
    `;
export const TwinsDeleteFileDocument = `
    mutation TwinsDeleteFile($input: TwinsDeleteFileInput!) {
  twinsDeleteFile(input: $input) {
    success
    message
  }
}
    `;
export const TwinsUpdateCollectionDocument = `
    mutation TwinsUpdateCollection($input: TwinsUpdateCollectionInput!) {
  twinsUpdateCollection(input: $input) {
    success
    message
  }
}
    `;
export const TwinsDeleteCollectionDocument = `
    mutation TwinsDeleteCollection($input: TwinsDeleteCollectionInput!) {
  twinsDeleteCollection(input: $input) {
    success
    message
  }
}
    `;
export const CompleteMultipartUploadDocument = `
    mutation CompleteMultipartUpload($input: CompleteMultipartUploadInput!) {
  completeMultipartUpload(input: $input) {
    location
    bucket
    key
    eTag
  }
}
    `;
export const TwinsGetCollectionDocument = `
    query TwinsGetCollection($input: TwinsGetCollectionInput!) {
  twinsGetCollection(input: $input) {
    success
    message
    data {
      id
      name
      location
      date
      createdBy
      username
      userID
      interviewer
      type
      status
      jobID
      createdAt
      updatedAt
    }
  }
}
    `;
export const TwinsGetFileDocument = `
    query TwinsGetFile($input: TwinsGetFileInput!) {
  twinsGetFile(input: $input) {
    success
    message
    data {
      id
      name
      medallion
      key
      size
      length
      content
      status
      type
      collectionID
      speakerID
      interviewer
      jobID
      createdBy
      speakers
      stage
      username
      userID
      selected
      createdAt
      updatedAt
    }
  }
}
    `;
export const TwinsCreateIngestionJobsDocument = `
    mutation TwinsCreateIngestionJobs($input: TwinsCreateIngestionJobsInput!) {
  twinsCreateIngestionJobs(input: $input) {
    success
    message
  }
}
    `;
export const TwinsGetIngestionDataDocument = `
    query TwinsGetIngestionData($input: TwinsGetIngestionDataInput!) {
  twinsGetIngestionData(input: $input) {
    success
    message
    data {
      id
      content
      name
      source
      status
      audience
    }
  }
}
    `;
export const TwinsSearchUsersDocument = `
    query TwinsSearchUsers($input: TwinsSearchUsersInput!) {
  twinsSearchUsers(input: $input) {
    users {
      id
      userId
      username
      sub
      firstName
      lastName
      dateOfBirth
      email
      phone
      gender
      genderDesc
      dateCreated
      dateDeleted
      lastActivity
      profilePictureUri
      aboutMe
      isFlagged
      connectionStatus
    }
    totalCount
  }
}
    `;
export const TwinsGetVoiceDocument = `
    query TwinsGetVoice($input: TwinsGetVoiceInput!) {
  twinsGetVoice(input: $input) {
    success
    message
    data {
      id
      name
      collectionID
      description
      apiKey
      apiUserID
      manifestURI
      provider
      type
      model
      username
      userID
      sampleID
      language
      transcriptMinChars
      transcriptMaxChars
      transcriptLanguage
      removeMultiSpeaker
      step
      status
      selectAll
      createdBy
      createdAt
      updatedAt
    }
  }
}
    `;
export const TwinsUpdateVoiceDocument = `
    mutation TwinsUpdateVoice($input: TwinsUpdateVoiceInput!) {
  twinsUpdateVoice(input: $input) {
    success
    message
  }
}
    `;
export const TwinsDeleteVoiceDocument = `
    mutation TwinsDeleteVoice($input: TwinsDeleteVoiceInput!) {
  twinsDeleteVoice(input: $input) {
    success
    message
  }
}
    `;

const injectedRtkApi = baseApiWithGraphQL.injectEndpoints({
  endpoints: (build) => ({
    TwinsUpdateFile: build.mutation<TwinsUpdateFileMutation, TwinsUpdateFileMutationVariables>({
      query: (variables) => ({ document: TwinsUpdateFileDocument, variables })
    }),
    TwinsDeleteFile: build.mutation<TwinsDeleteFileMutation, TwinsDeleteFileMutationVariables>({
      query: (variables) => ({ document: TwinsDeleteFileDocument, variables })
    }),
    TwinsUpdateCollection: build.mutation<TwinsUpdateCollectionMutation, TwinsUpdateCollectionMutationVariables>({
      query: (variables) => ({ document: TwinsUpdateCollectionDocument, variables })
    }),
    TwinsDeleteCollection: build.mutation<TwinsDeleteCollectionMutation, TwinsDeleteCollectionMutationVariables>({
      query: (variables) => ({ document: TwinsDeleteCollectionDocument, variables })
    }),
    CompleteMultipartUpload: build.mutation<CompleteMultipartUploadMutation, CompleteMultipartUploadMutationVariables>({
      query: (variables) => ({ document: CompleteMultipartUploadDocument, variables })
    }),
    TwinsGetCollection: build.query<TwinsGetCollectionQuery, TwinsGetCollectionQueryVariables>({
      query: (variables) => ({ document: TwinsGetCollectionDocument, variables })
    }),
    TwinsGetFile: build.query<TwinsGetFileQuery, TwinsGetFileQueryVariables>({
      query: (variables) => ({ document: TwinsGetFileDocument, variables })
    }),
    TwinsCreateIngestionJobs: build.mutation<TwinsCreateIngestionJobsMutation, TwinsCreateIngestionJobsMutationVariables>({
      query: (variables) => ({ document: TwinsCreateIngestionJobsDocument, variables })
    }),
    TwinsGetIngestionData: build.query<TwinsGetIngestionDataQuery, TwinsGetIngestionDataQueryVariables>({
      query: (variables) => ({ document: TwinsGetIngestionDataDocument, variables })
    }),
    TwinsSearchUsers: build.query<TwinsSearchUsersQuery, TwinsSearchUsersQueryVariables>({
      query: (variables) => ({ document: TwinsSearchUsersDocument, variables })
    }),
    TwinsGetVoice: build.query<TwinsGetVoiceQuery, TwinsGetVoiceQueryVariables>({
      query: (variables) => ({ document: TwinsGetVoiceDocument, variables })
    }),
    TwinsUpdateVoice: build.mutation<TwinsUpdateVoiceMutation, TwinsUpdateVoiceMutationVariables>({
      query: (variables) => ({ document: TwinsUpdateVoiceDocument, variables })
    }),
    TwinsDeleteVoice: build.mutation<TwinsDeleteVoiceMutation, TwinsDeleteVoiceMutationVariables>({
      query: (variables) => ({ document: TwinsDeleteVoiceDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useTwinsUpdateFileMutation, useTwinsDeleteFileMutation, useTwinsUpdateCollectionMutation, useTwinsDeleteCollectionMutation, useCompleteMultipartUploadMutation, useTwinsGetCollectionQuery, useLazyTwinsGetCollectionQuery, useTwinsGetFileQuery, useLazyTwinsGetFileQuery, useTwinsCreateIngestionJobsMutation, useTwinsGetIngestionDataQuery, useLazyTwinsGetIngestionDataQuery, useTwinsSearchUsersQuery, useLazyTwinsSearchUsersQuery, useTwinsGetVoiceQuery, useLazyTwinsGetVoiceQuery, useTwinsUpdateVoiceMutation, useTwinsDeleteVoiceMutation } = injectedRtkApi;

