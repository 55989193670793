import {
  Alert,
  AlertTitle,
  Box,
  Divider,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import { SectionTitle, SubSectionTitle, Caption } from 'components/atoms';
import { useEffect, useState, useMemo } from 'react';
import { AIProvider, Status, VoiceStep } from 'types/enums';
import { useFile } from 'use/file';
import { useVoice } from 'use/voice';

const SummaryItem = ({ caption, value }) => (
  <Grid
    item
    xs={12}
    sm={3}
  >
    <Caption>{caption}</Caption>
    <Box
      component="span"
      sx={{
        color: 'grey',
        p: 0,
        mt: 0,
        fontWeight: 600,
        fontSize: 'h4.fontSize',
        display: 'block',
      }}
    >
      {value}
    </Box>
  </Grid>
);

const formatTime = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  return [
    hours.toString().padStart(2, '0'),
    minutes.toString().padStart(2, '0'),
    seconds.toString().padStart(2, '0'),
  ].join(':');
};

export default function VoiceTrain() {
  const { files } = useFile();
  const { voice, updateVoice } = useVoice();
  const [provider, setProvider] = useState(
    voice?.provider || AIProvider.PLAYHT,
  );

  useEffect(() => {
    if (voice?.provider) {
      setProvider(voice.provider);
    }
  }, [voice]);

  const handleProviderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProvider(event.target.value as AIProvider);
    updateVoice({ ...voice, provider: event.target.value as AIProvider });
  };

  const selectedFiles = useMemo(() => {
    return files?.filter((file) => file.selected) || [];
  }, [files]);

  const totalLength = useMemo(() => {
    return selectedFiles.reduce((total, file) => total + (file.length || 0), 0);
  }, [selectedFiles]);

  const totalWords = useMemo(() => {
    return selectedFiles.reduce(
      (total, file) => total + (file.content?.length || 0),
      0,
    );
  }, [selectedFiles]);

  const wordsPerMinute = useMemo(() => {
    if (totalLength === 0) return 0;
    const minutes = totalLength / 60;
    return Math.round(totalWords / minutes);
  }, [totalLength, totalWords]);

  const summaryData = useMemo(
    () => [
      { caption: 'Samples', value: selectedFiles.length },
      { caption: 'Interview Samples', value: '0' },
      { caption: 'Feel Samples', value: selectedFiles.length },
      { caption: 'Total Length', value: formatTime(totalLength) },
      { caption: 'Total Words', value: totalWords.toLocaleString() },
      { caption: 'Words / Minute', value: wordsPerMinute },
      { caption: 'Language', value: 'EN' },
    ],
    [selectedFiles, totalLength, totalWords, wordsPerMinute],
  );

  return (
    <Box>
      <SectionTitle>Voice Clone Training</SectionTitle>
      {voice?.step === VoiceStep.train &&
        voice?.status !== Status.submitted &&
        voice?.status !== Status.complete && (
          <Box my={1}>
            <Alert severity="info">
              <AlertTitle>Training in progress ...</AlertTitle>
              Processing usually takes around 5 minutes.
            </Alert>
          </Box>
        )}
      <SubSectionTitle>Summary</SubSectionTitle>
      <Box my={2}>
        <Grid
          container
          spacing={2}
        >
          {summaryData.map((item, index) => (
            <SummaryItem
              key={index}
              caption={item.caption}
              value={item.value}
            />
          ))}
        </Grid>
      </Box>
      <Divider sx={{ opacity: 0.2 }} />
      <SubSectionTitle>Provider</SubSectionTitle>
      <Box my={2}>
        <RadioGroup
          aria-labelledby="provider-radio-buttons-group-label"
          value={provider}
          onChange={handleProviderChange}
          name="radio-buttons-group"
          row
        >
          <label>
            <Radio
              disabled={
                voice?.step === VoiceStep.train &&
                voice?.status !== Status.complete &&
                voice?.provider !== AIProvider.UNKNOWN
              }
              value={AIProvider.PLAYHT}
            />
            PlayHT
          </label>
        </RadioGroup>
      </Box>
      <Divider sx={{ opacity: 0.2 }} />
    </Box>
  );
}
