import { Cancel, CheckCircle } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import { JobStatus } from 'types/enums';

interface StatusIndicatorProps {
  status: JobStatus;
}

export default function StatusIndicator({ status }: StatusIndicatorProps) {
  switch (status) {
    case JobStatus.complete:
      return (
        <Tooltip
          title="Step Completed"
          placement="top"
        >
          <Box>
            <CheckCircle color="success" />
          </Box>
        </Tooltip>
      );
    case JobStatus.failed:
      return (
        <Tooltip
          title="Step Failed"
          placement="top"
        >
          <Box>
            <Cancel color="error" />
          </Box>
        </Tooltip>
      );
  }
}
