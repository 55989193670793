import { Users } from 'components/organisms';
import Layout from 'layouts';

export default function Dashboard() {
  return (
    <Layout>
      <Users />
    </Layout>
  );
}
