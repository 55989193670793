import {
  FormControlLabel,
  Typography,
  Switch as MUISwitch,
} from '@mui/material';
import { Memory } from '@twins/types';

interface SwitchProps {
  disabled?: boolean;
  label: string;
  checked: boolean;
  handleChange: (field: keyof Memory, value: unknown) => void;
}

export function Switch({
  checked,
  handleChange,
  label,
  disabled = false,
}: SwitchProps) {
  return (
    <FormControlLabel
      control={
        <MUISwitch
          disabled={disabled}
          color="info"
          size="small"
          checked={checked}
          onChange={(e) => handleChange('active', e.target.checked)}
        />
      }
      label={
        <Typography sx={{ fontSize: '13px', fontWeight: 600, ml: 0.75 }}>
          {label}
        </Typography>
      }
    />
  );
}
