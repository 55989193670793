import { SuperfeelPost } from '@twins/types';
import { executeGraphqlOperation } from 'api';
import { GraphQLResult } from 'aws-amplify/api';
import { twinsSearchPostsQuery } from 'graphql/queries';

import { useCallback } from 'react';
import { useSnackbar } from 'use/snackbar';
import { useSuperfeelUser } from 'use/superfeel-user';
import { useUser } from 'use/user';
import { usePostContext } from './state';

export const usePostAPI = () => {
  const { getJWT } = useUser();
  const { superfeelUser } = useSuperfeelUser();
  const { showSnackbar } = useSnackbar();
  const { setLoadingPosts, setPosts } = usePostContext();

  const getPosts = useCallback(
    async (page: number, pageSize: number) => {
      try {
        setLoadingPosts(true);
        const input = {
          limit: pageSize,
          startIndex: page * pageSize,
          authorUserIds: [superfeelUser?.userId],
        };
        const jwt = await getJWT();
        const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
          twinsSearchPostsQuery,
          { input },
          jwt,
        );

        console.log('data', data);
        if (data) {
          const result = data as {
            twinsSearchPosts: { posts: SuperfeelPost[]; totalCount: number };
          };
          if (result.twinsSearchPosts.posts) {
            setPosts(result.twinsSearchPosts.posts);
            return {
              posts: result.twinsSearchPosts.posts,
              totalCount: result.twinsSearchPosts.totalCount,
            };
          }
        }
      } catch (e) {
        showSnackbar(`Failed to fetch job: ${e as string}`, 'error');
      } finally {
        setLoadingPosts(false);
      }
    },
    [getJWT, setLoadingPosts, setPosts, showSnackbar, superfeelUser?.userId],
  );

  return { getPosts };
};
