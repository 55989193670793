// TODO: Add skeleton loader for files.
import { Box, Divider, Stack, Typography } from '@mui/material';
import { Collection, File } from '@twins/types';
import { FilePreview } from 'components/molecules';
import { useSuperfeelUser } from 'use/superfeel-user';
import { useUser } from 'use/user';
import {
  FileMedallion,
  FileSource,
  FileStage,
  FileType,
  SortDirection,
} from 'types/enums';
import { v4 as uuid } from 'uuid';
import { ButtonIconTooltip } from 'components/atoms';
import {
  useTwinsGetFileQuery,
  useTwinsUpdateFileMutation,
} from 'services/collection.services.ts/file';
import { useCallback, useMemo } from 'react';
import { useSnackbar } from 'use/snackbar';

interface FileCollectionFilesProps {
  medallion: FileMedallion;
  collection: Collection;
}

export default function FileCollectionFiles({
  medallion,
  collection,
}: FileCollectionFilesProps) {
  const { showSnackbar } = useSnackbar();
  const { data } = useTwinsGetFileQuery({
    input: {
      collectionID: collection?.id,
      medallion,
      sort: SortDirection.ASC,
    },
  });
  const [updateFile, { isLoading: isUpdateLoading }] =
    useTwinsUpdateFileMutation();
  const { superfeelUser } = useSuperfeelUser();
  const { user } = useUser();

  const sortedFiles = useMemo(() => {
    const files = data?.twinsGetFile?.data ?? [];
    return [...files].sort(
      (a, b) =>
        new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
    );
  }, [data]);

  const handleAddFile = useCallback(async () => {
    try {
      const input: File = {
        id: uuid(),
        name: '',
        key: '',
        size: 0,
        type: FileType.unknown,
        stage: FileStage.upload,
        medallion: FileMedallion.bronze,
        source: FileSource.interview,
        collectionID: collection?.id,
        userID: superfeelUser?.userId,
        createdBy: user?.id,
        speakers: 0,
      };
      const result = await updateFile({ input }).unwrap();
      if (result?.twinsUpdateFile?.success) {
        showSnackbar('File created successfully', 'success');
      }
    } catch (error) {
      console.error(error);
      showSnackbar(
        'Failed to create new file. Please try again later.',
        'error',
      );
    }
  }, [
    collection?.id,
    showSnackbar,
    superfeelUser?.userId,
    updateFile,
    user?.id,
  ]);

  return (
    <Box pb={6}>
      <Stack
        sx={{ mt: 4 }}
        direction="row"
        spacing={2}
        alignItems="center"
      >
        <Typography variant="h3">Files</Typography>
        <ButtonIconTooltip
          isLoading={isUpdateLoading}
          onClick={handleAddFile}
          tooltipTitle="New File"
          icon="add"
          tooltipColorVariant="info"
        />
      </Stack>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Stack
        direction="column"
        spacing={2}
      >
        {sortedFiles.map((file) => (
          <FilePreview
            key={file?.id}
            file={file}
          />
        ))}
      </Stack>
    </Box>
  );
}
