import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useMemo,
  useCallback,
} from 'react';
import { JobPreview, Voice } from '@twins/types';

interface VoiceContextState {
  voice: Voice | null;
  setVoice: (voice: Voice | null) => void;
  voices: Voice[] | null;
  setVoices: (voices: Voice[] | null) => void;
  deletingVoice: boolean;
  setDeletingVoice: (deleting: boolean) => void;
  creatingVoice: boolean;
  setCreatingVoice: (creating: boolean) => void;
  samplesSelected: number;
  setSamplesSelected: (selected: number) => void;
  preview: JobPreview | null;
  setPreview: (preview: JobPreview | null) => void;
  reset: (...keys: (keyof VoiceContextState)[]) => void;
}

const VoiceContext = createContext<VoiceContextState | undefined>(undefined);

export const useVoiceContext = () => {
  const context = useContext(VoiceContext);
  if (!context) {
    throw new Error(
      'useVoiceContext must be used within a VoiceContextProvider',
    );
  }
  return context;
};

interface VoiceContextProviderProps {
  children: ReactNode;
}

export function VoiceContextProvider({
  children,
}: VoiceContextProviderProps): JSX.Element {
  const [voices, setVoices] = useState<Voice[] | null>(null);
  const [voice, setVoice] = useState<Voice | null>(null);
  const [deletingVoice, setDeletingVoice] = useState(false);
  const [creatingVoice, setCreatingVoice] = useState(false);
  const [preview, setPreview] = useState<JobPreview | null>(null);
  const [samplesSelected, setSamplesSelected] = useState<number>(0);

  const reset = useCallback((...keys: (keyof VoiceContextState)[]) => {
    keys.forEach((key) => {
      switch (key) {
        case 'voice':
          setVoice(null);
          break;
        case 'voices':
          setVoices(null);
          break;
        case 'deletingVoice':
          setDeletingVoice(false);
          break;
        case 'creatingVoice':
          setCreatingVoice(false);
          break;
        case 'preview':
          setPreview(null);
          break;
        case 'samplesSelected':
          setSamplesSelected(0);
          break;
        default:
          console.warn(`Unrecognized key: ${key}`);
      }
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      voice,
      setVoice,
      voices,
      setVoices,
      deletingVoice,
      setDeletingVoice,
      creatingVoice,
      setCreatingVoice,
      preview,
      setPreview,
      samplesSelected,
      setSamplesSelected,
      reset,
    }),
    [
      voice,
      voices,
      deletingVoice,
      creatingVoice,
      preview,
      samplesSelected,
      reset,
    ],
  );

  return (
    <VoiceContext.Provider value={contextValue}>
      {children}
    </VoiceContext.Provider>
  );
}
