import { api as generatedApi } from 'services/generated';

export const fileApi = generatedApi.enhanceEndpoints({
  addTagTypes: ['Files'],
  endpoints: {
    TwinsGetFile: {
      providesTags: ['Files'],
    },
    TwinsUpdateFile: {
      invalidatesTags: ['Files'],
      async onQueryStarted(
        { input: updatedFile },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          fileApi.util.updateQueryData(
            'TwinsGetFile',
            {
              input: {
                collectionID: updatedFile.collectionID,
                medallion: updatedFile.medallion,
                sort: 'ASC',
              },
            },
            (draft) => {
              const fileIndex = draft.twinsGetFile.data.findIndex(
                (file) => file.id === updatedFile.id,
              );
              if (fileIndex !== -1) {
                draft.twinsGetFile.data[fileIndex] = {
                  ...draft.twinsGetFile.data[fileIndex],
                  ...updatedFile,
                };
              }
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch (error) {
          console.error('TwinsUpdateFile optimistic update failed:', error);
          patchResult.undo();
        }
      },
    },
    TwinsDeleteFile: {
      invalidatesTags: ['Files'],
    },
  },
});

export const {
  useTwinsGetFileQuery,
  useLazyTwinsGetFileQuery,
  useTwinsUpdateFileMutation,
  useTwinsDeleteFileMutation,
  useCompleteMultipartUploadMutation,
} = fileApi;
