import { Tooltip, Stack } from '@mui/material';
import {
  HorizontalRule,
  BubbleChart,
  SmartToy,
  Flag,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { ai, graph } from 'common/model/active';
import { pink } from '@mui/material/colors';
import { useSuperfeelUser } from 'use/superfeel-user';

interface UserStatusProps {
  username: string;
  showFlag?: boolean;
}

function UserStatus({
  username,
  showFlag = false,
}: UserStatusProps): JSX.Element {
  const theme = useTheme();
  const { superfeelUser } = useSuperfeelUser();
  const isInGraph = graph.includes(username);
  const isInAI = ai.includes(username);

  return (
    <Stack
      direction="row"
      spacing={2}
    >
      {!showFlag && !isInGraph && !isInAI && (
        <HorizontalRule color="disabled" />
      )}
      {isInGraph && (
        <Tooltip title="Graph Active">
          <BubbleChart sx={{ color: pink[500] }} />
        </Tooltip>
      )}
      {isInAI && (
        <Tooltip title="AI Active">
          <SmartToy sx={{ color: theme.palette.info.main }} />
        </Tooltip>
      )}
      {superfeelUser?.isFlagged && <Flag color="error" />}
    </Stack>
  );
}

export default UserStatus;
