import {
  ToggleButtonGroup,
  ToggleButtonGroupProps,
  toggleButtonGroupClasses,
} from '@mui/material';

function StyledToggleButtonGroup(props: ToggleButtonGroupProps) {
  return (
    <ToggleButtonGroup
      {...props}
      sx={{
        [`& .${toggleButtonGroupClasses.grouped}`]: {
          margin: 0,
          border: 0,
          [`&.${toggleButtonGroupClasses.disabled}`]: {
            border: 0,
          },
        },
        ...props.sx,
      }}
    >
      {props.children}
    </ToggleButtonGroup>
  );
}
export default StyledToggleButtonGroup;
