import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useMemo,
  useCallback,
} from 'react';

interface PlayHTContextState {
  generating: boolean | null;
  setGenerating: (generating: boolean | null) => void;
  sampleText: string;
  setSampleText: (sampleText: string) => void;
  quality: string;
  setQuality: (quality: string) => void;
  outputFormat: string;
  setOutputFormat: (outputFormat: string) => void;
  speed: number;
  setSpeed: (speed: number) => void;
  sampleRate: number;
  setSampleRate: (sampleRate: number) => void;
  seed: number | null;
  setSeed: (seed: number | null) => void;
  temperature: number | null;
  setTemperature: (temperature: number | null) => void;
  voiceEngine: string | null;
  setVoiceEngine: (voiceEngine: string | null) => void;
  emotion: string | null;
  setEmotion: (emotion: string | null) => void;
  voiceGuidance: number | null;
  setVoiceGuidance: (voiceGuidance: number | null) => void;
  styleGuidance: number | null;
  setStyleGuidance: (styleGuidance: number | null) => void;
  reset: (...keys: (keyof PlayHTContextState)[]) => void;
}

const PlayHTContext = createContext<PlayHTContextState | undefined>(undefined);

export const usePlayHTContext = () => {
  const context = useContext(PlayHTContext);
  if (!context) {
    throw new Error(
      'usePlayHTContext must be used within a PlayHTContextProvider',
    );
  }
  return context;
};

interface PlayHTContextProviderProps {
  children: ReactNode;
}

export function PlayHTContextProvider({
  children,
}: PlayHTContextProviderProps): JSX.Element {
  const [generating, setGenerating] = useState<boolean | null>(null);
  const [sampleText, setSampleText] = useState<string>('');
  const [quality, setQuality] = useState<string>('');
  const [outputFormat, setOutputFormat] = useState<string>('');
  const [speed, setSpeed] = useState<number>(1);
  const [sampleRate, setSampleRate] = useState<number>(0);
  const [seed, setSeed] = useState<number | null>(null);
  const [temperature, setTemperature] = useState<number | null>(null);
  const [voiceEngine, setVoiceEngine] = useState<string | null>(null);
  const [emotion, setEmotion] = useState<string | null>(null);
  const [voiceGuidance, setVoiceGuidance] = useState<number | null>(null);
  const [styleGuidance, setStyleGuidance] = useState<number | null>(null);

  const reset = useCallback((...keys: (keyof PlayHTContextState)[]) => {
    keys.forEach((key) => {
      switch (key) {
        case 'generating':
          setGenerating(null);
          break;
        case 'sampleText':
          setSampleText('');
          break;
        case 'quality':
          setQuality('');
          break;
        case 'outputFormat':
          setOutputFormat('');
          break;
        case 'speed':
          setSpeed(1);
          break;
        case 'sampleRate':
          setSampleRate(0);
          break;
        case 'seed':
          setSeed(null);
          break;
        case 'temperature':
          setTemperature(null);
          break;
        case 'voiceEngine':
          setVoiceEngine(null);
          break;
        case 'emotion':
          setEmotion(null);
          break;
        case 'voiceGuidance':
          setVoiceGuidance(null);
          break;
        case 'styleGuidance':
          setStyleGuidance(null);
          break;
        default:
          console.warn(`Unrecognized key: ${key}`);
      }
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      generating,
      setGenerating,
      sampleText,
      setSampleText,
      quality,
      setQuality,
      outputFormat,
      setOutputFormat,
      speed,
      setSpeed,
      sampleRate,
      setSampleRate,
      seed,
      setSeed,
      temperature,
      setTemperature,
      voiceEngine,
      setVoiceEngine,
      emotion,
      setEmotion,
      voiceGuidance,
      setVoiceGuidance,
      styleGuidance,
      setStyleGuidance,
      reset,
    }),
    [
      generating,
      sampleText,
      quality,
      outputFormat,
      speed,
      sampleRate,
      seed,
      temperature,
      voiceEngine,
      emotion,
      voiceGuidance,
      styleGuidance,
      reset,
    ],
  );

  return (
    <PlayHTContext.Provider value={contextValue}>
      {children}
    </PlayHTContext.Provider>
  );
}
