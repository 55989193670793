import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Collection as CollectionComponent } from 'components/molecules';
import { v4 as uuid } from 'uuid';
import { LoadingButton } from '@mui/lab';
import { Add } from '@mui/icons-material';
import { CollectionType } from 'types/enums';
import { Collection } from '@twins/types';
import { useSuperfeelUser } from 'use/superfeel-user';
import { useUser } from 'use/user';
import { useSnackbar } from 'use/snackbar';
import { useTwinsUpdateCollectionMutation } from 'services/collection.services.ts/collection';
import { useTwinsGetCollectionQuery } from 'services/collection.services.ts/collection';

export default function DataInterviews() {
  const navigate = useNavigate();
  const { superfeelUser } = useSuperfeelUser();
  const { user } = useUser();
  const { showSnackbar } = useSnackbar();
  const [updateCollection, { isLoading: isCreatingCollection }] =
    useTwinsUpdateCollectionMutation();
  const { data } = useTwinsGetCollectionQuery({
    input: {
      userID: superfeelUser?.userId,
      type: CollectionType.interview,
    },
  });

  const collections = data?.twinsGetCollection?.data;

  if (!collections || collections?.length === 0) {
    const handleCreateCollection = async () => {
      try {
        const collectionID = uuid();
        const input: Collection = {
          id: collectionID,
          name: 'New Collection',
          type: CollectionType.interview,
          userID: superfeelUser?.userId,
          username: superfeelUser?.username,
          createdBy: user.id,
        };
        const result = await updateCollection({ input }).unwrap();
        if (result?.twinsUpdateCollection?.success) {
          showSnackbar('File created successfully', 'success');
          navigate(
            `/user/${superfeelUser?.username}/data/collection/${collectionID}`,
          );
        }
      } catch (error) {
        console.error(error);
        showSnackbar(
          'Failed to create new collection. Please try again later.',
          'error',
        );
      }
    };

    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="75vh"
        width="100%"
        height="100%"
      >
        <LoadingButton
          startIcon={<Add />}
          size="small"
          loading={isCreatingCollection}
          onClick={handleCreateCollection}
          variant="outlined"
          color="primary"
          sx={{ fontWeight: 'bold' }}
        >
          Create collection
        </LoadingButton>
      </Box>
    );
  }

  return (
    <Box>
      {collections?.map((collection) => (
        <CollectionComponent
          key={collection.id}
          collection={collection}
        />
      ))}
    </Box>
  );
}
