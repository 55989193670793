import { Box, CircularProgress } from '@mui/material';
import { useSuperfeelUser } from 'use/superfeel-user';
import Layout from 'layouts';
import { useCallback, useEffect, useState } from 'react';
import { Outlet, Route, Routes, useParams } from 'react-router-dom';
import { executeGraphqlOperation } from 'api';
import { ModelSettings } from 'components/organisms';
import { twinsGetUserQuery } from 'graphql/queries';
import { SuperfeelUserResult } from 'types/api';
import UserHome from './home';
import UserData from './data';
import UserModels from './twin';
import UserModel from './twin/model-config-id';
import UserFile from './data/collection/file';
import UserCollection from './data/collection';
import { useUser } from 'use/user';
import UserVoice from './voice';
import UserLogs from './log';
import VoiceID from './voice/voice-id';
import UserJob from './log/job-id';
import { UserGraph } from './ssm/components';

export default function User() {
  const [loading, setLoading] = useState(false);
  const { setSuperfeelUser, superfeelUser } = useSuperfeelUser();
  const { username } = useParams<{ username: string }>();
  const { getJWT } = useUser();

  const fetchUser = useCallback(async () => {
    if (loading || username === superfeelUser?.username) {
      return;
    }
    setLoading(true);
    const jwt = await getJWT();
    const input = {
      username,
    };
    const { data } = await executeGraphqlOperation<{
      twinsGetUser: { user: SuperfeelUserResult };
    }>(twinsGetUserQuery, { input }, jwt);
    setLoading(false);
    if (data?.twinsGetUser?.user) {
      setSuperfeelUser(data?.twinsGetUser?.user);
    } else {
      setSuperfeelUser(null);
      throw new Error('Failed to fetch users');
    }
  }, [getJWT, loading, setSuperfeelUser, superfeelUser?.username, username]);

  useEffect(() => {
    fetchUser();
  }, [setSuperfeelUser, fetchUser]);

  useEffect(() => {
    return () => {
      setSuperfeelUser(null);
    };
  }, [setSuperfeelUser]);

  return (
    <Layout>
      {loading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          minHeight="75vh"
          width="100%"
          height="100%"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box height="85vh">
          <Box
            height={0}
            overflow={'hidden'}
          >
            <Outlet />
          </Box>
          <Routes>
            <Route
              index
              element={<UserHome />}
            />
            <Route
              path="data"
              element={<UserData />}
            >
              <Route
                path="collection"
                element={<UserData collection />}
              />
            </Route>
            <Route
              path="data/collection/:collectionID"
              element={<UserCollection />}
            />
            <Route
              path="log"
              element={<UserLogs />}
            />
            <Route
              path="log/:jobID"
              element={<UserJob />}
            />
            <Route
              path="voice"
              element={<UserVoice />}
            />
            <Route
              path="voice/:voiceID"
              element={<VoiceID />}
            />

            <Route
              path="data/collection/:collectionID/file/:fileID"
              element={<UserFile />}
            />
            <Route
              path="smm"
              element={<UserGraph />}
            />
            <Route
              path="twin"
              element={<UserModels />}
            />
            <Route
              path="twin/:modelConfigID"
              element={<UserModel />}
            />
            <Route
              path="twin/:modelConfigID/settings"
              element={<ModelSettings />}
            />
          </Routes>
        </Box>
      )}
    </Layout>
  );
}
