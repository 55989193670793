// pkg/frontend/app/src/routes/user/jobs/columns.tsx
import { ContentCopy } from '@mui/icons-material';
import {
  IconButton,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Job } from '@twins/types';
import { formatDate } from 'common/utils';
import { Status } from 'types/enums';
import { useSnackbar } from 'use/snackbar';

type StatusValue = unknown;

export const getStatusColor = (value: StatusValue, theme: Theme): string => {
  switch (value) {
    case Status.failed:
      return theme.palette.error.main;
    case Status.complete:
      return theme.palette.success.main;
    case Status.pending:
    case Status.submitted:
      return theme.palette.warning.main;
    case Status.processing:
    case Status.running:
      return theme.palette.info.main;
    default:
      return 'inherit';
  }
};

export const useColumns = (): GridColDef[] => {
  const theme = useTheme();
  const { showSnackbar } = useSnackbar();

  return [
    {
      field: 'id',
      headerName: 'ID',
      width: 200,
      renderCell: (params: GridRenderCellParams<Job, string>) => (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Tooltip title={params.id || ''}>
            <Typography
              variant="body2"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                marginRight: 1,
                flex: 1,
              }}
            >
              {params.id}
            </Typography>
          </Tooltip>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              navigator.clipboard.writeText((params.id as string) || '');
              showSnackbar('Copied Log ID to clipboard', 'success');
            }}
          >
            <ContentCopy fontSize="small" />
          </IconButton>
        </div>
      ),
      align: 'left',
      cellClassName: 'centered-cell',
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 80,
      renderCell: (params: GridRenderCellParams<Job, string>) => (
        <Typography variant="body2">{params.value}</Typography>
      ),
      align: 'left',
      cellClassName: 'centered-cell',
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 180,
      renderCell: (params: GridRenderCellParams<Job, string>) => (
        <Typography variant="body2">{params.value}</Typography>
      ),
      align: 'left',
      cellClassName: 'centered-cell',
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      renderCell: (params) => (
        <Typography
          color={getStatusColor(params.value, theme)}
          variant="body2"
          style={{
            color: getStatusColor(params.value, theme),
          }}
        >
          {params.value}
        </Typography>
      ),
      cellClassName: 'centered-cell',
    },
    {
      field: 'message',
      headerName: 'Message',
      width: 350,
      renderCell: (params: GridRenderCellParams<Job, string>) => {
        const message = params.value;
        return <Typography variant="body2">{message}</Typography>;
      },
      cellClassName: 'centered-cell',
    },
    {
      field: 'updatedAt',
      headerName: 'Last Updated',
      width: 200,
      renderCell: (params: GridRenderCellParams<Job, string>) => (
        <Typography variant="body2">{formatDate(params.value)}</Typography>
      ),
      cellClassName: 'centered-cell',
    },
  ];
};
