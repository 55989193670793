import { Box, Divider, Stack, Typography } from '@mui/material';
import { Voice } from '@twins/types';
import { ButtonIconTooltip, Loading } from 'components/atoms';
import { VoicePreview } from 'components/molecules';
import { useCallback, useEffect } from 'react';

import {
  useTwinsGetVoiceQuery,
  useTwinsUpdateVoiceMutation,
} from 'services/voice.services.ts/voice';
import { AIProvider, VoiceStep } from 'types/enums';
import { useFile } from 'use/file';
import { useSnackbar } from 'use/snackbar';
import { useSuperfeelUser } from 'use/superfeel-user';
import { useUser } from 'use/user';
import {} from 'use/voice';
import { v4 as uuid } from 'uuid';

export default function UserVoice() {
  const { reset, file, files } = useFile();
  const { superfeelUser } = useSuperfeelUser();
  const { user } = useUser();
  const [updateVoice, { isLoading: isUpdatingVoice }] =
    useTwinsUpdateVoiceMutation();
  const { showSnackbar } = useSnackbar();

  const { data, isLoading: isGettingVoice } = useTwinsGetVoiceQuery({
    input: {
      userID: superfeelUser?.userId,
    },
  });
  const voices = data?.twinsGetVoice?.data;

  const handleAddVoice = useCallback(async () => {
    try {
      const newVoiceName = `voice-${Math.floor(Math.random() * 100000)}`;
      const input: Voice = {
        id: uuid(),
        name: newVoiceName,
        step: VoiceStep.select,
        provider: AIProvider.PLAYHT,
        userID: superfeelUser?.userId,
        username: superfeelUser?.username,
        createdBy: user?.id,
        transcriptLanguage: 'en',
        transcriptMinChars: 160,
        transcriptMaxChars: 2000,
      };
      const result = await updateVoice({ input }).unwrap();
      if (result?.twinsUpdateVoice?.success) {
        showSnackbar('Voice created successfully', 'success');
      }
    } catch (error) {
      console.error(error);
      showSnackbar(
        'Failed to create new voice. Please try again later.',
        'error',
      );
    }
  }, [
    showSnackbar,
    superfeelUser?.userId,
    superfeelUser?.username,
    updateVoice,
    user?.id,
  ]);

  useEffect(() => {
    if (file || files) {
      reset('file', 'files');
    }
  }, [file, files, reset]);
  if (isGettingVoice) {
    return <Loading />;
  }
  return (
    <Box>
      <Stack
        direction="row"
        spacing={2}
        sx={{ alignItems: 'center', pb: 0.25 }}
      >
        <Typography variant="h3">Voice</Typography>
        <ButtonIconTooltip
          isLoading={isUpdatingVoice}
          onClick={handleAddVoice}
          tooltipTitle="Add Voice"
          icon="add"
          tooltipColorVariant="info"
        />
      </Stack>
      <Divider />
      <Box my={2}>
        {voices?.map((voice: Voice) => (
          <VoicePreview
            key={voice?.id}
            voice={voice}
          />
        ))}
      </Box>
    </Box>
  );
}
