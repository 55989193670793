import { createBrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Home, User, Settings } from './routes';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
  {
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/user/:username/*',
        element: <User />,
      },
      {
        path: '/settings/',
        element: <Settings />,
      },
    ],
  },
]);
