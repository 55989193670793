import { JobPreview, Voice } from '@twins/types';
import { executeGraphqlOperation } from 'api';
import { GraphQLResult } from 'aws-amplify/api';
import {
  twinsDeleteVoiceMutation,
  twinsProgressVoiceMutation,
  twinsUpdateVoiceMutation,
} from 'graphql/mutations';
import { twinsGetJobPreviewQuery, twinsGetVoiceQuery } from 'graphql/queries';
import { debounce, omit } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useSnackbar } from 'use/snackbar';
import { useSuperfeelUser } from 'use/superfeel-user';
import { useUser } from 'use/user';
import { v4 as uuid } from 'uuid';
import { useVoiceContext } from './state';
import { useNavigate } from 'react-router-dom';
import { VoiceStep } from 'types/enums';

export const useVoiceAPI = () => {
  const { user, getJWT } = useUser();
  const { superfeelUser } = useSuperfeelUser();
  const { showSnackbar } = useSnackbar();
  const { setVoice, voices, voice, setVoices, setDeletingVoice, setPreview } =
    useVoiceContext();
  const navigate = useNavigate();

  const updateVoiceBase = useCallback(
    async (voice?: Voice) => {
      try {
        const updatedVoice = {
          id: voice?.id || uuid(),
          ...voice,
          updatedAt: new Date().toISOString(),
          createdBy: user?.id,
          userID: superfeelUser?.userId,
        };
        if (voices) {
          const existingVoiceIndex = voices.findIndex(
            (v) => v.id === updatedVoice.id,
          );
          let updatedVoices;
          if (existingVoiceIndex !== -1) {
            updatedVoices = [
              ...voices.slice(0, existingVoiceIndex),
              updatedVoice,
              ...voices.slice(existingVoiceIndex + 1),
            ];
          } else {
            updatedVoices = [...voices, updatedVoice];
          }
          setVoices(updatedVoices);
        } else {
          setVoices([updatedVoice]);
        }
        setVoice(updatedVoice);
        const input = {
          ...omit(updatedVoice, ['createdAt', 'updatedAt']),
        };
        const jwt = await getJWT();
        const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
          twinsUpdateVoiceMutation,
          { input },
          jwt,
        );
        if (data) {
          const twinsUpdateVoiceResponse = data as {
            twinsUpdateVoice: {
              success: boolean;
              message: string;
            };
          };
          if (!twinsUpdateVoiceResponse.twinsUpdateVoice.success) {
            showSnackbar(
              `Failed to update voice: ${twinsUpdateVoiceResponse.twinsUpdateVoice.message}`,
              'error',
            );
          }
        }
      } catch (error) {
        console.error('Failed to update voice:', error);
        showSnackbar(`Error updating voice: ${error as string}`, 'error');
      }
    },
    [
      getJWT,
      setVoice,
      setVoices,
      showSnackbar,
      superfeelUser?.userId,
      user?.id,
      voices,
    ],
  );

  const updateVoice = useMemo(
    () => debounce(updateVoiceBase, 500),
    [updateVoiceBase],
  );

  const getVoices = useCallback(async () => {
    try {
      const input = {
        userID: superfeelUser?.userId,
      };
      const jwt = await getJWT();
      const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
        twinsGetVoiceQuery,
        { input },
        jwt,
      );
      console.log('data', data);
      if (data) {
        const result = data as {
          twinsGetVoice: {
            success: boolean;
            message: string;
            data: Voice[];
          };
        };
        if (
          result.twinsGetVoice.success &&
          result.twinsGetVoice.data.length > 0
        ) {
          console.log('result.twinsGetVoice.data', result.twinsGetVoice.data);
          setVoices(result.twinsGetVoice.data);
          return result.twinsGetVoice.data;
        }
      }
    } catch (e) {
      showSnackbar(`Failed to fetch job: ${e as string}`, 'error');
    }
  }, [getJWT, setVoices, showSnackbar, superfeelUser?.userId]);

  const getJobPreview = useCallback(async () => {
    try {
      const jwt = await getJWT();
      const input = {
        userID: superfeelUser?.userId,
        voiceID: voice?.id,
      };
      const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
        twinsGetJobPreviewQuery,
        { input },
        jwt,
      );
      console.log('data', data);
      if (data) {
        const result = data as {
          twinsGetJobPreview: {
            success: boolean;
            message: string;
            data: JobPreview;
          };
        };
        if (result.twinsGetJobPreview.success) {
          setPreview(result.twinsGetJobPreview.data);
          console.log('result.twinsGetJobPreview', result.twinsGetJobPreview);
          return result.twinsGetJobPreview;
        }
      }
    } catch (e) {
      showSnackbar(`Failed to fetch job: ${e as string}`, 'error');
    }
  }, [getJWT, setPreview, showSnackbar, superfeelUser?.userId, voice?.id]);

  const deleteVoice = useCallback(
    async (voiceID: string) => {
      try {
        if (!voice) return;
        const input = {
          id: voiceID,
        };
        const jwt = await getJWT();
        const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
          twinsDeleteVoiceMutation,
          { input },
          jwt,
        );
        console.log('data', data);
        if (data) {
          const twinsDeleteVoiceResponse = data as {
            twinsDeleteVoice: {
              success: boolean;
              message: string;
            };
          };
          if (!twinsDeleteVoiceResponse.twinsDeleteVoice.success) {
            showSnackbar(
              `Failed to delete voice: ${twinsDeleteVoiceResponse.twinsDeleteVoice.message}`,
              'error',
            );
          } else {
            const updatedVoices = voices?.filter((v) => v.id !== voice.id);
            setVoices(updatedVoices);
            showSnackbar(`Deleted voice`, 'success');
          }
        }
      } catch (error) {
        showSnackbar(`Error during ingestion: ${error as string}`, 'error');
      }
    },
    [getJWT, setVoices, showSnackbar, voice, voices],
  );

  const progressVoice = useCallback(
    async (voiceID: string, step: VoiceStep, jobID?: string) => {
      try {
        const jwt = await getJWT();
        const input = {
          jobID,
          userID: superfeelUser?.userId,
          voiceID,
          step,
          createdBy: user?.id,
        };
        const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
          twinsProgressVoiceMutation,
          { input },
          jwt,
        );
        console.log('data', data);
        if (data) {
          const twinsProgressVoiceResponse = data as {
            twinsProgressVoice: {
              success: boolean;
              message: string;
            };
          };
          if (!twinsProgressVoiceResponse.twinsProgressVoice.success) {
            showSnackbar(
              `Failed to progress voice: ${twinsProgressVoiceResponse.twinsProgressVoice.message}`,
              'error',
            );
          } else {
            showSnackbar(`Progressed voice`, 'success');
          }
        }
      } catch (error) {
        showSnackbar(`Error during ingestion: ${error as string}`, 'error');
      }
    },
    [getJWT, showSnackbar, superfeelUser?.userId, user?.id],
  );

  const getVoice = useCallback(
    async (id: string) => {
      try {
        const jwt = await getJWT();
        const input = {
          id,
        };
        const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
          twinsGetVoiceQuery,
          { input },
          jwt,
        );
        if (data) {
          const result = data as {
            twinsGetVoice: {
              success: boolean;
              message: string;
              data: Voice[];
            };
          };
          if (
            result.twinsGetVoice.success &&
            result.twinsGetVoice.data.length > 0
          ) {
            setVoice(result.twinsGetVoice.data[0]);
            return result.twinsGetVoice.data[0];
          }
        }
      } catch (e) {
        showSnackbar(`Failed to fetch collections: ${e as string}`, 'error');
      }
    },
    [getJWT, setVoice, showSnackbar],
  );

  const handleDeleteVoice = async (voiceID: string) => {
    console.log('Voice to delete:', voiceID);

    try {
      setDeletingVoice(true);
      const updatedVoices = voices?.filter((voice) => voice.id !== voiceID);
      setVoices(updatedVoices);
      await deleteVoice(voiceID);
      navigate(`/user/${superfeelUser?.username}/voice`);
      showSnackbar('Deleted voice', 'success');
    } catch (error) {
      console.error('Failed to delete voice:', error);
      showSnackbar(`Failed to delete voice: ${error}`, 'error');
    } finally {
      setDeletingVoice(false);
    }
  };

  return {
    progressVoice,
    handleDeleteVoice,
    updateVoice,
    updateVoiceBase,
    getVoice,
    getVoices,
    deleteVoice,
    getJobPreview,
  };
};
