import { useState, useEffect, useMemo } from 'react';
import { Box, Divider } from '@mui/material';
import { Loading, ProgressStepper } from 'components/atoms';
import {
  VoiceBottomRow,
  VoiceClean,
  VoiceDetails,
  VoiceReview,
  VoiceSelect,
  VoiceTest,
  VoiceTrain,
} from 'components/molecules';
import { useParams } from 'react-router-dom';
import { useVoice } from 'use/voice';
import { getVoiceStepFromIndex, getVoiceStepindex } from 'common/utils';
import { AIProvider, Status, VoiceStep } from 'types/enums';
import { v4 as uuid } from 'uuid';
import { useApp } from 'use/app';
import { useTwinsGetVoiceQuery } from 'services/voice.services.ts/voice';

export default function VoiceID() {
  const { setBottomNavLoading } = useApp();
  const { updateVoice, progressVoice } = useVoice();
  const { voiceID } = useParams<{ voiceID: string }>();
  const steps = ['Select', 'Clean', 'Review', 'Train'];
  const [activeStep, setActiveStep] = useState(1);
  const [component, setComponent] = useState(<Loading />);

  const { data: voiceData } = useTwinsGetVoiceQuery({ input: { id: voiceID } });
  const voice = useMemo(
    () => voiceData?.twinsGetVoice?.data[0],
    [voiceData?.twinsGetVoice?.data],
  );

  useEffect(() => {
    switch (activeStep) {
      case 0:
        setComponent(<VoiceSelect />);
        break;
      case 1:
        setComponent(<VoiceClean />);
        break;
      case 2:
        setComponent(<VoiceReview voice={voice} />);
        break;
      case 3:
        setComponent(<VoiceTrain />);
        break;
      case 4:
        setComponent(<VoiceTest />);
        break;
      default:
        setComponent(<Loading />);
    }
  }, [activeStep, voice]);

  const processClick = async () => {
    try {
      setBottomNavLoading(true);
      let status = Status.submitted;
      let currentStep = activeStep + 1;
      let currentVoiceStep = getVoiceStepFromIndex(currentStep);
      if (
        currentVoiceStep === VoiceStep.complete &&
        voice.status !== Status.complete
      ) {
        status = Status.pending;
        currentStep = activeStep;
        currentVoiceStep = VoiceStep.train;
        console.log('Staying on train step until voice status is complete');
      }

      console.log('status:', status);

      setActiveStep(currentStep);
      let jobID;
      if (currentVoiceStep === VoiceStep.clean) {
        jobID = uuid();
      }

      await updateVoice({
        ...voice,
        step: currentVoiceStep,
        status,
      });
      if (
        currentVoiceStep === VoiceStep.train &&
        voice?.provider === AIProvider.UNKNOWN
      ) {
        return;
      }

      await progressVoice(voiceID, currentVoiceStep, jobID);
    } catch (error) {
      console.error('Failed to update voice:', error);
    } finally {
      setBottomNavLoading(false);
    }
  };

  const handleClick = () => {
    processClick();
  };

  useEffect(() => {
    if (voiceID === voice?.id && voice?.step) {
      setActiveStep(getVoiceStepindex(voice.step as VoiceStep));
    }
  }, [voice, voiceID]);

  return (
    <Box>
      <VoiceDetails />
      <Box
        mt={8}
        mb={2}
      >
        <ProgressStepper
          steps={steps}
          activeStep={activeStep}
        />
      </Box>
      <Divider />
      <Box pb={4}>{component}</Box>
      <VoiceBottomRow
        voice={voice}
        onAction={handleClick}
        collectionId={voice?.collectionID}
      />
    </Box>
  );
}
