import React, { useCallback, useState, useEffect } from 'react';
import { Box, Paper } from '@mui/material';
import { DataGrid, GridPaginationModel } from '@mui/x-data-grid';
import { PostDetails } from 'components/atoms';
import { SuperfeelPost } from '@twins/types';
import { columns } from './local';
import { usePost } from 'use/post';

function DataFeels(): JSX.Element {
  const { getPosts, posts, setPosts, setLoadingPosts } = usePost();
  const [state, setState] = useState({
    pageSize: 10,
    page: 0,
    rowCount: 0,
    selectedRow: null as SuperfeelPost | null,
  });

  const { pageSize, page, rowCount, selectedRow } = state;

  const fetchAllFeels = useCallback(async () => {
    setLoadingPosts(true);
    try {
      const data = await getPosts(page, pageSize);
      setPosts(data.posts);
      setState((prev) => ({ ...prev, rowCount: data.totalCount }));
    } finally {
      setLoadingPosts(false);
    }
  }, [page, pageSize, getPosts, setPosts, setLoadingPosts]);

  useEffect(() => {
    fetchAllFeels();
  }, [fetchAllFeels]);

  const handlePaginationModelChange = useCallback(
    (model: GridPaginationModel) => {
      setState((prev) => ({
        ...prev,
        page: model.page,
        pageSize: model.pageSize,
      }));
    },
    [],
  );

  const handleRowClick = useCallback((row: SuperfeelPost) => {
    if (row.audience === 'PUBLIC') {
      setState((prev) => ({ ...prev, selectedRow: row }));
    }
  }, []);

  return (
    <Box>
      <Paper>
        <DataGrid
          slots={{
            noRowsOverlay: () => <></>,
          }}
          rows={posts || []}
          columns={columns}
          paginationMode="server"
          rowCount={rowCount}
          pageSizeOptions={[5, 10, 20]}
          paginationModel={{ page, pageSize }}
          onPaginationModelChange={handlePaginationModelChange}
          autoHeight
          onRowClick={(params) => handleRowClick(params.row as SuperfeelPost)}
        />
      </Paper>
      <PostDetails
        selectedRow={selectedRow}
        onClose={() => setState((prev) => ({ ...prev, selectedRow: null }))}
      />
    </Box>
  );
}

export default React.memo(DataFeels);
