import { useCallback, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useModelConfig } from 'use/model-config';
import {
  AiLanguages,
  AiModels,
  AiProvider,
  Model,
  ModelOptions,
} from '@twins/types';
import {
  AIModels,
  AIProvider,
  AIType,
  Languages,
  MODEL_PROVIDERS,
} from 'types/enums';
import { EnumSelect, Select, Section } from 'components/atoms';
import { menuOptions } from 'common/utils';
import { useSnackbar } from 'use/snackbar';

export function ModelTranscriber() {
  const { models, setModels, changed, setChanged } = useModelConfig();
  const transcriberModelIndex = models.findIndex((m) => m.type === AIType.ASR);
  const transcriberModel: Model | undefined = models[transcriberModelIndex];
  const availableProviders = Object.keys(
    MODEL_PROVIDERS[AIType.ASR] || {},
  ) as AIProvider[];
  const selectedProvider = (transcriberModel?.provider ||
    AIProvider.DEEPGRAM) as keyof (typeof MODEL_PROVIDERS)[typeof AIType.ASR];
  const availableModels = MODEL_PROVIDERS[AIType.ASR]?.[selectedProvider] || [];
  const selectedModel = (transcriberModel?.model ||
    AIModels.NOVA_2) as AIModels;
  const { showSnackbar } = useSnackbar();

  const changeStatus = useCallback(
    (value: boolean): void => {
      if (!changed) {
        setChanged(value);
      }
    },
    [changed, setChanged],
  );

  const updateModel = useCallback(
    (updatedModel: Partial<Model>): void => {
      if (transcriberModel) {
        const newModel: Model = {
          ...transcriberModel,
          ...updatedModel,
          options: {
            ...transcriberModel.options,
            ...(updatedModel.options || {}),
          },
        };
        const updatedModels = models.map((model) =>
          model.type === AIType.ASR ? newModel : model,
        );
        setModels(updatedModels);
        changeStatus(true);
      }
    },
    [changeStatus, models, setModels, transcriberModel],
  );
  useEffect(() => {
    if (
      transcriberModel?.options &&
      typeof transcriberModel.options === 'string'
    ) {
      try {
        const parsedOptions = transcriberModel.options as ModelOptions;
        updateModel({ options: parsedOptions });
      } catch (error) {
        showSnackbar('Failed to parse model options', 'error');
      }
    }
  }, [showSnackbar, transcriberModel.options, updateModel]);

  const handleProviderChange = (value: AiProvider): void =>
    updateModel({ provider: value });

  const handleLanguageChange = (value: AiLanguages): void =>
    updateModel({ language: value });

  const handleModelChange = (value: AiModels): void =>
    updateModel({ model: value });

  return (
    <Box
      component="section"
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <Typography
        sx={{ flex: 1 }}
        variant="h4"
      >
        Transcription Configuration
      </Typography>
      <Typography variant="caption">
        This section allows you to configure the transcription settings for the
        assistant.
      </Typography>
      <Box my={2}>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Section label="Provider">
              <Select
                value={selectedProvider || AIProvider.DEEPGRAM}
                defaultValue={AIProvider.DEEPGRAM}
                options={menuOptions(availableProviders)}
                onChange={(val) => handleProviderChange(val as AiProvider)}
              />
            </Section>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Section label="Languages">
              <EnumSelect
                enumOptions={Languages}
                value={transcriberModel?.language}
                defaultValue={Languages.EN}
                onChange={(val) => handleLanguageChange(val)}
                fullWidth
                size="small"
              />
            </Section>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Section label="Model">
              <Select
                value={selectedModel || AIModels.NOVA_2}
                defaultValue={AIModels.NOVA_2}
                options={menuOptions(availableModels)}
                onChange={(val) => handleModelChange(val as AiModels)}
              />
            </Section>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
