import Box from '@mui/material/Box';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { ReactNode, useCallback } from 'react';

interface ModalProps {
  title?: string;
  isOpen: boolean;
  onClose?: (event: object, reason: 'backdropClick' | 'escapeKeyDown') => void;
  children: ReactNode;
  width?: number | string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  actions?: ReactNode;
}

export function Modal({
  isOpen,
  onClose,
  children,
  title = '',
  maxWidth = 'xs',
  actions,
}: ModalProps) {
  const handleClose = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (onClose) {
        onClose(event, 'backdropClick');
      }
    },
    [onClose],
  );

  return (
    <Dialog
      maxWidth={maxWidth}
      fullWidth
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h4">{title}</Typography>
          <IconButton
            size="small"
            onClick={handleClose}
          >
            <Close fontSize="small" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', px: 1, py: 1 }}>
          {actions}
        </Box>
      </DialogActions>
    </Dialog>
  );
}
