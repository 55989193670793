import { Card, CardContent, Divider, Grid, Typography } from '@mui/material';

interface DataDisplayGridCardProps {
  title: string;
  value?: string | number;
  xs?: number;
  sm?: number;
}
export function DataDisplayGridCard({
  title,
  value,
  xs = 12,
  sm = 4,
}: DataDisplayGridCardProps) {
  return (
    <Grid
      item
      xs={xs}
      sm={sm}
    >
      <Card>
        <CardContent>
          <Typography variant="h6">{title}</Typography>
          <Divider sx={{ opacity: 0.3, my: 2 }} />
          <Typography variant="h4">{value}</Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}
