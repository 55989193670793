import { Box, Stack, Button, Tabs, Tab } from '@mui/material';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSuperfeelUser } from 'use/superfeel-user';
import { useSnackbar } from 'use/snackbar';
import { CopyAll, OpenInNew, Publish } from '@mui/icons-material';
import { Loading, Switch, TabPanel } from 'components/atoms';
import { Call, ModelSettings } from 'components/organisms';
import { LoadingButton } from '@mui/lab';
import { useModelConfig } from 'use/model-config';

export default function UserModel() {
  const [loading, setLoading] = useState(true);
  const { modelConfigID } = useParams<{ modelConfigID: string }>();
  const { superfeelUser } = useSuperfeelUser();
  const { showSnackbar } = useSnackbar();
  const [tabs, setTabs] = useState<number>(0);
  const {
    deletingModelConfig,
    publish,
    publishing,
    modelConfig,
    changed,
    getModelConfig,
    updateModelConfigActiveStatus,
  } = useModelConfig();

  const handlePublish = () => {
    publish();
  };

  const loadData = useCallback(async () => {
    setLoading(true);
    await Promise.all([getModelConfig()]);
    setLoading(false);
  }, [getModelConfig]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleToggleModelState = () => {
    updateModelConfigActiveStatus(!modelConfig?.active);
  };

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setTabs(newValue);
  };

  const deploymentURL = `${process.env.REACT_APP_TWINS_URL}/${superfeelUser?.username}/meet/${modelConfigID}`;

  return (
    <Box>
      {loading ? (
        <Loading />
      ) : (
        <Box>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
            >
              <Switch
                checked={modelConfig?.active || false}
                handleChange={handleToggleModelState}
                label="Active"
              />
              {modelConfig?.active && (
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Button
                    startIcon={<OpenInNew />}
                    href={deploymentURL}
                    target="_blank"
                    size="small"
                    sx={{ fontWeight: 600 }}
                  >
                    Deployment
                  </Button>
                  <Button
                    size="small"
                    sx={{ fontWeight: 600 }}
                    startIcon={<CopyAll />}
                    onClick={() => {
                      navigator.clipboard.writeText(deploymentURL);
                      showSnackbar('Link copied to clipboard', 'success');
                    }}
                  >
                    Copy Link
                  </Button>
                </Stack>
              )}
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
            >
              <Call disabled={false} />
              <LoadingButton
                disabled={!changed || deletingModelConfig}
                loading={publishing}
                onClick={handlePublish}
                variant="contained"
                startIcon={<Publish />}
                size="small"
                sx={{ fontWeight: 600 }}
              >
                Publish
              </LoadingButton>
            </Stack>
          </Stack>
          <Box
            mt={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Tabs
                value={tabs}
                onChange={handleTabChange}
                aria-label="ideas bank tabs"
              >
                <Tab
                  label="Config"
                  id="tab-0"
                  aria-controls="tabpanel-0"
                  sx={{ fontSize: '13px' }}
                />
              </Tabs>
            </Box>
          </Box>
          <TabPanel
            value={tabs}
            index={0}
          >
            <ModelSettings />
          </TabPanel>
        </Box>
      )}
    </Box>
  );
}
