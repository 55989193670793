// pkg/frontend/app/src/routes/user/jobs/index.tsx
import { Box, Divider, Stack, Typography } from '@mui/material';
import { DataGrid, GridRowParams } from '@mui/x-data-grid';
import { useEffect } from 'react';

import { Loading } from 'components/atoms';
import { useColumns } from './columns';
import { Job } from '@twins/types';
import { useJob } from 'use/job';
import { useSuperfeelUser } from 'use/superfeel-user';
import { useNavigate } from 'react-router-dom';

export default function UserLogs() {
  const { job, jobLogs, jobs, getJobs, gettingJobs, setJob, reset } = useJob();
  const { superfeelUser } = useSuperfeelUser();
  const columns = useColumns();
  const navigate = useNavigate();

  useEffect(() => {
    if (job || jobLogs) {
      reset('jobLogs', 'job');
    }
  }, [job, jobLogs, reset]);

  useEffect(() => {
    getJobs();
  }, [getJobs]);
  const handleRowClick = (params: GridRowParams) => {
    setJob(params.row as Job);
    navigate(`/user/${superfeelUser?.username}/log/${params.row.id}`);
  };

  return (
    <Box>
      <Stack
        direction="column"
        spacing={1}
      >
        <Typography variant="h3">Logs</Typography>
        <Divider />
      </Stack>
      <Box
        mt={2}
        sx={{ height: 600, width: '100%' }}
      >
        {gettingJobs ? (
          <Loading />
        ) : (
          <DataGrid
            rows={jobs}
            columns={columns}
            autoPageSize
            pageSizeOptions={[10]}
            getRowId={(row: Job) => row.id}
            onRowClick={handleRowClick}
            sx={{
              '.centered-cell': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                fontSize: 'inherit',
              },
            }}
          />
        )}
      </Box>
    </Box>
  );
}
