import { ReactNode } from 'react';
import { RecoilRoot } from 'recoil';
import { UserContextProvider } from './user';
import { AppContextProvider } from './app';
import { SuperfeelUserContextProvider } from './superfeel-user';
import { SnackbarProvider } from './snackbar';
import { JobContextProvider } from './job';
import { FileContextProvider } from './file';
import { ModelConfigProvider } from './model-config';
import { VoiceContextProvider } from './voice';
import { PostContextProvider } from './post';
import { CallContextProvider } from './call';
import { PlayHTContextProvider } from './playht';

interface ContextProviderProps {
  children: ReactNode;
}

// TODO 🤔😭

function ContextProvider({ children }: ContextProviderProps): JSX.Element {
  return (
    <RecoilRoot>
      <AppContextProvider>
        <UserContextProvider>
          <SuperfeelUserContextProvider>
            <ModelConfigProvider>
              <JobContextProvider>
                <FileContextProvider>
                  <VoiceContextProvider>
                    <PostContextProvider>
                      <CallContextProvider>
                        <PlayHTContextProvider>
                          <SnackbarProvider>{children}</SnackbarProvider>
                        </PlayHTContextProvider>
                      </CallContextProvider>
                    </PostContextProvider>
                  </VoiceContextProvider>
                </FileContextProvider>
              </JobContextProvider>
            </ModelConfigProvider>
          </SuperfeelUserContextProvider>
        </UserContextProvider>
      </AppContextProvider>
    </RecoilRoot>
  );
}

export default ContextProvider;
