import { api as generatedApi } from 'services/generated';

export const collectionApi = generatedApi.enhanceEndpoints({
  addTagTypes: ['Voice'],
  endpoints: {
    TwinsGetVoice: {
      providesTags: ['Voice'],
      transformResponse: (res: any) => {
        return res;
      },
    },
    TwinsUpdateVoice: {
      invalidatesTags: ['Voice'],
      async onQueryStarted({ input }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          collectionApi.util.updateQueryData(
            'TwinsGetVoice',
            { input: { id: input.id } },
            (draft) => {
              const voiceToUpdate = draft.twinsGetVoice.data.find(
                (voice) => voice.id === input.id,
              );
              console.log('voiceToUpdate', voiceToUpdate);
              if (voiceToUpdate) {
                Object.assign(voiceToUpdate);
              }
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    },
    TwinsDeleteVoice: {
      invalidatesTags: ['Voice'],
    },
  },
});

export const {
  useTwinsGetVoiceQuery,
  useTwinsUpdateVoiceMutation,
  useTwinsDeleteVoiceMutation,
} = collectionApi;
