import React from 'react';
import { Typography, Box } from '@mui/material';

interface SubSectionTitleProps {
  children: React.ReactNode;
}

const SubSectionTitle: React.FC<SubSectionTitleProps> = ({ children }) => (
  <Box my={2}>
    <Typography>{children}</Typography>
  </Box>
);

export default SubSectionTitle;
