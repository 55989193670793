import { useCallback } from 'react';
import { useSnackbar } from 'use/snackbar';
import { useSuperfeelUser } from 'use/superfeel-user';
import { usePlayHTContext } from './state';
import { useUser } from 'use/user';
import { useVoice } from 'use/voice';
import { GraphQLResult } from 'aws-amplify/api';
import { executeGraphqlOperation } from 'api';
import { twinsGenerateSampleMutation } from 'graphql/mutations';
import { useFile } from 'use/file';

export const usePlayHTAPI = () => {
  const { voice, updateVoiceBase } = useVoice();
  const { setGenerating, sampleText } = usePlayHTContext();
  const { getJWT } = useUser();
  const { superfeelUser } = useSuperfeelUser();
  const { showSnackbar } = useSnackbar();
  const { getFile } = useFile();

  const generateSample = useCallback(
    async (text?: string) => {
      setGenerating(true);
      try {
        const input = {
          text: text || sampleText,
          voiceID: voice?.id,
          userID: superfeelUser?.userId,
        };
        const jwt = await getJWT();
        const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
          twinsGenerateSampleMutation,
          { input },
          jwt,
        );
        console.log('updateFileBase res', data);
        if (data) {
          const twinsGenerateSampleResponse = data as {
            twinsGenerateSample: {
              success: boolean;
              message: string;
              fileID: string;
            };
          };
          if (!twinsGenerateSampleResponse.twinsGenerateSample.success) {
            showSnackbar(
              `Update file error: ${twinsGenerateSampleResponse.twinsGenerateSample.message}`,
              'error',
            );
          } else {
            updateVoiceBase({
              ...voice,
              sampleID: twinsGenerateSampleResponse.twinsGenerateSample.fileID,
            });
            getFile(twinsGenerateSampleResponse.twinsGenerateSample.fileID);
            showSnackbar('Sample generated', 'success');
          }
        }
      } catch (error) {
        showSnackbar(`Error updating file: ${error as string}`, 'error');
      } finally {
        setGenerating(false);
      }
    },
    [
      setGenerating,
      sampleText,
      voice,
      superfeelUser?.userId,
      getJWT,
      showSnackbar,
      updateVoiceBase,
      getFile,
    ],
  );

  return { generateSample };
};
