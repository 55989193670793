import React from 'react';
import { Box, Card, CardContent, CardActions, Divider } from '@mui/material';

interface PreviewCardProps {
  header: React.ReactNode;
  indicator?: React.ReactNode;
  content?: React.ReactNode;
  actions: React.ReactNode;
}

export function PreviewCard({
  header,
  content,
  indicator,
  actions,
}: PreviewCardProps) {
  return (
    <Box my={2}>
      <Card elevation={1}>
        {header}
        {indicator}
        {content ? <CardContent>{content}</CardContent> : null}
        <Divider sx={{ mb: 1 }} />
        <CardActions sx={{ justifyContent: 'space-between' }}>
          {actions}
        </CardActions>
      </Card>
    </Box>
  );
}
