import { useEffect, useState, useCallback, useMemo } from 'react';
import { Box, Checkbox, Stack, Typography } from '@mui/material';
import {
  CheckboxWithLabel,
  SectionTitle,
  SubSectionTitle,
  VoiceSample,
} from 'components/atoms';
import { useFile } from 'use/file';
import { useVoice } from 'use/voice';
import { FileMedallion, SortDirection } from 'types/enums';
import {
  useTwinsGetFileQuery,
  useTwinsUpdateFileMutation,
} from 'services/collection.services.ts/file';
import { Voice } from '@twins/types';
import { useSnackbar } from 'use/snackbar';
import { useUser } from 'use/user';
import { useSuperfeelUser } from 'use/superfeel-user';

interface VoiceReviewProps {
  voice: Voice;
}

export default function VoiceReview({ voice }: VoiceReviewProps) {
  const { showSnackbar } = useSnackbar();
  const { user } = useUser();
  const { superfeelUser } = useSuperfeelUser();
  const { updateVoiceBase } = useVoice();
  const { updateFileBase } = useFile();

  const [selectAll, setSelectAll] = useState(false);
  const [selectingAll, setSelectingAll] = useState(false);

  const { data: fileData } = useTwinsGetFileQuery({
    input: {
      collectionID: voice.collectionID,
      medallion: FileMedallion.gold,
      sort: SortDirection.ASC,
    },
  });
  const files = useMemo(
    () => fileData?.twinsGetFile?.data ?? [],
    [fileData?.twinsGetFile?.data],
  );

  const [updateFile] = useTwinsUpdateFileMutation();

  const handleSelectAllChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const isSelected = event.target.checked;
      try {
        setSelectingAll(true);
        setSelectAll(isSelected);
        if (files) {
          const updatedFiles = files.map((file) => {
            const { createdAt, updatedAt, ...fileWithoutDates } = file;
            return {
              ...fileWithoutDates,
              username: superfeelUser?.username,
              userID: user?.id,
              createdBy: user?.id,
              selected: isSelected,
            };
          });

          try {
            await Promise.all(
              updatedFiles.map((file) => updateFile({ input: file })),
            );
          } catch (error) {
            console.error('Error updating files:', error);
            setSelectAll(!isSelected);
          }
        }
        updateVoiceBase({ ...voice, selectAll: isSelected });
      } catch (error) {
        console.error('Error updating file:', error);
      } finally {
        setSelectingAll(false);
      }
    },
    [
      files,
      superfeelUser?.username,
      updateFile,
      updateVoiceBase,
      user?.id,
      voice,
    ],
  );

  const handleSelectFile = useCallback(
    async (fileId: string, isSelected: boolean) => {
      if (!files) return;
      const file = files.find((file) => file.id === fileId);
      if (!file) return;

      const { createdAt, updatedAt, ...fileWithoutDates } = file;
      const updatedFile = {
        ...fileWithoutDates,
        selected: isSelected,
        username: superfeelUser?.username,
        userID: user?.id,
        createdBy: user?.id,
      };

      try {
        await updateFileBase(updatedFile);
        const result = await updateFile({ input: updatedFile }).unwrap();
        if (!result?.twinsUpdateFile?.success) {
          showSnackbar('Could not update file', 'error');
        } else {
          const allSelected = files
            .map((f) => (f.id === fileId ? { ...f, ...updatedFile } : f))
            .every((f) => f.selected);
          setSelectAll(allSelected);
        }
      } catch (error) {
        console.error('Error updating file:', error);
        showSnackbar('Error updating file', 'error');
      }
    },
    [
      files,
      showSnackbar,
      superfeelUser?.username,
      updateFile,
      updateFileBase,
      user?.id,
    ],
  );

  useEffect(() => {
    if (files) {
      const allSelected = files.every((file) => file.selected);
      setSelectAll(allSelected);
    }
  }, [files]);

  return (
    <Box sx={{ paddingBottom: 12 }}>
      <SectionTitle>Voice Samples Review</SectionTitle>
      <SubSectionTitle>Samples</SubSectionTitle>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Typography
          color="grey"
          sx={{ p: 0, mt: 0, fontWeight: 600 }}
          variant="h4"
        >
          {files?.length ?? 0}
        </Typography>
        <Box>
          <CheckboxWithLabel
            label="Select All"
            checked={selectingAll || selectAll}
            onChange={handleSelectAllChange}
            labelPlacement="start"
          />
        </Box>
      </Stack>
      <Box>
        {files?.map((file) => (
          <VoiceSample
            key={file?.id}
            file={file}
            action={
              <Checkbox
                checked={file?.selected ?? false}
                onChange={(event) =>
                  handleSelectFile(file?.id ?? '', event.target.checked)
                }
              />
            }
            sample={{
              speaker: '1',
              transcriptURI: '',
              audioURI: file?.key,
            }}
          />
        ))}
      </Box>
    </Box>
  );
}
