export const twinsUpdatePromptMutation = `
mutation TwinsUpdatePrompt($input: TwinsUpdatePromptInput!) {
  twinsUpdatePrompt(input: $input) {
      success
      message
    }
  }`;

export const twinsUpdateDataSourceMutation = `
mutation TwinsUpdateDataSource($input: TwinsUpdateDataSourceInput!) {
  twinsUpdateDataSource(input: $input) {
      success
      message
    }
  }`;

export const twinsUpdateModelMutation = `
mutation TwinsUpdateModel($input: TwinsUpdateModelInput!) {
  twinsUpdateModel(input: $input) {
        success
        message
      }
    }`;

export const twinsUpdateModelConfigMutation = `
mutation TwinsUpdateModelConfig($input: TwinsUpdateModelConfigInput!) {
  twinsUpdateModelConfig(input: $input) {
      success
      message
    }
  }
`;

export const twinsUpdateCollectionMutation = `
mutation TwinsUpdateCollection($input: TwinsUpdateCollectionInput!) {
  twinsUpdateCollection(input: $input) {
      success
      message
    }
  }`;

export const twinsUpdateFileMutation = `
mutation TwinsUpdateFile($input: TwinsUpdateFileInput!) {
  twinsUpdateFile(input: $input) {
      success
      message
    }
  }`;

export const twinsUpdateJobMutation = `
mutation TwinsUpdateJob($input: TwinsUpdateJobInput!) {
  twinsUpdateJob(input: $input) {
      success
      message
    }
  }`;

export const twinsUpdateVoiceMutation = `
mutation TwinsUpdateVoice($input: TwinsUpdateVoiceInput!) {
  twinsUpdateVoice(input: $input) {
      success
      message
    }
  }
`;

export const twinsDeleteModelConfigMutation = `
mutation TwinsDeleteModelConfig($input: TwinsDeleteModelConfigInput!) {
  twinsDeleteModelConfig(input: $input) {
      success
      message
    }
  }
`;
export const twinsUpdateMemoryMutation = `
mutation TwinsUpdateMemory($input: TwinsUpdateMemoryInput!) {
  twinsUpdateMemory(input: $input) {
      success
      message
    }
  }`;

export const twinsUpdateDocumentMutation = `
mutation TwinsUpdateDocument($input: TwinsUpdateDocumentInput!) {
  twinsUpdateDocument(input: $input) {
      success
      message
    }
  }`;

export const twinsDeleteDocumentMutation = `
  mutation TwinsDeleteDocument($input: TwinsDeleteDocumentInput!) {
      twinsDeleteDocument(input: $input) {
          success
          message
        }
      }`;

export const twinsUpdateSpeakerIDMutation = `
mutation TwinsUpdateSpeakerID($input: TwinsUpdateSpeakerIDInput!) {
  twinsUpdateSpeakerID(input: $input) {
      success
      message
    }
  }`;

export const twinsDeleteFileMutation = `
  mutation TwinsDeleteFile($input: TwinsDeleteFileInput!) {
      twinsDeleteFile(input: $input) {
          success
          message
        }
      }`;

export const twinsSetPostsLocalContextMutation = `
mutation TwinsSetPostsLocalContext($input: TwinsSetPostsLocalContextInput!) {
  twinsSetPostsLocalContext(input: $input) {
      success
      message
    }
  }`;

export const twinsDeleteVoiceMutation = `
mutation TwinsDeleteVoice($input: TwinsDeleteVoiceInput!) {
  twinsDeleteVoice(input: $input) {
      success
      message
    }
  }
`;

export const twinsProgressVoiceMutation = `
mutation TwinsProgressVoice($input: TwinsProgressVoiceInput!) {
  twinsProgressVoice(input: $input) {
      success
      message
    }
  }`;

export const twinsDeleteCollectionMutation = `
mutation TwinsDeleteCollection($input: TwinsDeleteCollectionInput!) {
  twinsDeleteCollection(input: $input) {
      success
      message
    }
  }`;

export const twinsGenerateSampleMutation = `
  mutation TwinsGenerateSample($input: TwinsGenerateSampleInput!) {
    twinsGenerateSample(input: $input) {
        success
        message
        fileID
      }
    }
  `;

export const twinsUpdateJobLogMutation = `
mutation TwinsUpdateJobLog($input: TwinsUpdateJobLogInput!) {
  twinsUpdateJobLog(input: $input) {
      success
      message
    }
  }`;
