import { useCallback, useState } from 'react';
import { executeGraphqlOperation } from 'api';
import { Memory, Model, ModelConfig, Prompt } from '@twins/types';
import { useSnackbar } from 'use/snackbar';
import {
  twinsUpdateMemoryMutation,
  twinsUpdateModelMutation,
  twinsUpdateModelConfigMutation,
  twinsUpdatePromptMutation,
  twinsDeleteModelConfigMutation,
} from 'graphql/mutations';
import { GraphQLResult } from 'aws-amplify/api';
import { twinsGetModelConfigQuery } from 'graphql/queries';
import { useNavigate, useParams } from 'react-router-dom';
import { useSuperfeelUser } from 'use/superfeel-user';
import { useUser } from 'use/user';
import { useModelConfigContext } from './state';

export const useModelConfigAPI = () => {
  const [publishing, setPublishing] = useState(false);
  const {
    memory,
    models,
    modelConfig,
    setModelConfig,
    prompts,
    setChanged,
    setDeletingModelConfig,
  } = useModelConfigContext();
  const { modelConfigID } = useParams<{ modelConfigID: string }>();
  const { superfeelUser } = useSuperfeelUser();
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { getJWT } = useUser();

  const updateModelConfigActiveStatus = async (active: boolean) => {
    if (!modelConfig) return;
    console.log('running');
    try {
      setSaving(true);
      const input = {
        id: modelConfig.id || modelConfigID,
        name: modelConfig.name,
        description: modelConfig.description,
        username: superfeelUser?.username,
        userID: superfeelUser?.userId,
        active,
      };
      const jwt = await getJWT();
      const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
        twinsUpdateModelConfigMutation,
        { input },
        jwt,
      );
      console.log('data', data);
      if (data) {
        const modelConfigResult = data as {
          twinsUpdateModelConfig: {
            success: boolean;
            message: string;
          };
        };
        if (modelConfigResult.twinsUpdateModelConfig.success) {
          setModelConfig((prev) => prev && { ...prev, active });
          showSnackbar('Updated', 'success');
        } else {
          showSnackbar(
            `Failed to update model config: ${modelConfigResult.twinsUpdateModelConfig.message}`,
            'error',
          );
        }
      }
    } catch (e) {
      showSnackbar(`An error occurred: ${e}`, 'error');
    } finally {
      setSaving(false);
    }
  };

  const deleteModelConfig = useCallback(async () => {
    try {
      setDeletingModelConfig(true);
      const modelConfigInput = {
        id: modelConfig?.id,
      };
      const jwt = await getJWT();
      const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
        twinsDeleteModelConfigMutation,
        { input: modelConfigInput },
        jwt,
      );
      if (data) {
        const modelConfigResult = data as {
          twinsDeleteModelConfig: {
            success: boolean;
            message: string;
          };
        };
        if (modelConfigResult.twinsDeleteModelConfig.success) {
          navigate(`/user/${superfeelUser?.username}/twin`);
          showSnackbar('Deleted', 'success');
        }
        return;
      }
      throw new Error('Failed to delete model config');
    } catch (e) {
      showSnackbar(`Unknown error occurred: ${e as string}`, 'error');
    } finally {
      setDeletingModelConfig(false);
    }
  }, [
    setDeletingModelConfig,
    modelConfig?.id,
    getJWT,
    navigate,
    superfeelUser?.username,
    showSnackbar,
  ]);

  const getModelConfig = useCallback(async () => {
    const input = { id: modelConfigID };
    const jwt = await getJWT();
    const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
      twinsGetModelConfigQuery,
      { input },
      jwt,
    );
    if (data) {
      const result = data as {
        twinsGetModelConfig: {
          data: ModelConfig[];
        };
      };
      setModelConfig(
        result.twinsGetModelConfig.data && result.twinsGetModelConfig.data[0],
      );
    }
  }, [modelConfigID, getJWT, setModelConfig]);

  const publishMemory = async () => {
    try {
      const jwt = await getJWT();
      const memoryPromises = memory.map((m: Memory) => {
        return executeGraphqlOperation(
          twinsUpdateMemoryMutation,
          { input: m },
          jwt,
        );
      });
      await Promise.all(memoryPromises);
    } catch (e) {
      showSnackbar(`Unknown error occurred: ${e as string}`, 'error');
    }
  };

  const publishModels = async () => {
    try {
      const jwt = await getJWT();
      const modelPromises = models.map((m: Model) => {
        return executeGraphqlOperation(
          twinsUpdateModelMutation,
          { input: m },
          jwt,
        );
      });
      const modelConfigPromise = executeGraphqlOperation(
        twinsUpdateModelConfigMutation,
        { input: modelConfig },
        jwt,
      );
      const allPromises = [...modelPromises, modelConfigPromise];
      await Promise.all(allPromises);
    } catch (e) {
      showSnackbar(`Unknown error occurred: ${e as string}`, 'error');
    }
  };

  const publishPrompts = async () => {
    try {
      const jwt = await getJWT();
      const promises = prompts.map((p: Prompt) => {
        return executeGraphqlOperation(
          twinsUpdatePromptMutation,
          { input: p },
          jwt,
        );
      });
      const res = await Promise.all(promises);
      console.log('res', res);
    } catch (e) {
      showSnackbar(`Unknown error occurred: ${e as string}`, 'error');
    }
  };

  const publish = async () => {
    try {
      setPublishing(true);
      await Promise.all([publishModels(), publishPrompts(), publishMemory()]);
      setChanged(false);
      showSnackbar('Published', 'success');
    } catch (e) {
      showSnackbar(`Unknown error occurred: ${e as string}`, 'error');
    } finally {
      setPublishing(false);
    }
  };

  return {
    updateModelConfigActiveStatus,
    deleteModelConfig,
    getModelConfig,
    publishPrompts,
    publishMemory,
    publishModels,
    publishing,
    publish,
    saving,
  };
};
