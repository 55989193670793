import { usePlayHTAPI } from './api';
import { PlayHTContextProvider, usePlayHTContext } from './state';

export const usePlayHT = () => {
  const context = usePlayHTContext();
  const api = usePlayHTAPI();

  return {
    ...context,
    ...api,
  };
};

export { PlayHTContextProvider };
