import { useState } from 'react';
import { Box, Stack } from '@mui/material';
import { Button as CustomButton } from 'components/atoms';
import { ButtonIconTooltip, UserStatus } from 'components/atoms';
import { useSuperfeelUser } from 'use/superfeel-user';
import { useLocation, useNavigate, matchPath } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useVoice } from 'use/voice';
import { useFile } from 'use/file';
import { useModelConfig } from 'use/model-config';
import { useCall } from 'use/call';
import { CollectionType } from 'types/enums';
import {
  useTwinsDeleteCollectionMutation,
  useTwinsUpdateCollectionMutation,
} from 'services/collection.services.ts/collection';
import { Collection } from '@twins/types';
import { useSnackbar } from 'use/snackbar';
import { useUser } from 'use/user';
import { Modal } from 'components/organisms/modal';
import { DeleteOutlineRounded } from '@mui/icons-material';
import { useTwinsDeleteFileMutation } from 'services/generated';

export default function EndAction() {
  const navigate = useNavigate();
  const { superfeelUser } = useSuperfeelUser();
  const { deletingVoice, handleDeleteVoice } = useVoice();
  const { deletingFile, deletingCollection } = useFile();
  const { deleteModelConfig, modelConfig, deletingModelConfig } =
    useModelConfig();
  const { callActive } = useCall();

  const location = useLocation();
  const [updateCollection, { isLoading: isCreatingCollection }] =
    useTwinsUpdateCollectionMutation();
  const [deleteCollection, { isLoading: isDeletingCollection }] =
    useTwinsDeleteCollectionMutation();
  const [deleteFile, { isLoading: isDeletingFile }] =
    useTwinsDeleteFileMutation();
  const { showSnackbar } = useSnackbar();
  const { user } = useUser();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteType, setDeleteType] = useState<
    'file' | 'collection' | 'voice' | 'twin' | null
  >(null);

  const openDeleteModal = (type: 'file' | 'collection' | 'voice' | 'twin') => {
    setDeleteType(type);
    setIsModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsModalOpen(false);
    setDeleteType(null);
  };

  const handleDelete = async () => {
    try {
      switch (deleteType) {
        case 'file':
          await handleDeleteFile();
          break;
        case 'collection':
          await handleDeleteCollection();
          break;
        case 'voice':
          const fileMatch = matchPath(
            '/user/:username/voice/:voiceID',
            location.pathname,
          );
          await handleDeleteVoice(fileMatch.params.voiceID);
          break;
        case 'twin':
          await handleDeleteModelConfig();
          break;
      }
      closeDeleteModal();
    } catch (error) {
      console.error(error);
      showSnackbar('Failed to delete. Please try again later.', 'error');
    }
  };

  const handleDeleteFile = async () => {
    const fileMatch = matchPath(
      '/user/:username/data/collection/:collectionID/file/:fileID',
      location.pathname,
    );
    if (fileMatch) {
      await deleteFile({
        input: { id: fileMatch.params.fileID },
      }).unwrap();
      navigate(
        `/user/${superfeelUser?.username}/data/collection/${fileMatch.params.collectionID}`,
      );
    }
  };

  const handleDeleteCollection = async () => {
    const collectionMatch = matchPath(
      '/user/:username/data/collection/:collectionID',
      location.pathname,
    );
    if (collectionMatch) {
      const result = await deleteCollection({
        input: { collectionID: collectionMatch.params.collectionID },
      }).unwrap();
      console.log('result', result);
      navigate(`/user/${superfeelUser?.username}/data/collection`);
    }
  };

  const handleDeleteModelConfig = async () => {
    await deleteModelConfig();
    navigate(`/user/${superfeelUser?.username}/models`);
  };

  const shouldRenderUploadButton = (): boolean => {
    return !!matchPath('/user/:username/jobs', location.pathname);
  };

  const shouldRenderBadge = (): boolean => {
    return (
      !!matchPath('/user/:username', location.pathname) &&
      superfeelUser !== null &&
      !location.pathname.includes('/data') &&
      !location.pathname.includes('/jobs') &&
      !location.pathname.includes('/models') &&
      !location.pathname.includes('/new')
    );
  };

  const shouldRenderFileMenu = (): boolean => {
    return !!matchPath(
      '/user/:username/data/collection/:collectionID/file/:fileID',
      location.pathname,
    );
  };

  const shouldRenderCollectionMenu = (): boolean => {
    return !!matchPath(
      '/user/:username/data/collection/:collectionID',
      location.pathname,
    );
  };

  const shouldRenderDataMenu = (): boolean => {
    return !!matchPath('/user/:username/data/collection', location.pathname);
  };

  const shouldRenderVoiceMenu = (): boolean => {
    const fileMatch = matchPath(
      '/user/:username/voice/:voiceID',
      location.pathname,
    );
    return (
      !!matchPath('/user/:username/voice/:voiceID', location.pathname) &&
      !!fileMatch?.params.voiceID
    );
  };

  const shouldRenderTwinDeleteMenu = (): boolean => {
    const fileMatch = matchPath(
      '/user/:username/twin/:modelConfigID',
      location.pathname,
    );
    return (
      !!matchPath('/user/:username/twin/:modelConfigID', location.pathname) &&
      fileMatch.params.modelConfigID &&
      modelConfig?.active === false
    );
  };

  const handleCreateCollection = async () => {
    try {
      const collectionID = uuid();
      const input: Collection = {
        id: collectionID,
        name: 'New Collection',
        type: CollectionType.interview,
        userID: superfeelUser?.userId,
        username: superfeelUser?.username,
        createdBy: user.id,
      };
      const result = await updateCollection({ input }).unwrap();
      if (result?.twinsUpdateCollection?.success) {
        showSnackbar('Collection created successfully', 'success');
        navigate(
          `/user/${superfeelUser?.username}/data/collection/${collectionID}`,
        );
      }
    } catch (error) {
      console.error(error);
      showSnackbar(
        'Failed to create new collection. Please try again later.',
        'error',
      );
    }
  };

  return (
    <Box>
      <Modal
        isOpen={isModalOpen}
        onClose={closeDeleteModal}
        title="Delete Confirmation"
        width={480}
        actions={
          <Stack
            width="100%"
            direction="row"
            justifyContent="space-between"
          >
            <CustomButton
              startIcon={<DeleteOutlineRounded />}
              variant="contained"
              color="error"
              label="Delete"
              onClick={handleDelete}
              disabled={
                deletingFile ||
                deletingCollection ||
                deletingVoice ||
                deletingModelConfig
              }
              loading={
                deletingFile ||
                deletingCollection ||
                deletingVoice ||
                deletingModelConfig ||
                isDeletingFile ||
                isDeletingCollection
              }
              width={100}
            />
          </Stack>
        }
      >
        Are you sure you want to delete this {deleteType}? <br />
        This action is irreversible.
      </Modal>
      {shouldRenderBadge() && (
        <UserStatus
          username={superfeelUser?.username || ''}
          showFlag
        />
      )}
      {shouldRenderUploadButton() && (
        <CustomButton
          variant="contained"
          onClick={() =>
            navigate(
              `/user/${superfeelUser?.username}/data/collection/${uuid()}`,
            )
          }
          label="Upload"
        />
      )}
      {shouldRenderDataMenu() && (
        <ButtonIconTooltip
          onClick={handleCreateCollection}
          isLoading={isCreatingCollection}
          tooltipTitle="Create Collection"
          icon="add"
          tooltipColorVariant="info"
        />
      )}
      {shouldRenderFileMenu() && (
        <ButtonIconTooltip
          onClick={() => openDeleteModal('file')}
          isLoading={false}
          tooltipTitle="Delete File"
          icon="close"
          tooltipColorVariant="error"
        />
      )}
      {shouldRenderCollectionMenu() && (
        <ButtonIconTooltip
          onClick={() => openDeleteModal('collection')}
          isLoading={false}
          tooltipTitle="Delete Collection"
          icon="close"
          tooltipColorVariant="error"
        />
      )}
      {shouldRenderVoiceMenu() && (
        <ButtonIconTooltip
          onClick={() => openDeleteModal('voice')}
          isLoading={deletingVoice}
          tooltipTitle="Delete Voice"
          icon="close"
          tooltipColorVariant="error"
        />
      )}
      {shouldRenderTwinDeleteMenu() && (
        <ButtonIconTooltip
          onClick={() => openDeleteModal('twin')}
          isLoading={deletingModelConfig}
          isDisabled={callActive}
          tooltipTitle="Delete Twin"
          icon="close"
          tooltipColorVariant="error"
        />
      )}
    </Box>
  );
}
