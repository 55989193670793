import { Container, Paper } from '@mui/material';

interface BottomRowProps {
  information: React.ReactNode;
  action: React.ReactNode;
}

export default function BottomRow({ information, action }: BottomRowProps) {
  return (
    <Paper
      elevation={0}
      sx={{
        zIndex: 1000,
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 2,
        pl: 30,
        boxShadow: '0px -10px 10px 0px rgb(0 0 0 / 25%)',
        borderTop: '1px solid rgba(255, 255, 255, 0.1)',
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {information}
        {action}
      </Container>
    </Paper>
  );
}
