import { Alert, AlertTitle, Box, Grid } from '@mui/material';
import {
  DataDisplayGridCard,
  SectionTitle,
  SubSectionTitle,
} from 'components/atoms';
import { useEffect } from 'react';
import { Status } from 'types/enums';
import { useVoice } from 'use/voice';

export default function VoiceClean() {
  const { voice, preview, getJobPreview } = useVoice();

  useEffect(() => {
    if (voice?.id) {
      getJobPreview();
    }
  }, [voice?.id, getJobPreview]);

  return (
    <Box>
      <SectionTitle>Voice Dataset Selection & Cleaning</SectionTitle>
      {voice?.status !== Status.complete && (
        <Box my={1}>
          <Alert severity="info">
            <AlertTitle>Cleaning in progress ...</AlertTitle>
            Processing usually takes around 5 minutes.
          </Alert>
        </Box>
      )}
      <SubSectionTitle>Summary</SubSectionTitle>
      <Box>
        <Grid
          container
          spacing={2}
        >
          <DataDisplayGridCard
            title="Min Transcript Length"
            value={voice?.transcriptMinChars || 200}
          />
          <DataDisplayGridCard
            title="Max Transcript Length"
            value={voice?.transcriptMaxChars || 200}
          />
          <DataDisplayGridCard
            title="Transcript Language"
            value={voice?.transcriptLanguage || 'en'}
          />
        </Grid>
      </Box>
      <SubSectionTitle>Estimated Dataset Size</SubSectionTitle>
      <Box>
        <Grid
          container
          spacing={2}
        >
          <DataDisplayGridCard
            title="Feels"
            value={preview?.totalCount || 0}
          />
        </Grid>
      </Box>
    </Box>
  );
}
