import { getSignedUploadUrl } from 'api/getSignedUploadUrl';

export async function initiateMultipartUpload(
  bucketName: string,
  key: string,
): Promise<string> {
  const response = await getSignedUploadUrl({
    key,
    bucketName,
    method: 'POST',
    type: 'INTERVIEW',
    expiry: 60 * 60 * 24,
  });

  const { uploadId } = response?.data?.twinsGetSignedUrl;
  if (!uploadId) {
    throw new Error(
      'Failed to initiate multipart upload: No upload ID returned',
    );
  }

  return uploadId;
}
