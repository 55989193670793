import { Tabs as BaseTabs, TabsOwnProps } from '@mui/base/Tabs';
import styled from '@emotion/styled';

type TabType = string | number | null | undefined;
interface ModelTabsProps<T extends TabType> extends TabsOwnProps {
  defaultValue: T;
}

const Tabs = styled(BaseTabs)<ModelTabsProps<TabType>>();

export default Tabs;
