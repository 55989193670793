/*
  30-min expiry (exp) so rooms won't linger too long.
  See other available options at https://docs.daily.co/reference#create-room
 */
async function createRoom() {
  const exp = Math.round(Date.now() / 1000) + 60 * 30;
  const options = {
    properties: {
      exp,
      eject_at_room_exp: true,
    },
  };

  const endpoint = 'https://api.daily.co/v1/rooms/';

  const headers = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_DAILY_API_KEY}`,
    },
  };

  const response = await fetch(endpoint, {
    method: 'POST',
    body: JSON.stringify(options),
    ...headers,
  });

  return response.json();
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { createRoom };
