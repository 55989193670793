import { TableCell, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

interface TableHeaderCellProps {
  children: React.ReactNode;
}

function TableHeaderCell({ children }: TableHeaderCellProps) {
  return (
    <TableCell style={{ borderBottom: '1px solid #4e4e4e' }}>
      <Typography
        variant="h6"
        textTransform="uppercase"
        fontSize={12}
        fontWeight={600}
        color={grey[500]}
      >
        {children}
      </Typography>
    </TableCell>
  );
}

export default TableHeaderCell;
