import { UserContextProvider, useUserContext } from './state';

export const useUser = () => {
  const context = useUserContext();

  return {
    ...context,
  };
};

export { UserContextProvider };
