import { useSuperfeelUserContext, SuperfeelUserContextProvider } from './state';

export const useSuperfeelUser = () => {
  const context = useSuperfeelUserContext();

  return {
    ...context,
  };
};

export { SuperfeelUserContextProvider };
