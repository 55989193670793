import { Skeleton, Stack, Typography } from '@mui/material';
import { useSuperfeelUser } from 'use/superfeel-user';
import { useLocation } from 'react-router-dom';

export default function UserTitle() {
  const { superfeelUser } = useSuperfeelUser();
  const location = useLocation();

  if (location.pathname === '/') {
    return <Typography variant="h4">Users</Typography>;
  }

  if (location.pathname.includes('/twins')) {
    return <Typography variant="h4">Twins</Typography>;
  }

  if (location.pathname.includes('/user/')) {
    if (
      !superfeelUser?.firstName &&
      !superfeelUser?.username &&
      !superfeelUser?.id
    ) {
      return (
        <Stack
          direction="row"
          spacing={2}
        >
          <Skeleton
            height="40px"
            width="50px"
          />
          <Skeleton
            height="40px"
            width="80px"
          />
        </Stack>
      );
    }

    const userTitle =
      superfeelUser?.firstName && superfeelUser?.lastName
        ? `${superfeelUser.firstName} ${superfeelUser.lastName}`
        : superfeelUser?.username || superfeelUser?.id || null;

    return userTitle ? <Typography variant="h4">{userTitle}</Typography> : null;
  }

  if (location.pathname.includes('/invite')) {
    return <Typography variant="h4">Invite users to Superfeel</Typography>;
  }

  return <Typography variant="h4">Settings</Typography>;
}
