// pkg/frontend/app/src/layouts/index.tsx

import { Box, Container } from '@mui/material';
import React, { useEffect } from 'react';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { useUser } from 'use/user';
import { useLocation } from 'react-router-dom';
import { useModelConfig } from 'use/model-config';
import MiniDrawer from '../components/organisms/drawer';

export default function Layout({
  children,
  appBarAction,
}: {
  children: React.ReactNode;
  appBarAction?: React.ReactNode;
}) {
  const { setUser } = useUser();
  const { reset } = useModelConfig();
  const location = useLocation();
  useEffect(() => {
    const getUserData = async () => {
      try {
        const userAttributes = await fetchUserAttributes();
        setUser({
          id: userAttributes.sub,
          email: userAttributes.email,
          name: userAttributes.name,
        });
      } catch (err) {
        console.log('error getting user...', err);
      }
    };
    getUserData();
  }, [setUser]);

  useEffect(() => {
    if (!location.pathname.includes('twin/')) {
      reset();
    }
  }, [location.pathname, reset]);
  return (
    <Box>
      <MiniDrawer appBarAction={appBarAction}>
        <Container>{children}</Container>
      </MiniDrawer>
    </Box>
  );
}
