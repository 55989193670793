import { Start } from '@mui/icons-material';
import { ButtonTooltip } from 'components/atoms';

interface PreviewOpenButtonProps {
  tooltip: string;
  onClick: () => void;
  action: boolean;
  disabled?: boolean;
}

export function PreviewOpenButton({
  tooltip,
  onClick,
  action,
  disabled,
}: PreviewOpenButtonProps) {
  return (
    <ButtonTooltip
      disabled={disabled}
      title={action ? 'Action Required' : tooltip}
      placement="top"
      tooltipColorVariant="info"
      color={action ? 'info' : 'primary'}
      variant="outlined"
      sx={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}
      size="small"
      endIcon={<Start fontSize="small" />}
      onClick={onClick}
    >
      Open
    </ButtonTooltip>
  );
}
