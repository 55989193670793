import { Stack, Typography } from '@mui/material';

interface FilePreviewMetadataProps {
  label: string;
  value: string;
}

export default function FilePreviewMetadata({
  label,
  value,
}: FilePreviewMetadataProps) {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
    >
      <Typography
        color="grey"
        sx={{
          textTransform: 'uppercase',
          fontSize: '0.6rem',
          lineHeight: 0,
          fontWeight: 600,
        }}
        variant="caption"
      >
        {label}
      </Typography>
      <Typography
        color="grey"
        sx={{ p: 0, mt: 0, fontWeight: 600 }}
        variant="h4"
      >
        {value}
      </Typography>
    </Stack>
  );
}
