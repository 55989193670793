import { DateTime } from 'luxon';
import { AIProvider, FileStage, VoiceStep } from 'types/enums';

export const formatDate = (dateString?: string, format?: string) => {
  return DateTime.fromISO(dateString!).toFormat(
    format || 'dd-MMM-yy - HH:mm:ss',
  );
};

export function truncateText(text: string, maxWords: number): string {
  const words = text?.split(' ');
  if (words?.length > maxWords) {
    return `${words?.slice(0, maxWords).join(' ')} ...`;
  }
  return text;
}

export const formatFileSize = (size: number) => {
  if (size >= 1073741824) {
    return `${(size / 1073741824).toFixed(2)} GB`;
  }
  if (size >= 1048576) {
    return `${(size / 1048576).toFixed(2)} MB`;
  }
  return `${(size / 1024).toFixed(2)} KB`;
};

export const roundToTwoDecimals = (num: number): number => {
  return parseFloat(num.toFixed(2));
};

export const enumToObject = (
  enumObj: Record<string, string>,
): Record<string, string> => {
  return Object.keys(enumObj)
    .filter((key) => typeof enumObj[key] === 'string')
    .reduce(
      (acc, key) => {
        acc[key] = enumObj[key];
        return acc;
      },
      {} as Record<string, string>,
    );
};

export const menuOptions = (options: string[]) =>
  options.map((value) => ({
    value,
    label: value,
  }));

export const parseAwsDateTime = (
  awsDateTime: string | null,
): DateTime | null => {
  return awsDateTime ? DateTime.fromISO(awsDateTime, { zone: 'utc' }) : null;
};

export const getFileStepIndex = (stage?: FileStage): number => {
  switch (stage?.toLowerCase()) {
    case FileStage.upload:
      return 0;
    case FileStage.process:
      return 1;
    case FileStage.review:
      return 2;
    case FileStage.submit:
      return 3;
    case FileStage.complete:
      return 4;
    default:
      return 0;
  }
};

export const getVoiceStepindex = (step?: VoiceStep): number => {
  switch (step?.toLowerCase()) {
    case VoiceStep.select:
      return 0;
    case VoiceStep.clean:
      return 1;
    case VoiceStep.review:
      return 2;
    case VoiceStep.train:
      return 3;
    case VoiceStep.complete:
      return 4;
    default:
      return 0;
  }
};

export const getVoiceStepFromIndex = (index: number): VoiceStep => {
  switch (index) {
    case 0:
      return VoiceStep.select;
    case 1:
      return VoiceStep.clean;
    case 2:
      return VoiceStep.review;
    case 3:
      return VoiceStep.train;
    case 4:
      return VoiceStep.complete;
    default:
      return VoiceStep.select;
  }
};

export function getProviderName(provider: AIProvider): string {
  switch (provider) {
    case AIProvider.PLAYHT:
      return 'PlayHT';
    case AIProvider.OPENAI:
      return 'OpenAI';
    case AIProvider.DEEPGRAM:
      return 'Deepgram';
    case AIProvider.ELEVENLABS:
      return 'ElevenLabs';
    case AIProvider.UNKNOWN:
      return 'Unknown';
    default:
      return 'Unknown';
  }
}

export function secondsToTimeFormat(seconds: number): string {
  if (seconds < 0) {
    throw new Error('Input must be a non-negative number');
  }

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

  return `${minutes}:${formattedSeconds}`;
}
