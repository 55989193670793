import { Box, Card, Divider, Stack, Typography, useTheme } from '@mui/material';
import { formatDate } from 'common/utils';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useJob } from 'use/job';
import { getStatusColor } from '../columns';
import { SectionTitle, StatusIndicator } from 'components/atoms';
import { JobStatus } from 'types/enums';

export default function UserJob() {
  const { jobID } = useParams<{ jobID: string }>();
  const { job, jobLogs, getJob, getJobLogs } = useJob();
  const theme = useTheme();

  const getJobData = useCallback(async () => {
    await getJob(jobID);
    await getJobLogs(jobID);
  }, [jobID, getJob, getJobLogs]);

  useEffect(() => {
    if (
      !job ||
      (job && job.id !== jobID) ||
      !jobLogs ||
      (jobLogs && jobLogs[0]?.jobID !== jobID)
    ) {
      getJobData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getJobData]);

  return (
    <Box pb={6}>
      <Stack
        direction="column"
        spacing={2}
      >
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h3">Log - {job?.id}</Typography>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
          >
            <Typography sx={{ color: getStatusColor(job?.status, theme) }}>
              {job?.status}
            </Typography>
          </Stack>
        </Stack>
        <Divider />
      </Stack>
      <SectionTitle>History</SectionTitle>
      <Stack
        sx={{ mt: 2 }}
        direction="column"
        spacing={2}
      >
        {jobLogs?.map((log) => (
          <Card
            key={log.id}
            sx={{ p: 1 }}
          >
            <Stack
              justifyContent="space-between"
              alignItems="center"
              direction="row"
              spacing={2}
            >
              <Typography>{log.message}</Typography>
              <StatusIndicator status={log.status as JobStatus} />
            </Stack>
            <Typography>{formatDate(log.updatedAt)}</Typography>
          </Card>
        ))}
      </Stack>
    </Box>
  );
}
