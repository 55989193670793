import { createContext, useContext, useState, ReactNode, useMemo } from 'react';
import { SuperfeelUserResult } from 'types/api';

interface SuperfeelUserContextState {
  superfeelUser: SuperfeelUserResult | null;
  setSuperfeelUser: (user: SuperfeelUserResult | null) => void;
}

const SuperfeelUserContext = createContext<
  SuperfeelUserContextState | undefined
>(undefined);

export const useSuperfeelUserContext = () => {
  const context = useContext(SuperfeelUserContext);
  if (!context) {
    throw new Error(
      'useSuperfeelUserContext must be used within a SuperfeelUserContextProvider',
    );
  }
  return context;
};

interface SuperfeelUserContextProviderProps {
  children: ReactNode;
}

export function SuperfeelUserContextProvider({
  children,
}: SuperfeelUserContextProviderProps): JSX.Element {
  const [superfeelUser, setSuperfeelUser] =
    useState<SuperfeelUserResult | null>(null);

  const contextValue = useMemo(
    () => ({ superfeelUser, setSuperfeelUser }),
    [superfeelUser],
  );

  return (
    <SuperfeelUserContext.Provider value={contextValue}>
      {children}
    </SuperfeelUserContext.Provider>
  );
}
