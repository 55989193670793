import { FormLabel, TextField } from '@mui/material';

interface FormFieldProps {
  label: string;
  size?: 'small' | 'medium';
  fullWidth?: boolean;
  value: string;
  setValue: (value: string) => void;
}

export function FormField({
  label,
  size = 'small',
  fullWidth = true,
  value,
  setValue,
}: FormFieldProps) {
  return (
    <>
      <FormLabel>{label}</FormLabel>
      <TextField
        value={value}
        onChange={(e) => setValue(e.target.value)}
        size={size}
        fullWidth={fullWidth}
      />
    </>
  );
}
