import React, { useEffect, useRef, useState, useCallback } from 'react';
import {
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { File, SampleData } from '@twins/types';
import { MetadataGroup } from 'components/atoms';
import WaveSurfer from 'wavesurfer.js';
import PauseIcon from '@mui/icons-material/Pause';
import { PlayArrow } from '@mui/icons-material';
import { secondsToTimeFormat } from 'common/utils';
import { useMedia } from 'use/media';

interface VoiceSampleProps {
  file?: File;
  sample: SampleData;
  action?: React.ReactNode;
  loading?: boolean;
}

export default function VoiceSample({
  sample,
  action,
  file,
  loading,
}: VoiceSampleProps) {
  const waveformRef = useRef<HTMLDivElement>(null);
  const wavesurferRef = useRef<WaveSurfer | null>(null);
  const isInitializedRef = useRef(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { getCloudfrontUrl } = useMedia();

  const initializeWaveSurfer = useCallback(async () => {
    if (waveformRef.current && !isInitializedRef.current) {
      try {
        setIsLoading(true);

        abortControllerRef.current = new AbortController();
        const signal = abortControllerRef.current.signal;

        const key = sample.audioURI;

        const result = await getCloudfrontUrl(key);

        if (!result) {
          throw new Error('Failed to get signed URL');
        }

        const signedUrl = result;

        if (!wavesurferRef.current) {
          wavesurferRef.current = WaveSurfer.create({
            height: 100,
            container: waveformRef.current,
            waveColor: 'grey',
            progressColor: 'white',
          });

          wavesurferRef.current.on('ready', () => setIsLoading(false));
          wavesurferRef.current.on('play', () => setIsPlaying(true));
          wavesurferRef.current.on('pause', () => setIsPlaying(false));
        }

        const response = await fetch(signedUrl, { signal });
        const blob = await response.blob();

        if (!signal.aborted) {
          wavesurferRef.current.loadBlob(blob);
          isInitializedRef.current = true;
        }
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          console.error('Error loading audio:', error);
        }
        setIsLoading(false);
      }
    }
  }, [getCloudfrontUrl, sample.audioURI]);

  useEffect(() => {
    initializeWaveSurfer();

    return () => {
      // Cleanup function
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
        abortControllerRef.current = null;
      }
      // Separate WaveSurfer cleanup
      if (wavesurferRef.current) {
        wavesurferRef.current.unAll();
        wavesurferRef.current.destroy();
        wavesurferRef.current = null;
      }
      isInitializedRef.current = false;
    };
  }, [initializeWaveSurfer]);

  const handlePlayPause = () => {
    if (wavesurferRef.current) {
      wavesurferRef.current.playPause();
    }
  };

  return (
    <Box sx={{ my: 2 }}>
      <Card>
        <Box
          display="flex"
          justifyContent={'space-between'}
          alignItems={'center'}
          p={1}
        >
          <Stack
            direction="row"
            spacing={4}
          >
            <Typography
              color="grey"
              sx={{ p: 0, mt: 0, fontWeight: 600 }}
              variant="h4"
            >
              {file ? file?.name : 'Sample'}
            </Typography>
            <MetadataGroup
              items={[
                {
                  label: 'Length',
                  value: `${secondsToTimeFormat(file?.length)}`,
                },
              ]}
            />
          </Stack>
          {action && <Box>{action}</Box>}
        </Box>
        <Divider sx={{ opacity: 0.2 }} />
        <CardContent>
          <Stack
            direction="row"
            alignItems={'center'}
            justifyContent="center"
            spacing={2}
          >
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Tooltip
                title={isPlaying ? 'Pause' : 'Play'}
                placement="top"
              >
                <IconButton
                  size="large"
                  onClick={handlePlayPause}
                  disabled={isLoading}
                >
                  {isPlaying ? (
                    <PauseIcon fontSize="large" />
                  ) : (
                    <PlayArrow fontSize="large" />
                  )}
                </IconButton>
              </Tooltip>
            )}
            <Box
              sx={{ width: '100%', minHeight: '100%' }}
              ref={waveformRef}
            />
          </Stack>
          <Stack
            sx={{ mt: 2 }}
            direction="column"
            spacing={1}
          >
            <Typography
              color="grey"
              sx={{
                textTransform: 'uppercase',
                fontSize: '0.6rem',
                lineHeight: 0,
                fontWeight: 600,
              }}
              variant="caption"
            >
              Transcript
            </Typography>
            <Typography
              color="grey"
              sx={{ p: 0, mt: 0, fontWeight: 600 }}
              variant="h5"
            >
              {sample.transcript?.transcript
                ? sample.transcript?.transcript
                : file?.content
                  ? file?.content
                  : ''}
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
}
