import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import {
  Box,
  Checkbox,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Chip,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RadioButtonCheckedRounded from '@mui/icons-material/RadioButtonCheckedRounded';
import RadioButtonUncheckedRounded from '@mui/icons-material/RadioButtonUncheckedRounded';
import Refresh from '@mui/icons-material/Refresh';
import { GraphContentCard } from '../content-card';
import { useTwinsGetIngestionDataQuery } from 'services/job/job';
import { CheckboxWithLabel, Loading } from 'components/atoms';
import { IngestionAsset, FilesSource, JobStatus } from '@twins/types';

type ChipColour = "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";

const accordionStyles = {
  root: {
    '&.Mui-expanded': { bgcolor: 'background.default' },
    '& .MuiAccordionSummary-root': { bgcolor: 'background.default' },
    '& .MuiAccordionDetails-root': { bgcolor: 'background.default' },
  },
  summary: {
    paddingX: 0,
    margin: 0,
    background: 'background.default',
    minHeight: 'auto !important',
    borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
    '&.Mui-expanded': { minHeight: 'auto !important' },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      '&.Mui-expanded': { margin: 0 },
    },
  },
  details: { paddingX: 0 },
};

interface SelectAllBoxProps {
  checked: boolean;
  onChange: () => void;
  label: string;
  isDisabled: boolean
}

const statusColors: Record<JobStatus, ChipColour> = {
  complete: 'success',
  failed: 'error',
  pending: 'warning',
  running: 'info',
  starting: 'info',
  submitted: 'default',
  unknown: 'default',
};

const JobStatusChip = ({ status }: { status: JobStatus }) => (
  <Chip
    label={(status === 'unknown' ? 'not started' : status).toUpperCase()}
    color={statusColors[status]}
    size="small"
    sx={{ fontWeight: 'bold' }}
  />
);

const SelectAllBox = React.memo(
  ({ checked, onChange, label, isDisabled }: SelectAllBoxProps) => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        color: 'white',
        borderRadius: '4px',
        width: '100%',
      }}
    >
      <Checkbox
        icon={<RadioButtonUncheckedRounded />}
        checkedIcon={<RadioButtonCheckedRounded />}
        checked={checked}
        onChange={onChange}
        disabled={isDisabled}
        sx={{
          color: 'white',
          '&.Mui-checked': { color: 'white' },
          '&.MuiCheckbox-root': {
            padding: 0,
          },
        }}
      />
      <Typography
        variant="button"
        sx={{ marginLeft: 1 }}
      >
        {label}
      </Typography>
    </Box>
  ),
);

const createEmptyIngestionAssetGroups = (): Record<
  FilesSource,
  IngestionAsset[]
> => {
  return {
    interview: [],
    feel: [],
  };
};

const isDocSelectable = (doc: IngestionAsset) => doc.status === 'failed' || doc.status === 'unknown';

interface SourcesProps {
  userId: string;
  selectedJobs: Map<string, FilesSource>;
  setSelectedJobs: Dispatch<SetStateAction<Map<string, FilesSource>>>;
}

export function Sources({
  selectedJobs,
  setSelectedJobs,
  userId,
}: SourcesProps) {
  const [showSelectableOnly, setShowSelectableOnly] = useState(true);
  const { data, isLoading, isError, isUninitialized, refetch, isFetching } =
    useTwinsGetIngestionDataQuery(
      {
        input: { userId },
      },
      {
        skip: !userId,
      },
    );

  const filteredAndGroupedDocs = useMemo(() => {
    if (!data?.twinsGetIngestionData?.data)
      return createEmptyIngestionAssetGroups();

    return data.twinsGetIngestionData.data.reduce<
      Record<FilesSource, IngestionAsset[]>
    >((acc, doc: IngestionAsset) => {
      const source = doc.source || 'unknown';
      if (source in acc && (!showSelectableOnly || isDocSelectable(doc))) {
        acc[source as FilesSource].push(doc);
      } else if (source === 'unknown') {
        console.warn('IngestionAsset with unknown source:', doc);
      }
      return acc;
    }, createEmptyIngestionAssetGroups());
  }, [data?.twinsGetIngestionData?.data, showSelectableOnly]);

  const handleSelectAll = useCallback(
    (dataSet: IngestionAsset[]) => {
      setSelectedJobs((prev) => {
        const newMap = new Map(prev);
        const selectableDocs = dataSet.filter(isDocSelectable);
        if (selectableDocs.every((doc) => prev.has(doc.id))) {
          selectableDocs.forEach((doc) => newMap.delete(doc.id));
        } else {
          selectableDocs.forEach(
            (doc) => doc.source && newMap.set(doc.id, doc.source),
          );
        }
        return newMap;
      });
    },
    [setSelectedJobs],
  );

  const handleItemToggle = useCallback(
    (doc: IngestionAsset) => {
      if (!isDocSelectable(doc)) return;
      setSelectedJobs((prev) => {
        const newMap = new Map(prev);
        if (newMap.has(doc.id)) {
          newMap.delete(doc.id);
        } else if (doc.source) {
          newMap.set(doc.id, doc.source);
        }
        return newMap;
      });
    },
    [setSelectedJobs],
  );

  const renderIngestionAssetCard = useCallback(
    (doc: IngestionAsset) => (
      <GraphContentCard
        key={doc.id}
        id={doc.id}
        title={doc.name || 'Unnamed Document'}
        content={doc.content || 'No content'}
        audience={doc.audience}
        actions={
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Typography
              variant="h6"
              fontSize="small"
              sx={{ color: 'text.secondary' }}
            >
              STATUS
            </Typography>
            <JobStatusChip status={doc.status} />
          </Stack>
        }
        selected={selectedJobs.has(doc.id)}
        onToggleSelect={() => handleItemToggle(doc)}
        disabled={!isDocSelectable(doc)}
      />
    ),
    [handleItemToggle, selectedJobs],
  );

  const renderAccordion = useCallback(
    (title: string, docs: IngestionAsset[]) => {
      const selectableDocs = docs.filter(isDocSelectable);
      const selectedCount = selectableDocs.filter((doc) =>
        selectedJobs.has(doc.id),
      ).length;
      return (
        <Accordion
          key={title}
          sx={accordionStyles.root}
        >
          <AccordionSummary
            expandIcon={(
              <Box alignItems="center" justifyContent="center" paddingX={1}>
                <ExpandMoreIcon />
              </Box>)}
            aria-controls={title}
            id={title.toLowerCase()}
            sx={accordionStyles.summary}
          >
            <Typography
              variant="h5"
              fontWeight={600}
              sx={{ py: 1 }}
            >
              {`${selectedCount} / ${selectableDocs.length} unprocessed ${title}${selectableDocs.length !== 1 ? 's' : ''} (${docs.length} total)`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={accordionStyles.details}>
            <CheckboxWithLabel label="Show selectable only" checked={showSelectableOnly} onChange={() => setShowSelectableOnly(!showSelectableOnly)}/>
            <SelectAllBox
              checked={
                selectableDocs.length > 0 &&
                selectableDocs.every((doc) => selectedJobs.has(doc.id))
              }
              isDisabled={!selectableDocs.length}
              onChange={() => handleSelectAll(docs)}
              label="SELECT ALL"
            />
            {docs.map(renderIngestionAssetCard)}
          </AccordionDetails>
        </Accordion>
      );
    },
    [showSelectableOnly, renderIngestionAssetCard, selectedJobs, handleSelectAll],
  );

  if (isLoading || isUninitialized || isFetching) return <Loading />;
  if (isError) return <Typography>Error loading ingestion data</Typography>;

  return (
    <Box
      paddingBottom="180px"
      paddingTop="20px"
    >
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        <IconButton onClick={() => refetch()}>
          <Refresh />
        </IconButton>
      </Box>
      {(
        Object.entries(filteredAndGroupedDocs) as [
          FilesSource,
          IngestionAsset[],
        ][]
      ).map(([source, docs]) => renderAccordion(source, docs))}
    </Box>
  );
}
