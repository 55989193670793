import { LoadingButton } from '@mui/lab';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useState, ChangeEvent } from 'react';
import { useSnackbar } from 'use/snackbar';
import { updatePassword } from 'aws-amplify/auth';
import PasswordField from './password';

export function ChangePassword() {
  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { showSnackbar } = useSnackbar();

  const handleUpdatePassword = async () => {
    setIsLoading(true);
    try {
      await updatePassword({ oldPassword, newPassword });
      showSnackbar('Password changed successfully', 'success');
      setOldPassword('');
      setNewPassword('');
    } catch (err) {
      showSnackbar('Failed to change password', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleOldPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOldPassword(e.target.value);
  };

  const handleNewPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };

  const handleButtonClick = () => {
    handleUpdatePassword();
  };

  return (
    <Box>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          md={6}
        >
          <Box my={2}>
            <Typography
              sx={{ textTransform: 'uppercase' }}
              variant="h6"
            >
              Change Password
            </Typography>
          </Box>
          <Stack
            spacing={2}
            direction="column"
          >
            <PasswordField
              label="Old Password"
              value={oldPassword}
              onChange={handleOldPasswordChange}
            />
            <PasswordField
              label="New Password"
              value={newPassword}
              onChange={handleNewPasswordChange}
            />
            <LoadingButton
              onClick={handleButtonClick}
              loading={isLoading}
              variant="contained"
            >
              Change Password
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
