import { Stack } from '@mui/material';
import { FilePreviewMetadata } from 'components/atoms';

export const MetadataGroup = ({ items }) => (
  <Stack
    direction="row"
    spacing={6}
  >
    {items.map((item, index) => (
      <FilePreviewMetadata
        key={index}
        {...item}
      />
    ))}
  </Stack>
);
