import React from 'react';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Box,
  Skeleton,
} from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import { SuperfeelUserResult } from 'types/api';
import { UserField } from '../local';

interface SectionCardProps {
  title: string;
  fields: UserField[];
  user: SuperfeelUserResult;
  gridsize?: number;
  endCard?: React.ReactNode;
}

function SectionCard({
  title,
  fields,
  user,
  gridsize = 4,
  endCard,
}: SectionCardProps): JSX.Element {
  const renderValue = (value: boolean | string | number, field: UserField) => {
    if (typeof value === 'boolean') {
      return value ? <Check /> : <Close />;
    }
    if (field.format) {
      return field.format(value);
    }
    return value;
  };

  return (
    <Box mb={6}>
      <Box my={2}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            color: 'text.secondary',
          }}
          variant="body2"
          gutterBottom
        >
          {title}
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
      >
        {fields
          .filter(
            (field) =>
              user[field.key] !== null && user[field.key] !== undefined,
          )
          .map((field) => {
            const value = user[field.key] as boolean | string | number;

            const cardStyle =
              field.key === 'postsCount' &&
              typeof value === 'number' &&
              value > 150
                ? { border: '1px solid green' }
                : {};

            return (
              <Grid
                item
                xs={12}
                md={gridsize}
                key={field.key}
              >
                <Card sx={cardStyle}>
                  <CardContent sx={{ pb: 0 }}>
                    <Typography
                      variant="caption"
                      sx={{
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        color: 'text.secondary',
                      }}
                    >
                      {field.label}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 2,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                      variant="h3"
                    >
                      {value && field ? (
                        renderValue(value, field)
                      ) : (
                        <Skeleton />
                      )}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        {endCard || null}
      </Grid>
    </Box>
  );
}

export default SectionCard;
