import React from 'react';
import { Box, Typography } from '@mui/material';
import ButtonIconTooltip from '../button-icon-tooltip';

interface PreviewHeaderProps {
  title: string;
  deleting?: boolean;
  onDelete: () => void;
  tooltip?: string;
  extraContent?: React.ReactNode;
  loading?: boolean;
}

export function PreviewHeader({
  title,
  deleting, // TODO: Move loading / deleting state out of this component and handle it in modals.
  onDelete,
  tooltip,
  extraContent,
  loading,
}: PreviewHeaderProps) {
  return (
    <Box
      sx={{
        p: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        gap={2}
      >
        <Typography variant="h4">{title}</Typography>
        {extraContent}
      </Box>
      <ButtonIconTooltip
        onClick={onDelete}
        isLoading={deleting}
        tooltipTitle={tooltip || 'Delete'}
        tooltipColorVariant="error"
        icon="close"
        size="small"
      />
    </Box>
  );
}
