import { TabsList as BaseTabsList } from '@mui/base/TabsList';
import { styled } from '@mui/material/styles';

const TabsList = styled(BaseTabsList)`
  min-width: 400px;
  background-color: #222222;
  border: 1px solid #333333;
  border-radius: 12px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

export default TabsList;
