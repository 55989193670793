import React from 'react';
import { Typography } from '@mui/material';

interface CaptionProps {
  children: React.ReactNode;
}

const Caption: React.FC<CaptionProps> = ({ children }) => (
  <Typography
    variant="caption"
    color="grey"
    sx={{
      textTransform: 'uppercase',
      fontSize: '0.7rem',
      lineHeight: 0,
      fontWeight: 600,
    }}
  >
    {children}
  </Typography>
);

export default Caption;
