import { api as generatedApi } from 'services/generated';

export const usersApi = generatedApi.enhanceEndpoints({
  addTagTypes: ['Users'],
  endpoints: {
    TwinsSearchUsers: {
      providesTags: ['Users'],
      transformResponse: (res: any) => {
        return res;
      },
    },
  },
});

export const { useTwinsSearchUsersQuery } = usersApi;
