import { ReactNode } from 'react';
import {
  List,
  Toolbar,
  Box,
  CssBaseline,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  Tooltip,
  Collapse,
  Skeleton,
  IconButton,
  Stack,
} from '@mui/material';
import {
  ExitToApp,
  Settings,
  ExpandMore,
  ExpandLess,
} from '@mui/icons-material';
import { useApp } from 'use/app';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { signOut } from 'aws-amplify/auth';
import { useUser } from 'use/user';
import { v4 as uuid } from 'uuid';
import { SideBarItemProps } from 'types/app';
import { getUserSideBarItems, MainSideBarItems } from 'common/nav';
import { useSnackbar } from 'use/snackbar';
import { AppBar, Drawer, DrawerHeader } from './components/skeleton';
import UserTitle from './components/usertitle';
import SidebarItem from './components/sidebaritem';
import EndAction from './components/endaction';
import { useSuperfeelUser } from 'use/superfeel-user';
import { ButtonIconTooltip } from 'components/atoms';

interface AppDrawerProps {
  children: ReactNode;
  appBarAction?: ReactNode;
}

export default function AppDrawer({ appBarAction, children }: AppDrawerProps) {
  const { drawerOpen, accountSettingsOpen, setAccountSettingsOpen } = useApp();
  const { user } = useUser();
  const { superfeelUser } = useSuperfeelUser();
  const { showSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const location = useLocation();

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/');
    } catch (err) {
      showSnackbar('Failed to sign out', 'error');
    }
  };

  const handleSignOutClick = () => {
    handleSignOut();
  };

  const userSideBarItems = getUserSideBarItems(
    superfeelUser?.username || 'User',
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        elevation={1}
        position="fixed"
        open={drawerOpen}
      >
        <Toolbar>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
            >
              {location.pathname !== '/' && (
                <ButtonIconTooltip
                  onClick={() => navigate(-1)}
                  isLoading={false}
                  tooltipTitle="Back"
                  icon="back"
                  tooltipColorVariant="info"
                />
              )}
              <UserTitle />
            </Stack>
            <EndAction />
            {appBarAction && appBarAction}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        elevation={0}
        variant="permanent"
        open={drawerOpen}
      >
        <DrawerHeader>
          <Link to="/">
            <IconButton>
              <Avatar src="/img/superfeel-logo.jpg" />
            </IconButton>
          </Link>
        </DrawerHeader>
        <Divider />
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          height="100%"
        >
          <Box>
            <List>
              {MainSideBarItems.map((item: SideBarItemProps) => {
                return (
                  <SidebarItem
                    key={uuid()}
                    title={item.title}
                    icon={item.icon}
                    path={item.path}
                  />
                );
              })}
            </List>
            {location.pathname.includes('/user/') && (
              <Box>
                <Divider />
                <List>
                  {userSideBarItems.map((item: SideBarItemProps) => {
                    return (
                      <SidebarItem
                        key={uuid()}
                        disabled={item.disabled}
                        title={item.title}
                        icon={item.icon}
                        path={item.path}
                      />
                    );
                  })}
                </List>
              </Box>
            )}
          </Box>
          <Box>
            <Collapse in={accountSettingsOpen}>
              <List>
                <ListItem
                  disablePadding
                  sx={{ display: 'block' }}
                >
                  <Tooltip
                    title="Settings"
                    placement="right"
                  >
                    <ListItemButton
                      onClick={() => navigate('/settings')}
                      selected={location.pathname === '/settings'}
                    >
                      <ListItemIcon>
                        <Settings />
                      </ListItemIcon>
                      <ListItemText primary="Settings" />
                    </ListItemButton>
                  </Tooltip>
                </ListItem>
                <ListItem
                  disablePadding
                  sx={{ display: 'block' }}
                >
                  <Tooltip
                    title="Log Out"
                    placement="right"
                  >
                    <ListItemButton onClick={handleSignOutClick}>
                      <ListItemIcon>
                        <ExitToApp />
                      </ListItemIcon>
                      <ListItemText primary="Log Out" />
                    </ListItemButton>
                  </Tooltip>
                </ListItem>
              </List>
            </Collapse>
            <List>
              <ListItem
                disablePadding
                sx={{ display: 'block' }}
              >
                <Tooltip
                  title="Account"
                  placement="right"
                >
                  <ListItemButton
                    onClick={() => setAccountSettingsOpen(!accountSettingsOpen)}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: drawerOpen ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <Avatar>
                        {user?.name ? (
                          user?.name.charAt(0)
                        ) : (
                          <Skeleton
                            height={20}
                            width={20}
                          />
                        )}
                      </Avatar>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        user ? `${user?.name}` : <Skeleton width={100} />
                      }
                      primaryTypographyProps={{
                        noWrap: true,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    />
                    {accountSettingsOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3 }}
      >
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}
