import React, { useState, useEffect, memo } from 'react';
import { Box, Tabs, Tab, Typography, Divider, Stack } from '@mui/material';
import { TabPanel } from 'components/atoms';
import { v4 as uuid } from 'uuid';
import { DataFeels, DataInterviews } from './components';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSuperfeelUser } from 'use/superfeel-user';

const tabLabels = ['Feels', 'Interviews'];
const tabComponents = [<DataFeels />, <DataInterviews />];
interface UserDataProps {
  collection?: boolean;
}
function UserData({ collection }: UserDataProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(collection ? 1 : 0);
  const { superfeelUser } = useSuperfeelUser();

  useEffect(() => {
    const path = location.pathname;
    if (path.endsWith('/collection')) {
      setValue(1);
    } else {
      setValue(0);
    }
  }, [location]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    const basePath = `/user/${superfeelUser.username}/data`;
    switch (newValue) {
      case 0:
        navigate(basePath);
        break;
      case 1:
        navigate(`${basePath}/collection`);
        break;
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stack direction="column">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h3">Data</Typography>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="user data tabs"
            sx={{ minHeight: 0 }}
          >
            {tabLabels.map((label, index) => (
              <Tab
                key={uuid()}
                sx={{ fontWeight: 'bold', py: 1.5, minHeight: 4 }}
                label={label}
                id={`tab-${index}`}
                aria-controls={`tabpanel-${index}`}
              />
            ))}
          </Tabs>
        </Stack>
        <Divider />
      </Stack>
      <Box mt={4}>
        {tabComponents.map((component, index) => (
          <TabPanel
            key={uuid()}
            value={value}
            index={index}
          >
            {component}
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
}

export default memo(UserData);
