/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Skeleton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

export const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 150,
  },
  {
    field: 'transcript',
    headerName: 'Transcript Text',
    width: 300,
    renderCell: (params) =>
      params.row.audience === 'PUBLIC' ? (
        `${typeof params.value === 'string' ? params.value.slice(0, 50) : ''}...`
      ) : (
        <Skeleton
          variant="text"
          width={250}
        />
      ),
  },
  {
    field: 'captionText',
    headerName: 'Caption Text',
    width: 300,
    renderCell: (params) =>
      params.row.audience === 'PUBLIC' ? (
        `${typeof params.value === 'string' ? params.value.slice(0, 50) : ''}...`
      ) : (
        <Skeleton
          variant="text"
          width={250}
        />
      ),
  },
  {
    field: 'audience',
    headerName: 'Audience',
    width: 150,
  },
];
