import React from 'react';
import { Button, ButtonProps, TooltipProps } from '@mui/material';
import { Tooltip } from 'components/atoms';
import { ColorVariant } from 'types/app';

interface ButtonTooltipProps {
  title: string;
  placement: TooltipProps['placement'];
  children: React.ReactNode;
  size?: ButtonProps['size'];
  variant?: ButtonProps['variant'];
  color?: ButtonProps['color'];
  startIcon?: ButtonProps['startIcon'];
  endIcon?: ButtonProps['endIcon'];
  sx?: ButtonProps['sx'];
  onClick?: () => void;
  disabled?: boolean;
  tooltipColorVariant?: ColorVariant;
}

export const ButtonTooltip: React.FC<ButtonTooltipProps> = ({
  title,
  placement,
  children,
  size,
  variant,
  color,
  startIcon,
  endIcon,
  sx,
  onClick,
  disabled,
  tooltipColorVariant = 'tertiary',
}) => {
  return (
    <Tooltip
      title={title}
      placement={placement}
      colorVariant={tooltipColorVariant as ColorVariant}
    >
      <Button
        disabled={disabled}
        size={size}
        variant={variant}
        color={color}
        startIcon={startIcon}
        endIcon={endIcon}
        sx={sx}
        onClick={onClick}
      >
        {children}
      </Button>
    </Tooltip>
  );
};
