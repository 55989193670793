import React, { useState, useEffect, useCallback } from 'react';
import { Box, TextField } from '@mui/material';
import InfoLabel from '../info-label';

interface InfoTextFieldProps<T> {
  label: string;
  tooltip: string;
  placeholder?: string;
  multiline?: boolean;
  minRows?: number;
  value: T;
  onChange: (value: T) => void;
  onBlur?: () => void;
  endAction?: React.ReactNode;
  type?: 'text' | 'number';
}

function InfoTextField<T>({
  label,
  tooltip,
  placeholder,
  multiline,
  minRows,
  value,
  onChange,
  onBlur = () => {},
  endAction,
  type = 'text',
}: InfoTextFieldProps<T>) {
  const [localValue, setLocalValue] = useState<T>(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value as T;
      setLocalValue(newValue);
      onChange(newValue);
    },
    [onChange],
  );

  return (
    <Box>
      <InfoLabel
        label={label}
        tooltip={tooltip}
        placement="top"
        endAction={endAction}
      />
      <TextField
        type={type ? type : 'text'}
        value={localValue}
        onChange={handleChange}
        onBlur={onBlur}
        fullWidth
        size="small"
        placeholder={placeholder}
        multiline={multiline}
        minRows={minRows}
      />
    </Box>
  );
}

export default InfoTextField;
