import { createContext, useContext, useState, ReactNode, useMemo } from 'react';

interface AppContextState {
  bottomNavLoading: boolean;
  setBottomNavLoading: (value: boolean) => void;
  drawerOpen: boolean;
  setDrawerOpen: (value: boolean) => void;
  accountSettingsOpen: boolean;
  setAccountSettingsOpen: (value: boolean) => void;
  addVoice: boolean;
  setAddVoice: (value: boolean) => void;
  addDocument: boolean;
  setAddDocument: (value: boolean) => void;
  showMemorySettings: boolean;
  setShowMemorySettings: (value: boolean) => void;
}

const AppContext = createContext<AppContextState | undefined>(undefined);

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppContextProvider');
  }
  return context;
};

interface AppContextProviderProps {
  children: ReactNode;
}

export function AppContextProvider({
  children,
}: AppContextProviderProps): JSX.Element {
  const [bottomNavLoading, setBottomNavLoading] = useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(true);
  const [accountSettingsOpen, setAccountSettingsOpen] = useState<boolean>(true);
  const [addVoice, setAddVoice] = useState<boolean>(false);
  const [addDocument, setAddDocument] = useState<boolean>(false);
  const [showMemorySettings, setShowMemorySettings] = useState<boolean>(false);

  const contextValue = useMemo(
    () => ({
      bottomNavLoading,
      setBottomNavLoading,
      drawerOpen,
      setDrawerOpen,
      accountSettingsOpen,
      setAccountSettingsOpen,
      addVoice,
      setAddVoice,
      addDocument,
      setAddDocument,
      showMemorySettings,
      setShowMemorySettings,
    }),
    [
      bottomNavLoading,
      setBottomNavLoading,
      drawerOpen,
      setDrawerOpen,
      accountSettingsOpen,
      setAccountSettingsOpen,
      addVoice,
      setAddVoice,
      addDocument,
      setAddDocument,
      showMemorySettings,
      setShowMemorySettings,
    ],
  );

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
}
