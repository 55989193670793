import { getSignedUploadUrl } from 'api/getSignedUploadUrl';
import { completeMultipartUpload } from './complete-upload';
import { initiateMultipartUpload } from './initiate';

interface UploadStatus {
  success: boolean;
  message: string;
  percentage: number;
}

interface UploadOptions {
  file: File;
  onProgress: (percentage: number) => void;
  key: string;
  bucketName: string;
}

type UplaodRes = {
  ETag: string;
  PartNumber: number;
};

const MAX_CONCURRENT_UPLOADS = 10;
const PART_SIZE = 5 * 1024 * 1024; // 5MB part size

export const multipartUpload = async ({
  file,
  onProgress,
  key,
  bucketName,
}: UploadOptions): Promise<UploadStatus> => {
  try {
    // 1) Initiate the multipart upload and obtain an uploadId.
    const uploadId = await initiateMultipartUpload(bucketName, key);

    // 2) Divide the large object into multiple parts, get a presigned URL for
    //    each part, and upload the parts of a large object in parallel.
    const parts = await uploadParts(
      file,
      bucketName,
      key,
      uploadId,
      onProgress,
    );

    // 3) Complete the upload and verify the parts.
    const res = await completeMultipartUpload(bucketName, key, uploadId, parts);
    if (res?.key) {
      return {
        success: true,
        message: 'File uploaded successfully',
        percentage: 100,
      };
    } else {
      return {
        success: false,
        message: 'Clould not upload file. Please try again later.',
        percentage: 0,
      };
    }
  } catch (error) {
    return {
      success: false,
      message: `Error uploading file: ${error instanceof Error ? error.message : String(error)}`,
      percentage: 0,
    };
  }
};

async function uploadParts(
  file: File,
  bucket: string,
  key: string,
  uploadId: string,
  onProgress: (percentage: number) => void,
): Promise<UplaodRes[]> {
  const partCount = Math.ceil(file.size / PART_SIZE);
  const parts: UplaodRes[] = [];
  let completedParts = 0;

  async function* fileChunkGenerator() {
    for (let i = 0; i < partCount; i++) {
      const start = i * PART_SIZE;
      const end = Math.min(start + PART_SIZE, file.size);
      yield {
        chunk: file.slice(start, end),
        partNumber: i + 1,
      };
    }
  }

  async function uploadChunk({
    chunk,
    partNumber,
  }: {
    chunk: Blob;
    partNumber: number;
  }) {
    const multipartUpload = {
      partNumber,
      uploadId,
    };
    const response = await getSignedUploadUrl({
      key,
      bucketName: bucket,
      method: 'PUT',
      multipartUpload,
      expiry: 60 * 60 * 24,
      type: 'INTERVIEW',
    });

    const uploadData = response.data.twinsGetSignedUrl;
    if (!uploadData.url) {
      throw new Error(`Failed to get signed URL for part ${partNumber}`);
    }

    const uploadResult = await uploadPart(
      uploadData.url,
      chunk,
      partNumber,
      uploadId,
      'CRC32',
    );
    completedParts++;
    onProgress((completedParts / partCount) * 100);
    console.log(`Completed part ${partNumber}`);
    return { ETag: uploadResult.ETag, PartNumber: partNumber };
  }

  const uploadPromises: Promise<UplaodRes>[] = [];

  for await (const { chunk, partNumber } of fileChunkGenerator()) {
    if (uploadPromises.length >= MAX_CONCURRENT_UPLOADS) {
      await Promise.race(uploadPromises);
    }
    const uploadPromise = uploadChunk({ chunk, partNumber });
    uploadPromises.push(uploadPromise);
    uploadPromise.then((result) => {
      parts.push(result);
      uploadPromises.splice(uploadPromises.indexOf(uploadPromise), 1);
    });
  }

  await Promise.all(uploadPromises);
  return parts.sort((a, b) => a.PartNumber - b.PartNumber);
}

async function uploadPart(
  url: string,
  part: Blob,
  partNumber: number,
  uploadId: string,
  checksumAlgorithm?: 'CRC32' | 'CRC32C' | 'SHA1' | 'SHA256',
): Promise<{ ETag: string; PartNumber: number }> {
  const headers = new Headers();
  headers.append('Content-Length', part.size.toString());
  // TODO: Implement the logic to calculate checksums.
  // if (checksumAlgorithm) {
  //   headers.append('x-amz-sdk-checksum-algorithm', checksumAlgorithm);
  // }

  const fullUrl = new URL(url);
  fullUrl.searchParams.append('partNumber', partNumber.toString());
  fullUrl.searchParams.append('uploadId', uploadId);

  console.log(`Uploading part ${partNumber} to ${fullUrl}`);

  const response = await fetch(fullUrl.toString(), {
    method: 'PUT',
    headers: headers,
    body: part,
  });

  if (!response.ok) {
    throw new Error(
      `Failed to upload part ${partNumber}: ${response.status} ${response.statusText}`,
    );
  }

  const etag = response.headers.get('ETag');
  if (!etag) {
    throw new Error(`ETag not found in response for part ${partNumber}`);
  }

  const cleanEtag = etag.replace(/^"|"$/g, '');
  console.log(`Successfully uploaded part ${partNumber}. ETag: ${cleanEtag}`);
  return { ETag: cleanEtag, PartNumber: partNumber };
}
