import { Components } from '@mui/material';
import grey from '@mui/material/colors/grey';

const components: Components = {
  MuiStepIcon: {
    styleOverrides: {
      root: {
        color: grey[700],
        '&.Mui-active': {
          color: '#2bb6f6',
        },
        '&.Mui-completed': {
          color: 'white',
        },
      },
      text: {
        fill: 'black',
        '&.Mui-active': {
          fill: 'black',
        },
        fontWeight: 'bold',
        fontSize: '1rem',
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        borderRadius: 4,
        fontWeight: 'bold',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        boxShadow: 'none',

        '&:hover': {
          boxShadow: 'none',
        },
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      colorPrimary: {
        backgroundColor: '#0d0d0d',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        fontWeight: 'bold',
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        border: '1px solid',
        borderColor: '#2a2a2a',
        backgroundColor: '#0c0c0c',
      },
    },
  },
};

export default components;
