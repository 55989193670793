import { useFileAPI } from './api';
import { FileContextProvider, useFileContext } from './state';

export const useFile = () => {
  const context = useFileContext();
  const api = useFileAPI();

  return {
    ...context,
    ...api,
  };
};

export { FileContextProvider };
