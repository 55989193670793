import React from 'react';
import { Typography, Box, Divider } from '@mui/material';

interface SectionTitleProps {
  children: React.ReactNode;
}

const SectionTitle: React.FC<SectionTitleProps> = ({ children }) => (
  <Box>
    <Box my={2}>
      <Typography variant="h5">{children}</Typography>
    </Box>
    <Divider sx={{ opacity: 0.3 }} />
  </Box>
);

export default SectionTitle;
