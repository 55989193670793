import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useMemo,
  useCallback,
} from 'react';
import { Collection, File } from '@twins/types';

interface FileContextState {
  files: File[] | null;
  setFiles: (files: File[] | null) => void;
  file: File | null;
  setFile: (file: File | null) => void;
  collections: Collection[] | null;
  setCollections: (collections: Collection[] | null) => void;
  collection: Collection | null;
  setCollection: (collection: Collection | null) => void;
  deletingCollection: boolean;
  setDeletingCollection: (deletingCollection: boolean) => void;
  loadingFiles: boolean;
  setLoadingFiles: (loadingFiles: boolean) => void;
  loadingCollections: boolean;
  submitting: boolean;
  setSubmitting: (submitting: boolean) => void;
  setLoadingCollections: (loadingCollections: boolean) => void;
  creatingCollection: boolean;
  setCreatingCollection: (creatingCollection: boolean) => void;
  deletingFile: boolean;
  setDeletingFile: (deletingFile: boolean) => void;
  updatingFile: boolean;
  setUpdatingFile: (updatingFile: boolean) => void;
  reset: (...keys: (keyof FileContextState)[]) => void;
}

const FileContext = createContext<FileContextState | undefined>(undefined);

export const useFileContext = () => {
  const context = useContext(FileContext);
  if (!context) {
    throw new Error('useFileContext must be used within a FileContextProvider');
  }
  return context;
};

interface FileContextProviderProps {
  children: ReactNode;
}

export function FileContextProvider({
  children,
}: FileContextProviderProps): JSX.Element {
  const [files, setFiles] = useState<File[] | null>(null);
  const [collections, setCollections] = useState<Collection[] | null>(null);
  const [collection, setCollection] = useState<Collection | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [deletingCollection, setDeletingCollection] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(true);
  const [loadingCollections, setLoadingCollections] = useState(true);
  const [creatingCollection, setCreatingCollection] = useState(false);
  const [deletingFile, setDeletingFile] = useState(false);
  const [updatingFile, setUpdatingFile] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const reset = useCallback((...keys: (keyof FileContextState)[]) => {
    keys.forEach((key) => {
      switch (key) {
        case 'files':
          setFiles(null);
          break;
        case 'file':
          setFile(null);
          break;
        case 'collections':
          setCollections(null);
          break;
        case 'collection':
          setCollection(null);
          break;
        case 'deletingCollection':
          setDeletingCollection(false);
          break;
        case 'loadingFiles':
          setLoadingFiles(true);
          break;
        case 'loadingCollections':
          setLoadingCollections(true);
          break;
        case 'creatingCollection':
          setCreatingCollection(false);
          break;
        case 'deletingFile':
          setDeletingFile(false);
          break;
        case 'updatingFile':
          setUpdatingFile(false);
          break;
        default:
          console.warn(`Unrecognized key: ${key}`);
      }
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      files,
      setFiles,
      file,
      setFile,
      collections,
      setCollections,
      collection,
      setCollection,
      deletingCollection,
      setDeletingCollection,
      loadingFiles,
      setLoadingFiles,
      loadingCollections,
      setLoadingCollections,
      creatingCollection,
      setCreatingCollection,
      deletingFile,
      setDeletingFile,
      updatingFile,
      setUpdatingFile,
      submitting,
      setSubmitting,
      reset,
    }),
    [
      files,
      file,
      collections,
      collection,
      deletingCollection,
      loadingFiles,
      loadingCollections,
      creatingCollection,
      deletingFile,
      updatingFile,
      submitting,
      setSubmitting,
      reset,
    ],
  );

  return (
    <FileContext.Provider value={contextValue}>{children}</FileContext.Provider>
  );
}
