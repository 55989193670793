import { CompleteMultipartUploadResponse } from '@twins/types';
import { fileApi } from 'services/collection.services.ts/file';
import { store } from 'store';

export async function completeMultipartUpload(
  bucketName: string,
  key: string,
  uploadId: string,
  parts: { ETag: string; PartNumber: number }[],
): Promise<CompleteMultipartUploadResponse> {
  const response = await store.dispatch(
    fileApi.endpoints.CompleteMultipartUpload.initiate({
      input: {
        bucketName,
        key,
        uploadId,
        parts,
      },
    }),
  );

  if (!response.data.completeMultipartUpload?.key || response.error) {
    throw new Error('Failed to complete multipart upload');
  }

  return response?.data?.completeMultipartUpload;
}
