import { Menu, MenuItem } from '@mui/material';

const SPEED_OPTIONS = [
  { value: 0.5, label: '0.5x' },
  { value: 0.6, label: '0.6x' },
  { value: 0.7, label: '0.7x' },
  { value: 0.8, label: '0.8x' },
  { value: 0.9, label: '0.9x' },
  { value: 1, label: '1x' },
  { value: 1.1, label: '1.1x' },
  { value: 1.2, label: '1.2x' },
  { value: 1.3, label: '1.3x' },
  { value: 1.4, label: '1.4x' },
  { value: 1.5, label: '1.5x' },
];

const SpeedMenu = ({ anchorEl, open, onClose, onSpeedChange }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {SPEED_OPTIONS.map(({ value, label }) => (
        <MenuItem
          key={value}
          onClick={() => {
            onSpeedChange(value);
            onClose();
          }}
        >
          {label}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default SpeedMenu;
