import { createContext, useContext, useState, ReactNode, useMemo } from 'react';

interface CallContextState {
  callActive: boolean;
  setCallActive: (callActive: boolean) => void;
}

const CallContext = createContext<CallContextState | undefined>(undefined);

export const useCallContext = () => {
  const context = useContext(CallContext);
  if (!context) {
    throw new Error('useCallContext must be used within a CallContextProvider');
  }
  return context;
};

interface CallContextProviderProps {
  children: ReactNode;
}

export function CallContextProvider({
  children,
}: CallContextProviderProps): JSX.Element {
  const [callActive, setCallActive] = useState<boolean>(false);

  const contextValue = useMemo(
    () => ({
      setCallActive,
      callActive,
    }),
    [callActive],
  );

  return (
    <CallContext.Provider value={contextValue}>{children}</CallContext.Provider>
  );
}
