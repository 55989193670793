import { ReactNode, useMemo, useCallback } from 'react';
import { CircularProgress } from '@mui/material';
import { ButtonOwnProps } from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';

interface ButtonProps {
  label: string;
  onClick: () => void;
  color?: ButtonOwnProps['color'];
  variant?: ButtonOwnProps['variant'];
  size?: 'small' | 'medium' | 'large';
  icon?: ReactNode;
  disabled?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  loading?: boolean;
  width?: string | number;
}

export function Button({
  label,
  onClick,
  color = 'primary',
  variant = 'contained',
  size = 'medium',
  startIcon,
  endIcon,
  disabled = false,
  loading = false,
  width = 'auto',
}: ButtonProps) {
  const buttonStyles = useMemo(
    () => ({
      borderRadius: '8px',
      px: 2,
      fontWeight: '600',
      position: 'relative',
      minWith: 100,
      width,
    }),
    [width],
  );

  const loadingIndicator = useMemo(
    () => (
      <CircularProgress
        size={16}
        color="inherit"
      />
    ),
    [],
  );

  const getStartIcon = useCallback(() => {
    if (loading && startIcon) {
      return loadingIndicator;
    }
    return startIcon;
  }, [loading, startIcon, loadingIndicator]);

  const getEndIcon = useCallback(() => {
    if (loading && endIcon) {
      return loadingIndicator;
    }
    return endIcon;
  }, [loading, endIcon, loadingIndicator]);

  return (
    <LoadingButton
      startIcon={getStartIcon()}
      endIcon={getEndIcon()}
      variant={variant}
      color={color}
      onClick={onClick}
      size={size}
      disabled={disabled || loading}
      sx={buttonStyles}
    >
      {label}
    </LoadingButton>
  );
}
