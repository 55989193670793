import { useState, ChangeEvent } from 'react';
import {
  Dialog,
  DialogTitle,
  Divider,
  Typography,
  DialogContent,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  DialogActions,
  Button,
} from '@mui/material';
import {
  PERMISSION_LEVELS,
  PERMISSION_LEVEL_OPTIONS,
} from 'common/permissions';
import { LoadingButton } from '@mui/lab';

interface AddUserProps {
  addUser: boolean;
  setAddUser: (open: boolean) => void;
}

export function AddUser({ addUser, setAddUser }: AddUserProps) {
  const [email, setEmail] = useState<string>('');
  const [permissionLevel, setPermissionLevel] = useState<string>(
    PERMISSION_LEVELS.USER,
  );
  const [addingUser, setAddingUser] = useState<boolean>(false);

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePermissionChange = (e: SelectChangeEvent<string>) => {
    setPermissionLevel(e.target.value);
  };

  const handleAddUser = () => {
    setAddingUser(true);
    try {
      // Add user logic
    } catch (error) {
      // Handle error
    } finally {
      setAddingUser(false);
    }
  };

  return (
    <Dialog
      PaperProps={{ elevation: 2 }}
      fullWidth
      maxWidth="md"
      open={addUser}
      onClose={() => setAddUser(false)}
    >
      <DialogTitle>
        <Typography variant="h4">Add User</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <TextField
          value={email}
          onChange={handleEmailChange}
          fullWidth
          label="Email"
          variant="outlined"
          margin="normal"
          required
        />
        <FormControl
          fullWidth
          margin="normal"
          variant="outlined"
          required
        >
          <InputLabel id="permission-level-label">Permission Level</InputLabel>
          <Select
            labelId="permission-level-label"
            value={permissionLevel}
            onChange={handlePermissionChange}
            label="Permission Level"
          >
            {PERMISSION_LEVEL_OPTIONS.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button
          variant="text"
          onClick={() => setAddUser(false)}
        >
          Cancel
        </Button>
        <LoadingButton
          sx={{ fontWeight: 'bold' }}
          loading={addingUser}
          variant="contained"
          onClick={() => handleAddUser()}
        >
          Add
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
