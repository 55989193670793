import { useCallback, useState } from 'react';
import { Box, Button } from '@mui/material';
import { CheckCircle, Start } from '@mui/icons-material';
import { Sources } from '../sources';
import { FilesSource } from '@twins/types';
import { useTwinsCreateIngestionJobsMutation } from 'services/job/job';
import { useSuperfeelUser } from 'use/superfeel-user';
import { useSnackbar } from 'use/snackbar';

export function UserGraph() {
  const [selectedJobs, setSelectedJobs] = useState<Map<string, FilesSource>>(
    new Map(),
  );
  const [createIngestionJobs, { isLoading: isIngestionJobsLoading }] =
    useTwinsCreateIngestionJobsMutation();
  const { superfeelUser } = useSuperfeelUser();
  const { showSnackbar } = useSnackbar();

  const handleSubmit = useCallback(async () => {
    if (isIngestionJobsLoading) return;

    const selectedJobsInfo = {
      interviewIds: Array.from(selectedJobs.entries())
        .filter(([_, source]) => source === 'interview')
        .map(([id, _]) => id),
      postIds: Array.from(selectedJobs.entries())
        .filter(([_, source]) => source === 'feel')
        .map(([id, _]) => id),
      index: 'demo',
      userId: superfeelUser?.userId,
    };

    try {
      await createIngestionJobs({ input: selectedJobsInfo }).unwrap();
      setSelectedJobs(new Map());
      showSnackbar('Ingestion jobs successfully submitted', 'success');
    } catch (error) {
      showSnackbar('Failed to create ingestion jobs', 'error');
      console.error('Error creating ingestion jobs:', error);
    }
  }, [
    createIngestionJobs,
    isIngestionJobsLoading,
    selectedJobs,
    superfeelUser?.userId,
    showSnackbar,
  ]);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Sources
        userId={superfeelUser?.userId}
        selectedJobs={selectedJobs}
        setSelectedJobs={setSelectedJobs}
      />
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          p: 2,
          bgcolor: 'background.default',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: '60px',
          borderTop: '1px solid',
          borderColor: 'border.primary',
        }}
      >
        <Button
          color="primary"
          onClick={handleSubmit}
          disabled={isIngestionJobsLoading || selectedJobs.size === 0}
          variant="outlined"
          sx={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}
          size="small"
          endIcon={
            isIngestionJobsLoading ? (
              <CheckCircle fontSize="small" />
            ) : (
              <Start fontSize="small" />
            )
          }
        >
          {isIngestionJobsLoading ? 'Submitting...' : 'Submit'}
        </Button>
      </Box>
    </Box>
  );
}
