import { Collection, File, TwinsUpdateSpeakerIdInput } from '@twins/types';
import { executeGraphqlOperation } from 'api';
import { GraphQLResult } from 'aws-amplify/api';
import {
  twinsUpdateFileMutation,
  twinsDeleteFileMutation,
  twinsUpdateCollectionMutation,
  twinsDeleteCollectionMutation,
  twinsUpdateSpeakerIDMutation,
} from 'graphql/mutations';
import { twinsGetCollectionQuery, twinsGetFileQuery } from 'graphql/queries';
import { debounce, omit } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useFileContext } from './state';
import { useSnackbar } from 'use/snackbar';
import { useSuperfeelUser } from 'use/superfeel-user';
import { useUser } from 'use/user';
import { v4 as uuid } from 'uuid';
import { CollectionType, FileMedallion, SortDirection } from 'types/enums';

export const useFileAPI = () => {
  const {
    files,
    setUpdatingFile,
    setDeletingFile,
    setFiles,
    setCollections,
    setCreatingCollection,
    setFile,
    collections,
    setDeletingCollection,
  } = useFileContext();

  const { user, getJWT } = useUser();
  const { superfeelUser } = useSuperfeelUser();
  const { showSnackbar } = useSnackbar();

  const updateFileBase = useCallback(
    async (file: File) => {
      setUpdatingFile(true);
      try {
        const updatedFile = {
          ...file,
          id: file.id || uuid(),
          createdBy: user?.id,
          username: superfeelUser?.username,
          userID: superfeelUser?.userId,
          updatedAt: new Date().toISOString(),
        };
        setFile(updatedFile);

        let updatedFiles = files ? [...files] : [];
        const fileIndex = updatedFiles.findIndex(
          (f) => f.id === updatedFile.id,
        );
        if (fileIndex !== -1) {
          updatedFiles[fileIndex] = updatedFile;
        } else {
          updatedFiles.push(updatedFile);
        }

        updatedFiles.sort(
          (a, b) =>
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
        );

        setFiles(updatedFiles);

        const input = {
          ...omit(updatedFile, ['createdAt', 'updatedAt']),
        };
        const jwt = await getJWT();
        const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
          twinsUpdateFileMutation,
          { input },
          jwt,
        );
        if (data) {
          const twinsUpdateFileResponse = data as {
            twinsUpdateFile: {
              success: boolean;
              message: string;
            };
          };
          if (!twinsUpdateFileResponse.twinsUpdateFile.success) {
            showSnackbar(
              `Update file error: ${twinsUpdateFileResponse.twinsUpdateFile.message}`,
              'error',
            );
          }
        }
      } catch (error) {
        showSnackbar(`Error updating file: ${error as string}`, 'error');
      } finally {
        setUpdatingFile(false);
      }
    },
    [
      setUpdatingFile,
      user?.id,
      superfeelUser?.username,
      superfeelUser?.userId,
      setFile,
      files,
      setFiles,
      getJWT,
      showSnackbar,
    ],
  );
  const updateFile = useMemo(
    () => debounce(updateFileBase, 500),
    [updateFileBase],
  );

  const deleteFile = useCallback(
    async (fileId: string) => {
      try {
        setDeletingFile(true);
        const jwt = await getJWT();
        const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
          twinsDeleteFileMutation,
          { input: { id: fileId } },
          jwt,
        );

        if (data) {
          const twinsDeleteFileResponse = data as {
            twinsDeleteFile: {
              success: boolean;
              message: string;
            };
          };
          if (twinsDeleteFileResponse.twinsDeleteFile.success) {
            const updatedFiles = files?.filter((file) => file.id !== fileId);
            setFiles(updatedFiles);
            showSnackbar('File deleted successfully', 'success');
          } else {
            showSnackbar(
              `Failed to delete file: ${twinsDeleteFileResponse.twinsDeleteFile.message}`,
              'error',
            );
          }
        }
      } catch (error) {
        showSnackbar(`Error deleting file: ${error as string}`, 'error');
      } finally {
        setDeletingFile(false);
      }
    },
    [setDeletingFile, getJWT, files, setFiles, showSnackbar],
  );

  const createCollection = useCallback(
    async (collectionData: Partial<Collection>) => {
      try {
        const input = {
          id: uuid(),
          ...omit(collectionData, ['createdAt', 'updatedAt']),
          createdBy: user?.id,
          username: superfeelUser?.username,
          userID: superfeelUser?.userId,
        };
        const newCollections = collections ? [...collections, input] : [input];
        setCollections(newCollections);
        const jwt = await getJWT();
        const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
          twinsUpdateCollectionMutation,
          { input },
          jwt,
        );

        if (data) {
          const twinsCreateCollectionResponse = data as {
            twinsUpdateCollection: {
              success: boolean;
              message: string;
            };
          };

          if (!twinsCreateCollectionResponse.twinsUpdateCollection.success) {
            showSnackbar(
              `Failed to create collection: ${twinsCreateCollectionResponse.twinsUpdateCollection.message}`,
              'error',
            );
            return null;
          } else {
            showSnackbar('Collection created successfully', 'success');
            return input;
          }
        }
      } catch (error) {
        showSnackbar(`Error creating collection: ${error as string}`, 'error');
        return null;
      }
    },
    [
      user?.id,
      superfeelUser?.username,
      superfeelUser?.userId,
      collections,
      setCollections,
      getJWT,
      showSnackbar,
    ],
  );

  const updateCollectionBase = useCallback(
    async (collectionUpdate: Partial<Collection>) => {
      try {
        setCreatingCollection(true);
        const twinsUpdateCollectionInput = {
          id: collectionUpdate.id || uuid(),
          ...collectionUpdate,
          createdBy: user?.id,
          username: superfeelUser?.username,
          userID: superfeelUser?.userId,
        };
        const updatedCollections: Collection[] = collections
          ? collections.map((c) =>
              c.id === twinsUpdateCollectionInput.id
                ? { ...c, ...twinsUpdateCollectionInput }
                : c,
            )
          : [twinsUpdateCollectionInput];

        setCollections(updatedCollections);
        const input = {
          ...omit(twinsUpdateCollectionInput, ['createdAt', 'updatedAt']),
        };
        const jwt = await getJWT();
        const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
          twinsUpdateCollectionMutation,
          { input },
          jwt,
        );

        if (data) {
          const twinsUpdateCollectionResponse = data as {
            twinsUpdateCollection: {
              success: boolean;
              message: string;
            };
          };

          if (!twinsUpdateCollectionResponse.twinsUpdateCollection.success) {
            showSnackbar(
              `Failed to update collection: ${twinsUpdateCollectionResponse.twinsUpdateCollection.message}`,
              'error',
            );
          }
        }
      } catch (error) {
        showSnackbar(`Error updating collection: ${error as string}`, 'error');
      } finally {
        setCreatingCollection(false);
      }
    },
    [
      setCreatingCollection,
      user?.id,
      superfeelUser?.username,
      superfeelUser?.userId,
      collections,
      setCollections,
      getJWT,
      showSnackbar,
    ],
  );

  const updateCollection = useMemo(
    () => debounce(updateCollectionBase, 500),
    [updateCollectionBase],
  );

  const getCollections = useCallback(
    async (type?: CollectionType) => {
      try {
        const input: { userID: string | undefined; type?: CollectionType } = {
          userID: superfeelUser?.userId,
        };
        if (type) {
          input.type = type;
        }
        const jwt = await getJWT();
        const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
          twinsGetCollectionQuery,
          { input },
          jwt,
        );
        console.log('Collections data:', data);
        if (data) {
          const result = data as {
            twinsGetCollection: {
              success: boolean;
              message: string;
              data: Collection[];
            };
          };
          if (
            result.twinsGetCollection.success &&
            result.twinsGetCollection.data?.length > 0
          ) {
            setCollections(result.twinsGetCollection.data);
            return result.twinsGetCollection.data;
          }
        }
        return [];
      } catch (e) {
        showSnackbar(`Failed to fetch collections: ${e as string}`, 'error');
        return [];
      }
    },
    [getJWT, setCollections, showSnackbar, superfeelUser?.userId],
  );

  const getFilesByCollectionID = useCallback(
    async (collectionId: string, medallion?: FileMedallion) => {
      try {
        const input = {
          collectionID: collectionId,
          medallion: medallion,
          sort: SortDirection.ASC,
        };
        const jwt = await getJWT();
        const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
          twinsGetFileQuery,
          { input },
          jwt,
        );
        if (data) {
          const result = data as {
            twinsGetFile: {
              success: boolean;
              message: string;
              data: File[];
            };
          };
          if (result.twinsGetFile.success) {
            console.log('result.twinsGetFile.data', result.twinsGetFile.data);
            if (medallion) {
              const medallionFiles = result.twinsGetFile.data.filter(
                (file) => file.medallion === medallion,
              );
              setFiles(medallionFiles);
            } else {
              setFiles(result.twinsGetFile.data);
            }
            return result.twinsGetFile.data;
          }
        }
      } catch (e) {
        showSnackbar(`Failed to fetch files: ${e as string}`, 'error');
      }
    },
    [getJWT, setFiles, showSnackbar],
  );

  const getFile = useCallback(
    async (fileId: string) => {
      try {
        const input = {
          id: fileId,
        };
        const jwt = await getJWT();
        const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
          twinsGetFileQuery,
          { input },
          jwt,
        );
        console.log('getFileByID', input, data);
        if (data) {
          const result = data as {
            twinsGetFile: {
              success: boolean;
              message: string;
              data: File[];
            };
          };
          if (
            result.twinsGetFile.success &&
            result.twinsGetFile.data.length > 0
          ) {
            console.log('result.twinsGetFile.data', result.twinsGetFile.data);
            setFile(result.twinsGetFile.data[0]);
            return result.twinsGetFile.data[0];
          }
        }
      } catch (e) {
        showSnackbar(`Failed to fetch file: ${e as string}`, 'error');
      }
    },
    [getJWT, setFile, showSnackbar],
  );

  const deleteCollection = useCallback(
    async (collectionID: string) => {
      try {
        setDeletingCollection(true);
        const jwt = await getJWT();
        const input = {
          collectionID,
        };

        const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
          twinsDeleteCollectionMutation,
          { input },
          jwt,
        );
        if (data) {
          const twinsDeleteCollectionResponse = data as {
            twinsDeleteCollection: {
              success: boolean;
              message: string;
            };
          };
          if (twinsDeleteCollectionResponse.twinsDeleteCollection.success) {
            const updatedCollections = collections?.filter(
              (collection) => collection.id !== collectionID,
            );
            setCollections(updatedCollections);
            showSnackbar('Collection deleted successfully', 'success');
          } else {
            showSnackbar(
              `Failed to delete collection: ${twinsDeleteCollectionResponse.twinsDeleteCollection.message}`,
              'error',
            );
          }
        }
      } catch (error) {
        showSnackbar(`Error deleting collection: ${error as string}`, 'error');
      } finally {
        setDeletingCollection(false);
      }
    },
    [setDeletingCollection, getJWT, collections, setCollections, showSnackbar],
  );

  const updateSpeakerIDs = useCallback(
    async (file: File, speakerID: string[]): Promise<void> => {
      try {
        const input: TwinsUpdateSpeakerIdInput = {
          fileID: file?.id,
          speakerID,
          userID: superfeelUser?.userId,
          updatedBy: user?.id,
          collectionID: file?.collectionID,
          fileName: file?.name,
        };
        const jwt = await getJWT();
        const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
          twinsUpdateSpeakerIDMutation,
          { input },
          jwt,
        );
        console.log('updateSpeakerIDs', input, data);
        if (data) {
          const twinsUpdateSpeakerIDResponse = data as {
            twinsUpdateSpeakerID: {
              success: boolean;
              message: string;
            };
          };
          if (!twinsUpdateSpeakerIDResponse.twinsUpdateSpeakerID.success) {
            showSnackbar(
              `Failed to delete collection: ${twinsUpdateSpeakerIDResponse.twinsUpdateSpeakerID.message}`,
              'error',
            );
          }
        }
      } catch (error) {
        showSnackbar(`Error deleting collection: ${error as string}`, 'error');
      } finally {
        setDeletingCollection(false);
      }
    },
    [
      getJWT,
      setDeletingCollection,
      showSnackbar,
      superfeelUser?.userId,
      user?.id,
    ],
  );

  return {
    updateSpeakerIDs,
    deleteCollection,
    getFile,
    createCollection,
    getCollections,
    updateCollection,
    updateFile,
    updateFileBase,
    getFilesByCollectionID,
    deleteFile,
    updateCollectionBase,
  };
};
