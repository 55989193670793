import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  FormControl,
  Box,
  Typography,
  TextField,
  Stack,
  Grid,
} from '@mui/material';
import { InfoLabel, Select, Section, Slider } from 'components/atoms';
import { useModelConfig } from 'use/model-config';
import {
  AIProvider,
  AIType,
  MODEL_PROVIDERS,
  PromptPosition,
} from 'types/enums';
import { Model, ModelOptions, Prompt } from '@twins/types';
import { menuOptions } from 'common/utils';
import { useSnackbar } from 'use/snackbar';
import InfoTextField from 'components/atoms/info-text-field';
import { capitalizeFirstSentence } from 'utils/capitalizeFirstSentence';

export function ModelHome() {
  const { prompts, setPrompts, models, setModels, changed, setChanged } =
    useModelConfig();
  const findPromptByPosition = (position: PromptPosition): Prompt | undefined =>
    prompts.find((prompt) => prompt.position === position);
  const startPrompt = findPromptByPosition(PromptPosition.START);
  const mainPrompt = findPromptByPosition(PromptPosition.MAIN);
  const llmModelIndex = models.findIndex((m) => m.type === AIType.LLM);
  const llmModel = models[llmModelIndex];
  const availableProviders = Object.keys(
    MODEL_PROVIDERS[AIType.LLM] || {},
  ) as AIProvider[];
  const selectedProvider = llmModel?.provider || availableProviders[0];
  let selectedModel = llmModel?.model || 'gpt-4o';
  const { showSnackbar } = useSnackbar();
  const [firstMessage, setFirstMessage] = useState(startPrompt?.content || '');
  const [systemPrompt, setSystemPrompt] = useState<string>('');

  const parseLLMModelOptions = (): Model | undefined => {
    if (llmModel?.options && typeof llmModel.options === 'string') {
      try {
        const parsedOptions = llmModel.options as ModelOptions;
        return {
          ...llmModel,
          options: parsedOptions,
        };
      } catch (error) {
        showSnackbar('Failed to parse model options', 'error');
      }
    }
    return llmModel;
  };

  const parsedLLMModel = parseLLMModelOptions();

  const changeStatus = useCallback(
    (value: boolean): void => {
      if (!changed) {
        setChanged(value);
      }
    },
    [changed, setChanged],
  );

  const updatePrompt = useCallback(
    ({ position, content }: { position: PromptPosition; content: string }) => {
      const updatedPrompts = prompts.map((prompt) =>
        prompt.position === position
          ? {
              ...prompt,
              content: content,
            }
          : prompt,
      );
      setPrompts(updatedPrompts);
      changeStatus(true);
    },
    [changeStatus, prompts, setPrompts],
  );

  const updateModel = (updatedModel: Partial<Model>): void => {
    if (parsedLLMModel) {
      const newModel: Model = {
        ...parsedLLMModel,
        ...updatedModel,
        options: {
          ...parsedLLMModel.options,
          ...(updatedModel.options || {}),
        },
      };
      const updatedModels = models.map((model) =>
        model.type === AIType.LLM ? newModel : model,
      );
      setModels(updatedModels);
      changeStatus(true);
    }
  };

  const handleTemperatureChange = (value: number): void =>
    updateModel({ options: { temperature: value } });

  const handleMaxTokensChange = (value: number): void =>
    updateModel({ options: { maxTokens: value } });

  const handleProviderChange = (value: string): void => {
    const provider = value as keyof (typeof MODEL_PROVIDERS)[typeof AIType.LLM];
    updateModel({ provider });
  };

  const handleModelChange = (value: string): void =>
    updateModel({ model: value });

  const getOptionValue = (
    key: keyof ModelOptions,
    defaultValue: number,
  ): number => {
    if (parsedLLMModel?.options && typeof parsedLLMModel.options === 'object') {
      const value = parsedLLMModel.options[key];
      return typeof value === 'number' ? value : defaultValue;
    }
    return defaultValue;
  };

  const temperature = getOptionValue('temperature', 0.2);
  const maxTokens = getOptionValue('maxTokens', 512);

  const onFirstMessageBlur = useCallback(
    (value: string) => {
      updatePrompt({
        content: value,
        position: PromptPosition.START,
      });
    },
    [updatePrompt],
  );

  const onSystemPromptBlur = (value: string): void =>
    updatePrompt({
      content: value,
      position: PromptPosition.MAIN,
    });

  useEffect(() => {
    setFirstMessage(startPrompt?.content || '');
  }, [startPrompt?.content]);

  useEffect(() => {
    setSystemPrompt(mainPrompt?.content || '');
  }, [mainPrompt?.content]);

  const providersList = useMemo(() => {
    return availableProviders?.map((p) => {
      const label = p === 'openai' ? 'Open AI' : p;
      return {
        label: capitalizeFirstSentence(label),
        value: p,
      };
    });
  }, [availableProviders]);

  const availableModels = useMemo(
    () => MODEL_PROVIDERS[AIType.LLM]?.[selectedProvider] || [],
    [selectedProvider],
  );
  const activeModel = useMemo(() => {
    return availableModels.includes(selectedModel)
      ? selectedModel
      : availableModels[0];
  }, [availableModels, selectedModel]);

  return (
    <FormControl fullWidth>
      <Box mb={2}>
        <Typography
          color="white"
          variant="h4"
        >
          Model
        </Typography>
        <Typography variant="caption">
          This section allows you to configure the model for the twin.
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          md={8}
          xs={12}
        >
          <Stack
            display="flex"
            flexDirection="column"
            width="100%"
            spacing={2}
          >
            <InfoTextField<string>
              value={startPrompt?.content || ''}
              onChange={setFirstMessage}
              onBlur={() => onFirstMessageBlur(firstMessage)}
              label="First Message"
              multiline
              tooltip="The first message that the assistant will say. This can also be a URL to a containerized audio file (mp3, wav, etc.)."
            />
            <InfoTextField<string>
              value={systemPrompt}
              onChange={setSystemPrompt}
              onBlur={() => onSystemPromptBlur(systemPrompt)}
              label="System Prompt"
              tooltip="The system prompt can be used to configure the context, role, personality, instructions and so on for the assistant."
              placeholder={'Main system prompt'}
              multiline
              minRows={10}
            />
          </Stack>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
        >
          <Stack
            direction="column"
            spacing={2}
            sx={{ pt: 1 }}
          >
            <Section label="Provider">
              <Select
                value={selectedProvider || AIProvider.OPENAI}
                defaultValue={AIProvider.OPENAI}
                options={providersList}
                onChange={handleProviderChange}
              />
            </Section>
            <Section label="Model">
              <Select
                value={activeModel || 'gpt-4o'}
                defaultValue={'gpt-4o'}
                options={menuOptions(availableModels)}
                onChange={handleModelChange}
              />
            </Section>
            <Section label="Max Tokens">
              <TextField
                value={maxTokens}
                onChange={(e) => handleMaxTokensChange(Number(e.target.value))}
                type="number"
                size="small"
                placeholder="Numerical value, e.g. 250"
                fullWidth
              />
            </Section>
            <Section>
              <InfoLabel
                label="Temperature"
                tooltip={`The temperature is used to control the randomness of the output. 
                  When you set it higher, you'll get more random outputs. 
                  When you set it lower, towards 0, the values are more deterministic."`}
              />
              <Box>
                <Slider
                  value={temperature}
                  setValue={handleTemperatureChange}
                  columns={1}
                  max={1}
                  step={0.1}
                  sx={{ marginTop: 2 }}
                  defaultValue={0.2}
                  slots={{ valueLabel: Typography }}
                />
              </Box>
            </Section>
          </Stack>
        </Grid>
      </Grid>
    </FormControl>
  );
}
