import { useSnackbarContext, SnackbarProvider } from './state';

export const useSnackbar = () => {
  const context = useSnackbarContext();

  return {
    ...context,
  };
};

export { SnackbarProvider };
