// pkg/frontend/app/src/components/atoms/date-select/index.tsx
import { DateTime } from 'luxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, SxProps } from '@mui/material';

interface DateSelectProps {
  boxStyles: SxProps;
  value?: DateTime | null;
  onChange?: (newValue: DateTime | null) => void;
}

export default function DateSelect({
  boxStyles,
  value,
  onChange,
}: DateSelectProps) {
  return (
    <Box sx={boxStyles}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DatePicker
          sx={{ width: '100%' }}
          value={value}
          slotProps={{ textField: { size: 'small' } }}
          onChange={onChange}
        />
      </LocalizationProvider>
    </Box>
  );
}
