import { InfoOutlined } from '@mui/icons-material';
import { Box, FormLabel, IconButton, Stack, Tooltip } from '@mui/material';

interface InfoLabelProps {
  label: string;
  tooltip: string;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
    | undefined;
  endAction?: React.ReactNode;
}

export default function InfoLabel({
  label,
  tooltip,
  placement,
  endAction,
}: InfoLabelProps) {
  return (
    <Stack
      sx={{ my: 1 }}
      direction="row"
      alignItems="center"
      justifyContent={endAction ? 'space-between' : 'flex-start'}
    >
      <Box>
        <FormLabel>{label}</FormLabel>
        <Tooltip
          placement={placement}
          title={tooltip}
        >
          <IconButton
            color="warning"
            size="small"
          >
            <InfoOutlined sx={{ height: 15 }} />
          </IconButton>
        </Tooltip>
      </Box>
      {endAction && endAction}
    </Stack>
  );
}
