import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

type EnumSelectProps<T extends string | number> = {
  enumOptions: Record<string, T>;
  value?: T;
  defaultValue?: T;
  onChange: (value: T) => void;
  label?: string;
  fullWidth?: boolean;
  size?: 'small' | 'medium';
};

export function EnumSelect<T extends string | number>({
  enumOptions,
  value,
  defaultValue,
  onChange,
  label,
  fullWidth,
  size,
}: EnumSelectProps<T>) {
  const handleChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = enumOptions[event.target.value];
    onChange(selectedValue);
  };

  return (
    <Select
      value={
        value
          ? Object.keys(enumOptions).find((key) => enumOptions[key] === value)
          : ''
      }
      defaultValue={
        defaultValue
          ? Object.keys(enumOptions).find(
              (key) => enumOptions[key] === defaultValue,
            )
          : ''
      }
      onChange={handleChange}
      label={label}
      fullWidth={fullWidth}
      size={size}
    >
      {Object.entries(enumOptions).map(([key, enumValue]) => (
        <MenuItem
          key={key}
          value={key}
        >
          {String(enumValue)}
        </MenuItem>
      ))}
    </Select>
  );
}
