import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

export function AnalyticsPage() {
  const sentryDisabled = process.env.DISABLE_SENTRY;
  const dsn = process.env.REACT_APP_SENTRY_DSN;

  // Sentry
  if (dsn) {
    Sentry.init({
      dsn,
      enabled: !!sentryDisabled,
      environment: process.env.NODE_ENV || 'unknown',
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        // Sentry.replayIntegration({
        //   maskAllText: true,
        //   maskAllInputs: true,
        // }),
      ],
      // replaysSessionSampleRate: 0.1,
      // replaysOnErrorSampleRate: 1.0,
      tracesSampleRate: 0.6,
    });
  }

  return null;
}
