import { useModelConfigAPI } from './api';
import { ModelConfigProvider, useModelConfigContext } from './state';

export const useModelConfig = () => {
  const context = useModelConfigContext();
  const api = useModelConfigAPI();

  return {
    ...context,
    ...api,
  };
};

export { ModelConfigProvider };
