import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import {
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  CircularProgress,
  Stack,
  Tooltip,
  ToggleButton,
  Paper,
  Typography,
} from '@mui/material';
import { SampleData } from '@twins/types';
import { MetadataGroup, StyledToggleButtonGroup } from 'components/atoms';
import WaveSurfer from 'wavesurfer.js';
import PauseIcon from '@mui/icons-material/Pause';
import { PlayArrow } from '@mui/icons-material';
import { useSuperfeelUser } from 'use/superfeel-user';
import { useMedia } from 'use/media';

interface FileReviewSampleProps {
  sample: SampleData;
  speakerIDs: string[];
  onSpeakerChange?: (newSpeakerID: string) => void;
}

export default function FileReviewSample({
  sample,
  speakerIDs,
  onSpeakerChange,
}: FileReviewSampleProps) {
  const waveformRef = useRef<HTMLDivElement>(null);
  const wavesurferRef = useRef<WaveSurfer | null>(null);
  const isInitializedRef = useRef(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { superfeelUser } = useSuperfeelUser();
  const abortControllerRef = useRef<AbortController | null>(null);
  const { getCloudfrontUrl } = useMedia();

  const getSpeakerIDFromLabel = useCallback(
    (label: string) => {
      const speakerIndex = parseInt(label.split('_')[1], 10);
      return speakerIDs[speakerIndex] || '';
    },
    [speakerIDs],
  );

  const selectedSpeakerID = useMemo(() => {
    return getSpeakerIDFromLabel(sample.transcript?.speaker || '');
  }, [sample.transcript?.speaker, getSpeakerIDFromLabel]);

  const handleSpeakerChange = (
    event: React.MouseEvent<HTMLElement>,
    newSpeakerID: string,
  ) => {
    if (newSpeakerID !== null) {
      onSpeakerChange?.(newSpeakerID);
    }
  };

  const loadAudioDirectly = useCallback(async (url: string) => {
    if (!wavesurferRef.current) return;
    await wavesurferRef.current.load(url);
  }, []);

  const initializeWaveSurfer = useCallback(async () => {
    if (waveformRef.current && !isInitializedRef.current) {
      try {
        setIsLoading(true);

        abortControllerRef.current = new AbortController();
        const signal = abortControllerRef.current.signal;

        const s3UriParts = sample.audioURI.replace('s3://', '').split('/');
        const key = s3UriParts.slice(1).join('/');

        const result = await getCloudfrontUrl(key);

        if (signal.aborted) return;

        if (!result) {
          throw new Error('Failed to get signed URL');
        }

        const signedUrl = result;

        if (!wavesurferRef.current) {
          wavesurferRef.current = WaveSurfer.create({
            height: 100,
            container: waveformRef.current,
            waveColor: 'grey',
            progressColor: 'white',
            backend: 'MediaElement',
          });

          wavesurferRef.current.on('ready', () => {
            if (!signal.aborted) setIsLoading(false);
          });
          wavesurferRef.current.on('error', (err) => {
            console.error('WaveSurfer error:', err);
            setIsLoading(false);
          });
          wavesurferRef.current.on('play', () => {
            if (!signal.aborted) setIsPlaying(true);
          });
          wavesurferRef.current.on('pause', () => {
            if (!signal.aborted) setIsPlaying(false);
          });
        }

        await loadAudioDirectly(signedUrl);

        if (!signal.aborted) isInitializedRef.current = true;
      } catch (error) {
        console.error('Error loading audio:', error);
        setIsLoading(false);
      }
    }
  }, [getCloudfrontUrl, sample.audioURI, loadAudioDirectly]);

  useEffect(() => {
    initializeWaveSurfer();

    return () => {
      // Cleanup function
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
        abortControllerRef.current = null;
      }

      // Separate WaveSurfer cleanup
      if (wavesurferRef.current) {
        wavesurferRef.current.unAll(); // Remove all event listeners
        wavesurferRef.current.destroy();
        wavesurferRef.current = null;
      }
      isInitializedRef.current = false;
    };
  }, [initializeWaveSurfer]);

  const handlePlayPause = () => {
    if (wavesurferRef.current) {
      wavesurferRef.current.playPause();
    }
  };

  return (
    <Box sx={{ my: 2 }}>
      <Card>
        <Box
          display="flex"
          justifyContent={'space-between'}
          p={1}
        >
          <MetadataGroup
            items={[
              { label: 'Label', value: sample.transcript?.speaker },
              {
                label: 'Start Time',
                value: sample.transcript?.startTime,
              },
              {
                label: 'End Time',
                value: sample.transcript?.endTime,
              },
            ]}
          />
          <Paper elevation={0}>
            <StyledToggleButtonGroup
              size="small"
              value={selectedSpeakerID}
              onChange={handleSpeakerChange}
              exclusive
            >
              <ToggleButton
                value={superfeelUser.userId}
                aria-label="left aligned"
              >
                {superfeelUser.firstName}
              </ToggleButton>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ opacity: 0.5 }}
              />
              <ToggleButton
                value="interviewer"
                aria-label="left aligned"
              >
                Interviewer
              </ToggleButton>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ opacity: 0.5 }}
              />
              <ToggleButton
                value="other"
                aria-label="left aligned"
              >
                Other
              </ToggleButton>
            </StyledToggleButtonGroup>
          </Paper>
        </Box>
        <Divider sx={{ opacity: 0.2 }} />
        <CardContent>
          <Stack
            direction="row"
            alignItems={'center'}
            spacing={2}
          >
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Tooltip
                title={isPlaying ? 'Pause' : 'Play'}
                placement="top"
              >
                <IconButton
                  size="large"
                  onClick={handlePlayPause}
                  disabled={isLoading}
                >
                  {isPlaying ? (
                    <PauseIcon fontSize="large" />
                  ) : (
                    <PlayArrow fontSize="large" />
                  )}
                </IconButton>
              </Tooltip>
            )}
            <Box
              sx={{ width: '100%', minHeight: '100%' }}
              ref={waveformRef}
            />
          </Stack>
          <Box my={2}>
            <Typography
              color="grey"
              sx={{ p: 0, mt: 0, fontWeight: 600 }}
              variant="h5"
            >
              {sample.transcript?.transcript}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
