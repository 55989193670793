import { useJobAPI } from './api';
import { JobContextProvider, useJobContext } from './state';

export const useJob = () => {
  const context = useJobContext();
  const api = useJobAPI();

  return {
    ...context,
    ...api,
  };
};

export { JobContextProvider };
