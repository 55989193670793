import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  Grid,
  Stack,
  Typography,
  Tabs,
  Tab,
} from '@mui/material';
import { executeGraphqlOperation } from 'api';
import { GraphQLResult } from 'aws-amplify/api';
import { useSuperfeelUser } from 'use/superfeel-user';
import { Link } from 'react-router-dom';
import { NewModelConfig } from 'components/molecules';
import { formatDate } from 'common/utils';
import { green } from '@mui/material/colors';
import { ModelConfig } from '@twins/types';
import { twinsGetModelConfigQuery } from 'graphql/queries';
import { ButtonIconTooltip } from 'components/atoms';
import { useUser } from 'use/user';

export default function UserModels() {
  const [modelConfigs, setModelConfigs] = useState<ModelConfig[]>([]);
  const { superfeelUser } = useSuperfeelUser();
  const [openCreateModel, setOpenCreateModel] = useState(false);
  const [value, setValue] = useState<number>(0);
  const { getJWT } = useUser();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getModelConfig = useCallback(async () => {
    const input = {
      userID: superfeelUser?.userId,
    };
    const jwt = await getJWT();
    const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
      twinsGetModelConfigQuery,
      { input },
      jwt,
    );
    if (data) {
      const result = data as {
        twinsGetModelConfig: {
          data: ModelConfig[];
        };
      };
      setModelConfigs(result.twinsGetModelConfig.data);
    }
  }, [getJWT, superfeelUser?.userId]);

  useEffect(() => {
    getModelConfig();
  }, [getModelConfig]);

  const handleUpdate = () => {
    getModelConfig();
  };

  const sortModelConfigs = (a: ModelConfig, b: ModelConfig) => {
    const sortKey = value === 0 ? 'updatedAt' : 'createdAt';
    return (
      new Date(b[sortKey] as string).getTime() -
      new Date(a[sortKey] as string).getTime()
    );
  };

  const groupedModelConfigs = modelConfigs?.sort(sortModelConfigs).reduce(
    (acc, modelConfig) => {
      const sortKey = value === 0 ? 'updatedAt' : 'createdAt';
      const groupDate = formatDate(modelConfig[sortKey] as string, 'dd-MMM-yy');
      if (!acc[groupDate]) {
        acc[groupDate] = [];
      }
      acc[groupDate].push(modelConfig);
      return acc;
    },
    {} as Record<string, ModelConfig[]>,
  );

  return (
    <Box>
      <Stack
        direction="row"
        sx={{ alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
        >
          <Typography variant="h3">Twin</Typography>
          <ButtonIconTooltip
            isLoading={false}
            onClick={() => setOpenCreateModel(true)}
            tooltipTitle="Add Twin"
            icon="add"
            tooltipColorVariant="info"
          />
        </Stack>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="sort options"
          sx={{ minHeight: 0 }}
        >
          <Tab
            sx={{ fontWeight: 'bold', py: 1.5, minHeight: 4 }}
            label="Updated"
            id="tab-0"
            aria-controls="created-at"
          />
          <Tab
            sx={{ fontWeight: 'bold', py: 1.5, minHeight: 4 }}
            label="Created"
            id="tab-1"
            aria-controls="updated-at"
          />
        </Tabs>
      </Stack>
      <Divider />
      <Box
        width="100%"
        mb={4}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      ></Box>
      <NewModelConfig
        title="New Twin"
        setOpen={setOpenCreateModel}
        open={openCreateModel}
        onUpdate={handleUpdate}
      />
      {groupedModelConfigs &&
        Object.entries(groupedModelConfigs).map(([date, modelConfigItems]) => (
          <Box
            key={date}
            mb={4}
          >
            <Typography
              variant="h4"
              gutterBottom
            >
              {date}
            </Typography>
            <Divider sx={{ my: 1, opacity: 0.3 }} />
            <Grid
              container
              spacing={2}
            >
              {modelConfigItems.map((modelConfig) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  key={modelConfig.id}
                >
                  <Card>
                    <Link
                      style={{ textDecoration: 'none', color: 'inherit' }}
                      to={`/user/${superfeelUser?.username}/twin/${modelConfig.id}`}
                    >
                      <CardActionArea>
                        <CardContent>
                          <Typography variant="h5">
                            {modelConfig.name}
                          </Typography>
                          <Typography variant="body2">
                            {modelConfig.description}
                          </Typography>
                          <Typography
                            color={modelConfig.active ? green[500] : 'grey'}
                            variant="body2"
                          >
                            {modelConfig.active ? 'Active' : 'Inactive'}
                          </Typography>
                          <Typography variant="body2">
                            Created: {formatDate(modelConfig.createdAt)}
                          </Typography>
                          <Typography variant="body2">
                            Updated: {formatDate(modelConfig.updatedAt)}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Link>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}
    </Box>
  );
}
