import { GraphQLQuery, generateClient } from 'aws-amplify/api';
import { AppsyncErrorResponse, appsyncErrorConverter } from './errors';

export async function executeGraphqlOperation<T>(
  query: string,
  variables: Record<string, unknown>,
  authToken: string,
) {
  const client = generateClient({ authToken, authMode: 'lambda' });
  try {
    const res = await client.graphql<GraphQLQuery<T>>({
      query,
      variables: variables as unknown as undefined, // HACK: Gets around unnecessarily complex amplify types
    });

    return res;
  } catch (err: unknown) {
    throw appsyncErrorConverter(err as AppsyncErrorResponse, query, variables);
  }
}
