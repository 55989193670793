import { createTheme } from '@mui/material';
import palette from './palette';
import typography from './typography';
import components from './components';
import transitions from './transitions';

export const darkTheme = createTheme({
  palette,
  typography,
  shape: {
    borderRadius: 8,
  },
  components,
  transitions,
});
