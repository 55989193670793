import { Box, Stack, Typography } from '@mui/material';

interface ModelSectionProps {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
}

export function ModelSettingsSection({
  title,
  subtitle,
  children,
}: ModelSectionProps) {
  return (
    <Box
      component="section"
      display="flex"
      flexDirection="column"
      width="100%"
    >
      <Stack
        direction="column"
        spacing={1}
      >
        <Typography variant="h4">{title}</Typography>
        {subtitle && <Typography variant="caption">{subtitle}</Typography>}
      </Stack>
      <Box my={2}>{children}</Box>
    </Box>
  );
}
