import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useMemo,
  useCallback,
} from 'react';
import { Job, JobLog } from '@twins/types';

interface JobContextState {
  job: Job | null;
  setJob: (job: Job | null) => void;
  jobs: Job[] | null;
  setJobs: (jobs: Job[] | null) => void;
  jobLogs: JobLog[] | null;
  setJobLogs: (jobLogs: JobLog[] | null) => void;
  gettingJobs: boolean;
  setGettingJobs: (gettingJobs: boolean) => void;

  reset: (...keys: (keyof JobContextState)[]) => void;
}

const JobContext = createContext<JobContextState | undefined>(undefined);

export const useJobContext = () => {
  const context = useContext(JobContext);
  if (!context) {
    throw new Error('useJobContext must be used within a JobContextProvider');
  }
  return context;
};

interface JobContextProviderProps {
  children: ReactNode;
}

export function JobContextProvider({
  children,
}: JobContextProviderProps): JSX.Element {
  const [job, setJob] = useState<Job | null>(null);
  const [jobs, setJobs] = useState<Job[] | null>(null);
  const [jobLogs, setJobLogs] = useState<JobLog[] | null>(null);
  const [gettingJobs, setGettingJobs] = useState(false);

  const reset = useCallback((...keys: (keyof JobContextState)[]) => {
    keys.forEach((key) => {
      switch (key) {
        case 'job':
          setJob(null);
          break;
        case 'jobs':
          setJobs(null);
          break;
        case 'jobLogs':
          setJobLogs(null);
          break;
        default:
          console.warn(`Unrecognized key: ${key}`);
      }
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      job,
      setJob,
      jobs,
      setJobs,
      jobLogs,
      setJobLogs,
      gettingJobs,
      setGettingJobs,

      reset,
    }),
    [gettingJobs, job, jobLogs, jobs, reset],
  );

  return (
    <JobContext.Provider value={contextValue}>{children}</JobContext.Provider>
  );
}
