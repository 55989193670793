import { DateTime } from 'luxon';
import { SuperfeelUserResult } from 'types/api';

export interface UserField {
  label: string;
  key: keyof SuperfeelUserResult;
  format?: (value: unknown) => string;
}

const formatDate = (date: unknown): string => {
  if (!date) return '';
  return DateTime.fromISO(date as string).toFormat('dd-LLL-yy HH:mm');
};

export const userFields: UserField[] = [
  { label: 'First Name', key: 'firstName' },
  { label: 'Last Name', key: 'lastName' },
  { label: 'Username', key: 'username' },
  { label: 'Date Created', key: 'dateCreated', format: formatDate },
];

export const dataReadiness: UserField[] = [
  { label: 'Posts Count', key: 'postsCount' },
];

export const statFields: UserField[] = [
  { label: 'Connected Count', key: 'connectedCount' },
  { label: 'Follows Count', key: 'followsCount' },
  { label: 'Follower Count', key: 'followerCount' },
  { label: 'Hidden Count', key: 'hiddenCount' },
  { label: 'Views 3 Seconds', key: 'views3secs' },
  { label: 'Views 50%', key: 'views50pct' },
  { label: 'Views Complete', key: 'viewsComplete' },
];

export const AIStatusFields: UserField[] = [
  { label: 'Model Status', key: 'modelStatus' },
  { label: 'Graph Status', key: 'graphStatus' },
  { label: 'Enabled', key: 'enabled' },
];
