import { AutoMode, CheckCircle } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { formatDate } from 'common/utils';
import { BottomRow, MetadataGroup } from 'components/atoms';
import { FileStage, FileStatus } from 'types/enums';
import { useTwinsGetFileQuery } from 'services/collection.services.ts/file';
import { useParams } from 'react-router-dom';
import { useFile } from 'use/file';

interface FileBottomRowProps {
  onSubmit: () => void;
}

export default function FileBottomRow({ onSubmit }: FileBottomRowProps) {
  const { fileID } = useParams<{ fileID: string }>();
  const { submitting } = useFile();
  const { data } = useTwinsGetFileQuery({
    input: {
      id: fileID,
    },
  });
  const file = data?.twinsGetFile.data[0];
  return (
    <Box>
      <BottomRow
        information={
          <MetadataGroup
            items={[
              {
                label: 'Created At',
                value: formatDate(file?.createdAt || ''),
              },
              {
                label: 'Updated At',
                value: formatDate(file?.updatedAt || ''),
              },
            ]}
          />
        }
        action={
          <>
            {file?.stage === FileStage.process && (
              <LoadingButton
                disabled={
                  file?.stage !== FileStage.process ||
                  file?.status !== FileStatus.complete
                }
                size="small"
                sx={{ fontWeight: 600 }}
                variant="outlined"
                endIcon={<AutoMode />}
                loading={false}
                onClick={() => {
                  onSubmit();
                }}
              >
                Process
              </LoadingButton>
            )}
            {file?.stage !== FileStage.process && (
              <LoadingButton
                disabled={file?.stage !== FileStage.submit}
                size="small"
                sx={{ fontWeight: 600 }}
                variant="outlined"
                endIcon={<CheckCircle />}
                loading={submitting}
                onClick={() => {
                  onSubmit();
                }}
              >
                Submit
              </LoadingButton>
            )}
          </>
        }
      />
    </Box>
  );
}
