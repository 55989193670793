import { Job, JobLog } from '@twins/types';
import { executeGraphqlOperation } from 'api';
import { GraphQLResult } from 'aws-amplify/api';
import { twinsUpdateJobMutation } from 'graphql/mutations';
import { twinsGetJobLogQuery, twinsGetJobQuery } from 'graphql/queries';
import { debounce, omit } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useJobContext } from './state';
import { useSnackbar } from 'use/snackbar';
import { useSuperfeelUser } from 'use/superfeel-user';
import { useUser } from 'use/user';
import { v4 as uuid } from 'uuid';

export const useJobAPI = () => {
  const { setJob, setJobs, setJobLogs, setGettingJobs } = useJobContext();
  const { user, getJWT } = useUser();
  const { superfeelUser } = useSuperfeelUser();
  const { showSnackbar } = useSnackbar();

  const updateJobBase = useCallback(
    async (job?: Job) => {
      try {
        const input = {
          id: job?.id || uuid(),
          ...omit(job, ['createdAt', 'updatedAt']),
          createdBy: user?.id,
          username: superfeelUser?.username,
          userID: superfeelUser?.userId,
        };
        setJob(input);
        const jwt = await getJWT();
        const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
          twinsUpdateJobMutation,
          { input },
          jwt,
        );
        if (data) {
          const twinsUpdateJobResponse = data as {
            twinsUpdateJob: {
              success: boolean;
              message: string;
            };
          };
          if (!twinsUpdateJobResponse.twinsUpdateJob.success) {
            showSnackbar(
              `Failed to update job: ${twinsUpdateJobResponse.twinsUpdateJob.message}`,
              'error',
            );
          }
        }
      } catch (error) {
        showSnackbar(`Error updating job: ${error as string}`, 'error');
      }
    },
    [
      getJWT,
      setJob,
      showSnackbar,
      superfeelUser?.userId,
      superfeelUser?.username,
      user?.id,
    ],
  );

  const updateJob = useMemo(
    () => debounce(updateJobBase, 500),
    [updateJobBase],
  );

  const getJobsByFileID = useCallback(
    async (fileID: string) => {
      try {
        const input = {
          fileID,
        };
        const jwt = await getJWT();
        const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
          twinsGetJobQuery,
          { input },
          jwt,
        );
        console.log('data', data);
        if (data) {
          const result = data as {
            twinsGetJob: {
              success: boolean;
              message: string;
              data: Job[];
            };
          };
          if (
            result.twinsGetJob.success &&
            result.twinsGetJob.data.length > 0
          ) {
            setJobs(result.twinsGetJob.data);
            console.log('result.twinsGetJob.data', result.twinsGetJob.data);
            return result.twinsGetJob.data;
          }
        }
      } catch (e) {
        showSnackbar(`Failed to fetch job: ${e as string}`, 'error');
      }
    },
    [getJWT, setJobs, showSnackbar],
  );

  const getJobLogs = useCallback(
    async (jobID: string) => {
      try {
        const input = {
          jobID,
        };
        const jwt = await getJWT();
        const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
          twinsGetJobLogQuery,
          { input },
          jwt,
        );
        if (data) {
          const result = data as {
            twinsGetJobLog: {
              success: boolean;
              message: string;
              data: JobLog[];
            };
          };
          if (
            result.twinsGetJobLog.success &&
            result.twinsGetJobLog.data &&
            result.twinsGetJobLog.data.length > 0
          ) {
            setJobLogs(result.twinsGetJobLog.data);
            return result.twinsGetJobLog.data;
          }
        }
      } catch (e) {
        showSnackbar(`Failed to fetch job logs: ${e as string}`, 'error');
      }
    },
    [getJWT, setJobLogs, showSnackbar],
  );

  const getJobs = useCallback(async () => {
    try {
      setGettingJobs(true);
      const input = {
        userID: superfeelUser?.userId,
      };
      const jwt = await getJWT();
      const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
        twinsGetJobQuery,
        { input },
        jwt,
      );
      if (data) {
        const result = data as {
          twinsGetJob: {
            success: boolean;
            message: string;
            data: Job[];
          };
        };
        if (result.twinsGetJob.success && result.twinsGetJob.data.length > 0) {
          setJobs(result.twinsGetJob.data);
        }
      }
    } catch (e) {
      showSnackbar(`Failed to fetch jobs: ${e as string}`, 'error');
    } finally {
      setGettingJobs(false);
    }
  }, [getJWT, setGettingJobs, setJobs, showSnackbar, superfeelUser?.userId]);

  const getJob = useCallback(
    async (jobID: string) => {
      try {
        const input = {
          id: jobID,
        };
        const jwt = await getJWT();
        const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
          twinsGetJobQuery,
          { input },
          jwt,
        );
        if (data) {
          const result = data as {
            twinsGetJob: {
              success: boolean;
              message: string;
              data: Job[];
            };
          };
          if (
            result.twinsGetJob.success &&
            result.twinsGetJob.data.length > 0
          ) {
            setJob(result.twinsGetJob.data[0]);
            return result.twinsGetJob.data[0];
          }
        }
      } catch (e) {
        showSnackbar(`Failed to fetch job: ${e as string}`, 'error');
      }
    },
    [getJWT, setJob, showSnackbar],
  );

  return { getJobsByFileID, updateJob, getJobLogs, getJobs, getJob };
};
