import { TwinsGetSignedCloudfrontUrlInput } from '@twins/types';
import { executeGraphqlOperation } from 'api';
import { GraphQLResult } from 'aws-amplify/api';
import { twinsGetSignedCloudfrontUrlQuery } from 'graphql/queries';
import { useCallback } from 'react';

import { useSnackbar } from 'use/snackbar';
import { useUser } from 'use/user';

export const useMediaAPI = () => {
  const { getJWT } = useUser();
  const { showSnackbar } = useSnackbar();

  const getCloudfrontUrl = useCallback(
    async (key: string) => {
      try {
        const input: TwinsGetSignedCloudfrontUrlInput = {
          type: 'CONTENT',
          key: key,
        };
        const jwt = await getJWT();
        const { data }: GraphQLResult<unknown> = await executeGraphqlOperation(
          twinsGetSignedCloudfrontUrlQuery,
          { input },
          jwt,
        );
        console.log('data', data);
        if (data) {
          const result = data as {
            twinsGetSignedCloudfrontUrl: {
              success: boolean;
              message: string;
              uri: string;
            };
          };
          if (
            result.twinsGetSignedCloudfrontUrl.success &&
            result.twinsGetSignedCloudfrontUrl.uri
          ) {
            return result.twinsGetSignedCloudfrontUrl.uri;
          }
        }
      } catch (e) {
        showSnackbar(`Failed to fetch job: ${e as string}`, 'error');
      }
    },
    [getJWT, showSnackbar],
  );

  return { getCloudfrontUrl };
};
