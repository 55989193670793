import React, { useEffect, useState } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { enumToObject, menuOptions } from 'common/utils';
import { InfoLabel, InfoTextFeld, Select, Tooltip } from 'components/atoms';
import {
  SectionTitle,
  SubSectionTitle,
  CheckboxWithLabel,
} from 'components/atoms';
import { Languages } from 'types/enums';
import { useVoice } from 'use/voice';
import { parseInt } from 'lodash';

type TextFieldConfig = {
  key: 'transcriptMinChars' | 'transcriptMaxChars';
  label: string;
  tooltip: string;
  defaultValue: number;
};

const textFieldConfigs: TextFieldConfig[] = [
  {
    key: 'transcriptMinChars',
    label: 'Transcript Min Characters',
    tooltip: 'Minimum number of characters in the transcript.',
    defaultValue: 160,
  },
  {
    key: 'transcriptMaxChars',
    label: 'Transcript Max Characters',
    tooltip: 'Maximum number of characters in the transcript.',
    defaultValue: 2000,
  },
];

type CheckboxConfig = {
  key: string;
  label: string;
  tooltip?: string;
  disabled?: boolean;
};

const checkboxConfigs: CheckboxConfig[] = [
  {
    key: 'usePosts',
    label: 'Feels',
    tooltip: 'Select and clean feels.',
  },
  {
    key: 'useFiles',
    label: 'Interviews',
    tooltip: 'Select and clean uploaded interview files.',
  },
];

export default function VoiceSelect() {
  const languageOptions = menuOptions(Object.values(enumToObject(Languages)));
  const { voice, updateVoice } = useVoice();
  const [selectedLanguage, setSelectedLanguage] = useState(Languages.EN);
  const [textFieldValues, setTextFieldValues] = useState<
    Record<string, number>
  >({});
  const [checkboxValues, setCheckboxValues] = useState<Record<string, boolean>>(
    {},
  );

  useEffect(() => {
    if (voice?.transcriptLanguage) {
      setSelectedLanguage(voice.transcriptLanguage as Languages);
    }
    textFieldConfigs.forEach((config) => {
      if (voice?.[config.key] !== undefined) {
        setTextFieldValues((prev) => ({
          ...prev,
          [config.key]: voice[config.key],
        }));
      }
    });
    checkboxConfigs.forEach((config) => {
      if (voice?.[config.key] !== undefined) {
        setCheckboxValues((prev) => ({
          ...prev,
          [config.key]: voice[config.key],
        }));
      }
    });
  }, [voice]);

  const handleLanguageChange = (newLanguage: string) => {
    setSelectedLanguage(newLanguage as Languages);
    updateVoice({ ...voice, transcriptLanguage: newLanguage as Languages });
  };

  const handleTextFieldChange = (key: string, value: number) => {
    setTextFieldValues((prev) => ({ ...prev, [key]: value }));
    updateVoice({ ...voice, [key]: value });
  };

  const handleBooleanValueChange = (key: string, value: boolean) => {
    setCheckboxValues((prev) => ({ ...prev, [key]: value }));
    updateVoice({ ...voice, [key]: value });
  };

  return (
    <Box mb={10}>
      <SectionTitle>Selection Criteria</SectionTitle>
      <SubSectionTitle>Content</SubSectionTitle>
      <Box>
        <Grid
          container
          spacing={2}
        >
          {textFieldConfigs.map((config) => (
            <Grid
              item
              xs={12}
              sm={4}
              key={config.key}
            >
              <InfoTextFeld<number>
                type="number"
                label={config.label}
                value={textFieldValues[config.key] ?? config.defaultValue}
                tooltip={config.tooltip}
                onChange={(val) =>
                  handleTextFieldChange(config.key, parseInt(val.toString()))
                }
              />
            </Grid>
          ))}
          <Grid
            item
            xs={12}
            sm={4}
          >
            <InfoLabel
              label="Transcript Language"
              tooltip="Language of the transcript."
              placement="top"
            />
            <Select
              value={selectedLanguage}
              onChange={handleLanguageChange}
              options={languageOptions}
            />
          </Grid>
        </Grid>
      </Box>
      <Stack
        direction="column"
        sx={{ pt: 2 }}
      >
        <SubSectionTitle>Sources</SubSectionTitle>
        <Stack
          direction="row"
          spacing={2}
          sx={{ pt: 2 }}
        >
          {checkboxConfigs.map((config) => (
            <Tooltip
              key={config.key}
              title={config.tooltip || ''}
              placement="top"
            >
              <CheckboxWithLabel
                disabled={config.disabled}
                label={config.label}
                checked={checkboxValues[config.key] || false}
                onChange={(e) =>
                  handleBooleanValueChange(config.key, e.target.checked)
                }
              />
            </Tooltip>
          ))}
        </Stack>
        <Box mt={2}>
          <SubSectionTitle>Audio Settings</SubSectionTitle>
          <Box>
            <Tooltip
              title="Autoselect Coming Soon"
              placement="top"
            >
              <CheckboxWithLabel
                disabled
                label="Remove Multi-Speaker"
                checked={false}
                onChange={() => {}}
              />
            </Tooltip>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
}
