import { Box } from '@mui/material';
import { FileCollectionDetails } from 'components/molecules';
import { FileCollectionFiles } from 'components/organisms';
import { useParams } from 'react-router-dom';
import { Loading } from 'components/atoms';
import { FileMedallion } from 'types/enums';
import { useTwinsGetCollectionQuery } from 'services/collection.services.ts/collection';

export default function UserCollection() {
  const { collectionID } = useParams<{ collectionID: string }>();
  const { data, isLoading, isError } = useTwinsGetCollectionQuery({
    input: {
      id: collectionID,
    },
  });

  if (isError) {
    return <div>Something went wrong!</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box>
      <FileCollectionDetails collection={data?.twinsGetCollection?.data[0]} />
      <FileCollectionFiles
        collection={data?.twinsGetCollection?.data[0]}
        medallion={FileMedallion.bronze}
      />
    </Box>
  );
}
