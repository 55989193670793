import { TwinsGetSignedUrlInput, UploadUrl } from '@twins/types';
import { twinsGetSignedUrlQuery } from 'graphql/queries';
import { executeGraphqlOperation } from '.';
import { getCurrentAuthCredentials } from './auth/auth.api';

type OptionalTwinsGetSignedUrlInput = Omit<
  TwinsGetSignedUrlInput,
  'method' | 'type' | 'expiry'
> &
  Partial<Pick<TwinsGetSignedUrlInput, 'method' | 'type' | 'expiry'>>;

export const getSignedUploadUrl = async ({
  key,
  bucketName,
  method = 'PUT',
  multipartUpload,
  expiry = 60 * 60 * 24,
  type = 'INTERVIEW',
}: OptionalTwinsGetSignedUrlInput) => {
  const uploadContentInput: TwinsGetSignedUrlInput = {
    method,
    key,
    type,
    expiry,
    bucketName,
    multipartUpload,
  };
  const { jwt } = await getCurrentAuthCredentials();
  return executeGraphqlOperation<{ twinsGetSignedUrl: UploadUrl }>(
    twinsGetSignedUrlQuery,
    { input: uploadContentInput },
    jwt,
  );
};
