import React, { useMemo } from 'react';
import { LoadingButton } from '@mui/lab';
import { Badge, Box } from '@mui/material';
import { useTwinsGetFileQuery } from 'services/collection.services.ts/file';
import { formatDate } from 'common/utils';
import { BottomRow, MetadataGroup } from 'components/atoms';
import {
  AIProvider,
  FileMedallion,
  SortDirection,
  Status,
  VoiceStep,
} from 'types/enums';
import { useApp } from 'use/app';
import { Voice } from '@twins/types';

interface VoiceBottomRowProps {
  voice: Voice;
  onAction: () => void;
  collectionId: string;
}

export default function VoiceBottomRow({
  voice,
  onAction,
  collectionId,
}: VoiceBottomRowProps) {
  const { bottomNavLoading } = useApp();
  const { data } = useTwinsGetFileQuery({
    input: {
      collectionID: collectionId,
      medallion: FileMedallion.gold,
      sort: SortDirection.ASC,
    },
  });

  const badgeCount = useMemo(() => {
    return (
      data?.twinsGetFile?.data?.filter((file) => file.selected)?.length || 0
    );
  }, [data?.twinsGetFile?.data]);

  const isButtonDisabled = (
    step: VoiceStep,
    status: Status | null,
    provider: AIProvider | null,
  ) => {
    switch (step) {
      case VoiceStep.clean:
        return status !== Status.complete;
      case VoiceStep.review:
        return badgeCount === 0;
      case VoiceStep.train:
        return (
          provider === null ||
          provider === AIProvider.UNKNOWN ||
          (status !== Status.complete && status !== Status.submitted)
        );
      case VoiceStep.complete:
        return true;
      default:
        return false;
    }
  };

  const Button = () => (
    <LoadingButton
      disabled={isButtonDisabled(
        voice?.step as VoiceStep,
        voice?.status as Status,
        voice?.provider as AIProvider,
      )}
      loading={bottomNavLoading}
      size="small"
      variant="outlined"
      sx={{ fontWeight: 600 }}
      onClick={onAction}
    >
      {voice?.step === VoiceStep.complete ? 'Completed' : 'Next'}
    </LoadingButton>
  );

  return (
    <Box>
      <BottomRow
        information={
          <MetadataGroup
            items={[
              {
                label: 'Created At',
                value: formatDate(voice?.createdAt || new Date().toISOString()),
              },
              {
                label: 'Updated At',
                value: formatDate(voice?.updatedAt || new Date().toISOString()),
              },
            ]}
          />
        }
        action={
          <Badge
            badgeContent={badgeCount}
            color="primary"
            invisible={voice?.step !== VoiceStep.review}
          >
            <Button />
          </Badge>
        }
      />
    </Box>
  );
}
