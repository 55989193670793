import { useEffect, useState, ChangeEvent, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  Box,
  Typography,
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import { Loading, TableHeaderCell, UserStatus } from 'components/atoms';
import { useSnackbar } from 'use/snackbar';
import { SuperfeelUserResult } from 'types/api';
import { useDebounce } from 'hooks/use-debounce';
import { isErrorWithMessage, isFetchBaseQueryError } from 'services/utils';
import { useTwinsSearchUsersQuery } from 'services/user.services.ts/user';

export default function Users() {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const searchInput = useMemo(() => {
    const trimmedSearch = debouncedSearchTerm.trim();
    return trimmedSearch.length > 0
      ? {
          exactMatch: false,
          properties: ['username', 'first_name', 'last_name'],
          terms: [trimmedSearch],
        }
      : undefined;
  }, [debouncedSearchTerm]);

  const { data, isLoading, isError, error, refetch } = useTwinsSearchUsersQuery(
    {
      input: {
        includeHidden: true,
        limit: rowsPerPage,
        startIndex: page * rowsPerPage,
        search: searchInput,
      },
    },
  );

  useEffect(() => {
    refetch();
  }, [page, rowsPerPage, debouncedSearchTerm, refetch]);

  useEffect(() => {
    if (isError) {
      if (isFetchBaseQueryError(error)) {
        const errMsg =
          'error' in error ? error.error : JSON.stringify(error.data);
        showSnackbar(errMsg, 'error');
      } else if (isErrorWithMessage(error)) {
        showSnackbar(error.message, 'error');
      } else {
        showSnackbar('An error occurred', 'error');
      }
    }
  }, [isError, error, showSnackbar]);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value;
    setSearchTerm(searchValue);
    setPage(0);
  };

  const handleRowClick = (username: string) => {
    navigate(`/user/${username}`);
  };

  if (isError) {
    return (
      <Box sx={{ width: '100%', height: '100%' }}>
        <Typography>Error fetching table data</Typography>
      </Box>
    );
  }

  return (
    <Paper>
      <TextField
        variant="filled"
        value={searchTerm}
        onChange={handleSearch}
        fullWidth
        label="Search"
        size="small"
      />

      <TableContainer>
        {isLoading ? (
          <Loading minHeight="82vh" />
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableHeaderCell>Username</TableHeaderCell>
                <TableHeaderCell>First Name</TableHeaderCell>
                <TableHeaderCell>Last Name</TableHeaderCell>
                <TableHeaderCell>AI Status</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.twinsSearchUsers?.users?.map(
                (user: SuperfeelUserResult) => (
                  <TableRow
                    key={uuid()}
                    hover
                    onClick={() => handleRowClick(user.username)}
                    style={{ cursor: 'pointer' }}
                  >
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{user.firstName}</TableCell>
                    <TableCell>{user.lastName}</TableCell>
                    <TableCell>
                      <UserStatus username={user.username} />
                    </TableCell>
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data?.twinsSearchUsers?.totalCount || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
